<!--
	@name app-reward-dialog-redeem
	@description Rewards page dialog to redeem points
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" @closed="$emit('reward-redeem-dialog-closed')">
		<template #header>
			Redeem Air Reward points
		</template>
		<template #body>
			<div v-if="submitting" class="text-center">
				<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
			</div>
			<div v-else-if="submitStatus.success" class="text-center">
				<v-icon class="text-h1" color="success">
					mdi-check-bold
				</v-icon>
				<v-card-title class="text-h6 d-block pb-5">
					{{ submitStatus.title }}
				</v-card-title>
				<v-card-title class="text-subtitle-1 pt-0">
					{{ submitStatus.text }}
				</v-card-title>
			</div>
			<div v-else-if="submitStatus.error" class="text-center">
				<v-icon class="text-h1" color="error">
					mdi-exclamation
				</v-icon>
				<v-card-title class="text-h6 d-block pb-5">
					An unexpected error occurred
				</v-card-title>
				<v-card-title v-if="site" class="text-subtitle-1 d-block pt-0">
					If this problem continues, please contact our support team on {{ site.contact.number }}
				</v-card-title>
			</div>
			<div v-else>
				<validation-observer v-slot="{ invalid, validate }">
					<form @submit.prevent="validate().then(submitRewardRedeem)">
						<validation-provider v-slot="{ errors }" name="How would you like to redeem your Air reward points?" rules="required">
							<v-select
								v-if="rewardRedeemOptions.types"
								v-model="selectedRedeemOption"
								:error-messages="errors"
								:items="rewardRedeemOptions.types"
								label="How would you like to redeem your Air reward points?"
								outlined
							/>
						</validation-provider>

						<div v-if="selectedRedeemOption === 'charity'">
							<validation-provider v-slot="{ errors }" name="Which charity would you like to support?" rules="required">
								<v-select
									v-model="selectedCharity"
									:error-messages="errors"
									:items="rewardRedeemOptions.charities"
									label="Which charity would you like to support?"
									outlined
								/>
							</validation-provider>
						</div>

						<v-btn color="primary" class="mr-4" type="submit" :disabled="invalid">
							Confirm
						</v-btn>
					</form>
				</validation-observer>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { mapState, mapActions } from 'vuex';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-reward-dialog-redeem',

		components: { ValidationObserver, ValidationProvider, CommonDialog },

		inject: ['$rewardDebitList'],

		data() {
			return {
				rewardConfig: {},
				selectedCharity: null,
				selectedRedeemOption: null,
				submitStatus: {},
				submitting: false
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('CmsSite', ['site']),

			rewardDebitList() {
				return this.$rewardDebitList();
			},

			rewardRedeemOptions() {
				const types = [];
				const charities = [];
				let foundCharityForTypes = false;

				this.rewardDebitList.forEach((i) => {
					if (i.entityType === 'charity') {
						if (!foundCharityForTypes) {
							types.push({ value: 'charity', text: 'Charity Donation' });
							foundCharityForTypes = true;
						}
						charities.push({ value: i.id, text: i.name });
					} else types.push({ value: i.id, text: i.name });
				});

				return { types, charities };
			}
		},

		methods: {
			...mapActions('Account', ['verify']),
			...mapActions('AppReward', ['rewardRedeem', 'voucherRedeem']),

			/**
			 * @name open
			 * @description TODO
			 */
			open(rewardConfig) {
				this.rewardConfig = rewardConfig;
				this.submitStatus = {};
				this.selectedCharity = null;
				this.selectedRedeemOption = null;
				this.$refs.dialog.open();
			},

			/**
			 * @name submitRewardRedeem
			 * @description TODO
			 */
			async submitRewardRedeem() {
				this.submitting = true;

				try {
					this.rewardConfig.type === 'voucher'
						? await this.voucherRedeem({
								transactionId: this.rewardConfig.id,
								data: {
									debitId: this.selectedCharity || this.selectedRedeemOption
								}
						  })
						: await this.rewardRedeem({
								debitId: this.selectedCharity || this.selectedRedeemOption,
								data: { type: 'redemption', originator: 'adviser' },
								actioned: new Date()
						  });

					this.submitStatus = {
						success: true,
						title: 'Request Submitted',
						text: 'Your request to redeem Air Reward Points has been submitted. One of our team will update you shortly to confirm receipt.'
					};

					await this.verify({ verifyOnly: true });
					this.$emit('init');
				} catch (error) {
					this.submitStatus.error = true;
				}

				this.submitting = false;
			}
		}
	};
</script>
