import { $http } from '@/utils';

/**
 * @namespace Service
 * @class FactFind
 * @exports Service/App/Matter/FactFind
 * @description API App Matter FactFind Service
 * @date 2023/09/25
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class FactFind {
	/**
	 * @static @name list
	 * @description Get all fact finds
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static async list(payload) {
		return $http.post('fact-find/list', payload);
	}

	/**
	 * @static @name get
	 * @description Get fact find details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`fact-find/${id}`);
	}

	/**
	 * @static @name get
	 * @description Get fact find details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static getFile(id) {
		return $http.get(`fact-find/${id}/file`);
	}
}
