<!--
	@name app-client-dashboard-workflow-state-benefits-activity
	@description Client State Benefits Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="loading" type="list-item-three-line" />

		<template v-else>
			<div v-if="!canAccessStateBenefits">
				<v-alert outlined text type="error" border="left" class="mb-6">
					<span class="text-body-1">Your free trial has expired!</span>
				</v-alert>
				<p class="text-body-1 font-weight-bold">
					We really hope you've enjoyed the time-saving benefits of direct integration between entitledto and {{ site.softwareName }}. To continue using the
					functionality, you now have two options:
				</p>
				<ul class="text-body-1 mb-6">
					<li class="mb-2">
						<span class="font-weight-bold">Subscription Service:</span> You can opt for a rolling monthly subscription of £7.99 per month, or you can benefit from the
						cost-saving annual subscription of just £40.
					</li>
					<li>
						<span class="font-weight-bold">Join the Air Later Life Academy:</span> As well as integrated State Benefits, by joining the LLA you will also have immediate
						access to enhanced product terms, referral models to increase earning potential, nationwide training modules and much more.
					</li>
				</ul>
				<p class="text-body-1">
					To proceed, or to discuss these options in more detail, please <a href="#" @click="$refs.contact.open()">contact our support team</a>.
				</p>
			</div>

			<div v-else>
				<!-- Old Content -->
				<!-- <v-sheet v-if="!items?.length">
					<img :src="'entitledto.png' | urlize('organisation')" class="mt-n3 ml-10 mb-6" style="float: right; width: 300px" />
					<p class="text-body-1 font-weight-bold">
						{{ site.softwareName }} has teamed up with the UK's leading provider of online state benefits calculators entitledto, to bring you an integrated State
						Benefits Reporting system.
					</p>
					<p class="text-body-2">
						Establishing if older clients can claim State Benefits is vital, particularly where they are raising capital or looking to increase income. You can perform
						a full State Benefits check for your clients, establish what benefits they can claim and the full report as well as a summary of benefits available will be
						saved against your client in the “Clients” section. You can use this key information in your suitability report and ensure that your advice and
						recommendations reflect real-time data.
					</p>

					<v-btn color="primary" @click="getStarted">
						<v-icon class="mr-2">
							mdi-calculator
						</v-icon>
						Start Calculation for this Client
					</v-btn>
				</v-sheet> -->

				<v-sheet v-if="!items?.length">
					<v-row>
						<v-col cols="12">
							<video
								v-if="introResource"
								class="d-block ml-10 mb-6"
								:src="introResource.video"
								:poster="introResource.poster"
								controls
								width="50%"
								style="float: right"
							/>
							<p class="text-body-1 font-weight-bold mb-6">
								Complete a calculation today using entitledto on AirFlow's integrated State Benefits tile.
							</p>
							<ul class="text-body-1 pl-8 mb-6">
								<li class="mb-2">
									Navigate directly to the entitledto state benefits calculator and input the relevant information required for your customers.
								</li>
								<li class="mb-2">
									Calculate if your customer is entitled to means tested benefits and what the impact of raising capital may have on any future entitlement.
								</li>
								<li class="mb-0">
									Print-off PDF versions of the benefits calculator reports to evidence your discussions and provide a clear audit trail.
								</li>
							</ul>
							<p class="text-body-1 mb-8">
								Click on the intro video for a deeper insight into how you can navigate AirFlow's State Benefits tile and maximise its benefits and features.
							</p>
							<v-btn color="primary" @click="getStarted">
								Get started
							</v-btn>
							<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
						</v-col>
					</v-row>
				</v-sheet>

				<div v-else>
					<v-timeline align-top dense class="pa-0">
						<v-timeline-item v-for="(item, index) in items" :key="index" :color="item.completed ? 'grey' : 'grey'" small class="py-6">
							<v-row no-gutters>
								<v-col>
									<p class="text-h6 mt-n2 mb-1">
										<span class="font-weight-bold">
											{{ item.title || 'Benefit Calculation' }}
										</span>
										<v-chip
											label
											x-small
											dark
											:color="getStatusColor(item.completed ? 'Completed' : 'In Progress')"
											style="position: relative; left: 8px; top: -4px"
										>
											{{ item.completed ? 'Completed' : 'In Progress' }}
										</v-chip>
									</p>
									<p class="text-body-2 mb-4">
										{{ getCaseReference(item) }}
									</p>

									<v-timeline align-top dense class="pt-1">
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon color="success">
													mdi-check-bold
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> Created: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														{{ item.created | moment('MMM Do YYYY, h:mma') }}
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon v-if="item.completed" color="success">
													mdi-check-bold
												</v-icon>
												<v-icon v-else color="error">
													mdi-close-thick
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> Completed: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														<template v-if="item.completed">
															{{ item.completed | moment('MMM Do YYYY, h:mma') }}
														</template>
														<template v-else> -- </template>
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
									</v-timeline>

									<div class="mt-4 mx-n1">
										<v-btn v-if="!item.completed" color="primary" class="mx-1" @click="openUrl(item.redirectUrl)">
											Resume calculation
										</v-btn>
										<v-btn v-else color="primary" class="mx-1" @click="openEntitlements(item.entitlements)">
											View Entitlements
										</v-btn>

										<v-tooltip bottom>
											<template #activator="{ on }">
												<v-btn
													color="info"
													class="mx-1"
													icon
													:disabled="!item.document || item.document.expired"
													:loading="fileLoading[item.id]"
													@click="fetchDocument(item)"
													v-on="on"
												>
													<v-icon> mdi-file-download </v-icon>
												</v-btn>
											</template>
											<span>Download PDF</span>
										</v-tooltip>

										<v-tooltip v-if="item.completed" bottom>
											<template #activator="{ on }">
												<v-btn color="secondary" class="mx-1" icon @click="openUrl(item.redirectUrl)" v-on="on">
													<v-icon> mdi-calculator </v-icon>
												</v-btn>
											</template>
											<span>Open Calculator</span>
										</v-tooltip>
									</div>
								</v-col>
							</v-row>
						</v-timeline-item>
					</v-timeline>
				</div>
			</div>
		</template>

		<common-dialog ref="entitlements">
			<template #body>
				<p class="text-body-1 font-weight-bold mb-4">
					Entitlements
				</p>
				<div class="ma-n1">
					<v-chip v-for="(entitlement, key) in entitlements" :key="key" label outlined class="ma-1" color="secondary">
						{{ entitlement.name }}<span class="font-weight-bold d-inline-block pl-1">{{ entitlement.value }}</span>
					</v-chip>
				</div>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { EventBus, ElementTools, PopupWindow } from '@/utils';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-client-dashboard-workflow-state-benefits-activity',

		components: {
			CommonDialog
		},

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				items: null,
				loading: true,
				fileLoading: {},
				entitlements: null
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('Account', ['user', 'membership']),
			...mapGetters('AppWorkflow', ['getStatusColor']),

			canAccessStateBenefits() {
				const academy = this.membership.find((m) => m.type === 'academy');

				if (academy && academy.approve) return true; // 1
				if (this.user.stateBenefitsUpgrade) return true; // 2
				if (!this.item || !this.items.length) return true; // 3
				if (this.$moment().diff(this.items[0].created, 'days') <= 30) return true; // 4

				return false;
			},

			entityIds() {
				const entityIds = [this.client.id];
				if (this.association) entityIds.push(this.association.id);
				return entityIds;
			}
		},

		created() {
			this.loadStateBenefits();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('AppStateBenefit', ['loadStateBenefitList', 'submitStateBenefit', 'fetchFile']),

			/**
			 * @name onMessage
			 * @description Handle the websocket message
			 * @param message
			 */
			onMessage(message) {
				if (message?.type !== 'stateBenefits') return;
				const index = this.items.findIndex((i) => i.id === message.id);
				this.$set(this.items, index, { ...this.items[index], completed: message.completed, document: message.document, entitlements: message.entitlements });
				this.$emit('workflow-updated');
			},

			/**
			 * @name loadStateBenefits
			 * @description Load the state benefits
			 */
			loadStateBenefits() {
				this.loadStateBenefitList({
					entityIds: this.entityIds,
					order: { property: 'created', direction: 'desc' }
				})
					.then(({ data }) => {
						this.items = data;
						if (!this.items?.length) this.$emit('toggle-intro-resource');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
					.finally(() => (this.loading = false));
			},

			/**
			 * @name getStarted
			 * @description Get started with the state benefits
			 */
			getStarted() {
				this.submitStateBenefit({
					client: this.entityIds.map((id) => ({ id }))
				})
					.then(({ data }) => {
						this.openUrl(data.redirectUrl);
						this.$emit('toggle-intro-resource');
						this.loadStateBenefits();
						this.$emit('workflow-updated');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`));
			},

			/**
			 * @name fetchDocument
			 * @description Fetch the document from the server
			 * @param item item that needs updating
			 */
			async fetchDocument(item) {
				const { id } = item;
				this.$set(this.fileLoading, item.id, true);

				this.fetchFile({ id: item.id })
					.then((response) => {
						if (response.data?.preSignedUrl) this.openUrl(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, id, false));
			},

			/**
			 * @name getCaseReference
			 * @description Get the case reference
			 * @param item
			 * @returns {string}
			 */
			getCaseReference(item) {
				const itemClientIds = item.clients.map((client) => client.id);
				const relevantClients = [this.client, this.association].filter((client) => client && itemClientIds.includes(client.id));
				const clientNames = relevantClients.map((client) => this.getClientFullName(client));
				return clientNames.join(' and ');
			},

			/**
			 * @name getClientFullName
			 * @param client
			 * @returns {string}
			 */
			getClientFullName(client) {
				return `${client.nameTitleName} ${client.nameGiven} ${client.nameMiddle || ''} ${client.nameFamily}`;
			},

			/**
			 * @name openUrl
			 * @param url
			 */
			openUrl(url) {
				new PopupWindow().open(url, '_blank');
			},

			/**
			 * @name openChat
			 * @description Open the chat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			},

			/**
			 * @name openEntitlements
			 * @description Open the chat
			 */
			openEntitlements(entitlements) {
				this.entitlements = entitlements;
				this.$refs.entitlements.open();
			}
		}
	};
</script>

<style type="text/css" lang="scss" scoped>
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	.v-timeline-item:has(.v-timeline-item):nth-child(even) {
		background: rgba(30, 176, 225, 0.1);
		background: linear-gradient(90deg, rgba(27, 120, 216, 0.05) 0%, rgba(103, 0, 86, 0.05) 100%);
	}
</style>
