import { AccountOrganisation } from '@/service';
import VeeValidate from 'vee-validate';
import { isNil } from 'lodash';
import moment from 'moment';

const isNilOrEmpty = (value) => isNil(value) || value === '';

const isFcaAvailable = (fca) =>
	AccountOrganisation.fcaCheck({ fca }).then((response) => ({
		valid: response.data.valid
	}));

/**
 * @name is_fca_available
 * @description FCA number locked validation
 */
VeeValidate.Validator.extend('is_fca_available', {
	validate: (value) => isFcaAvailable(value),
	getMessage: () => 'This FCA number is not available'
});

/**
 * @name min_length
 * @description Minimum length validation for array type models
 */
VeeValidate.Validator.extend('min_length', {
	getMessage: (field, [ruleValue]) => `Please select at least ${ruleValue} ${field}`,
	validate: (value, [ruleValue]) => value.length >= ruleValue
});

/**
 * @name verify_password
 * @description Password strength validation
 */
VeeValidate.Validator.extend('verify_password', {
	getMessage: () =>
		`The password must be at least 8 characters and must contain an uppercase letter, a lowercase letter, a number, and a special character which includes ? " $ % & * !`,
	validate: (value) => {
		var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/;
		var passwordRegex = new RegExp(regex);
		// this regex checks if one upper/lower/number/special new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$');
		return passwordRegex.test(value);
	}
});

/**
 * @name verify_password
 * @description Password strength validation
 */
VeeValidate.Validator.extend('required_true', {
	getMessage: () => `You must confirm to proceed`,
	validate: (value) => !!value
});

/**
 * @name verify_username
 * @description Username validation to allow anything but whitespace
 */
VeeValidate.Validator.extend('verify_username', {
	getMessage: () => `The username cannot contain whitespace.`,
	validate: (value) => {
		var regex = /^[^\s]+$/;
		var usernameRegex = new RegExp(regex);
		return usernameRegex.test(value);
	}
});

/**
 * @name greater_than
 * @description Custom comparative validator
 */
VeeValidate.Validator.extend(
	'greater_than',
	{
		getMessage: () => 'The max value should be greater than or equal to the min value',
		validate: (value, [otherValue]) => value >= otherValue
	},
	{ hasTarget: true }
);

/**
 * @name less_than
 * @description Custom comparative validator
 */
VeeValidate.Validator.extend(
	'less_than',
	{
		getMessage: () => 'The min value should be less than or equal to the max value',
		validate: (value, [otherValue]) => (!isNaN(otherValue) ? value <= otherValue : true)
	},
	{ hasTarget: true }
);

VeeValidate.Validator.extend('is_directly_authorised', {
	validate: (value) => value === true,
	getMessage: () => 'You must be either a member of a Network or Directly Authorised to register'
});

/**
 * @name postcode
 * @description Postcode validation
 */
VeeValidate.Validator.extend('postcode', {
	getMessage: () => `The postcode field should contain a valid postcode`,
	validate: (value) => {
		const postcodeRegex = /^(([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2}$/;
		return postcodeRegex.test(value.replace(/\s+/g, ''));
	}
});

/**
 * @name required_if_not
 * @description Required if target field is falsy
 */
VeeValidate.Validator.extend(
	'required_if_not',
	{
		validate: (value, [otherValue]) => {
			return {
				valid: !(isNilOrEmpty(otherValue) && isNilOrEmpty(value)),
				data: {
					required: isNilOrEmpty(otherValue)
				}
			};
		}
	},
	{ hasTarget: true, computesRequired: true }
);

/**
 * @name age_between
 * @description Age between validation
 */
VeeValidate.Validator.extend('age_between', {
	getMessage: (value, [minAge, maxAge]) => `Age must be between ${minAge} and ${maxAge} years old.`,
	validate: (value, [minAge, maxAge]) => {
		const maxDob = moment().subtract(minAge, 'years');
		const minDob = moment().subtract(maxAge, 'years');
		const dob = moment(value, 'DD/MM/yyyy');
		return dob.isBetween(minDob, maxDob, 'days', '(]');
	}
});

/**
 * @name search
 * @description Search validation
 */
VeeValidate.Validator.extend('search', {
	getMessage: () => `The search must contain alphanumeric characters.`,
	validate: (value) => {
		const regex = /^([a-zA-Z0-9\s_\-:&@.']*)$/;
		return regex.test(value);
	}
});

export default VeeValidate;
