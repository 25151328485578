<template>
	<common-dialog ref="dialog" :max-width="800">
		<template #header>
			Our KFI request page has had a facelift!
		</template>
		<template #body>
			<p class="mb-2 text-body-2 font-weight-bold">
				KFI requests are now displayed according to their status - with additional information and features available at each stage of the request process.
			</p>
			<p class="mb-5 text-body-2">
				Use your mouse to hover over the slideshow below in order to read the detailed descriptions and reveal the navigation elements to scroll through the different KFI request statuses at your leisure.
			</p>
			<v-hover v-slot="{ hover }">
				<v-carousel
					continuous
					:cycle="!hover"
					hide-delimiter-background
					show-arrows-on-hover
					interval="3500"
					light
					height="230"
					class="grey lighten-3 rounded"
				>
					<v-carousel-item v-for="(example, index) in examples" :key="index">
						<product
							:product="example"
							:index="index"
							:client-id="clientId"
							:sockets:enabled="socketsEnabled"
							:demo="true"
							class="py-6 px-8"
						/>
						<div style="height: 30px;" />
					</v-carousel-item>
				</v-carousel>
			</v-hover>
		</template>
	</common-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import Product from './product';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'kfi-request-summary',

		components: {
			Product,
			CommonDialog
		},

		props: {
			clientId: { type: String, required: true },
			socketsEnabled: { type: Boolean, required: false, default: true }
		},

		computed: {
			...mapState('Account', ['changelogItems']),
			...mapState('CmsSite', ['site']),

			examples() {
				let apiCopy = 'API enabled requests will process immediately. These requests will pulse to illustrate that they are communicating with the lender partner system. Once complete, the item will update to either a success or failure status.';
				if (this.socketsEnabled) apiCopy = 'API enabled requests will process immediately and update automatically in your browser. These requests will pulse to illustrate that they are communicating with the lender partner system. Once complete, the item will update to either a success or failure status.';
				let manualCopy = 'Our team will process these requests on your behalf as they are for products not yet supported by an API. Requests received before 3pm will be actioned the same day. With best endeavours, we will look to process any requests received after 3pm, however this cannot be guaranteed.';
				let receivedCopy = 'Your KFI request has been successful! You can click these requests to access documents.';
				let failedCopy = 'KFI requests can fail for a variety of reasons. Click these requests to find out more information, resubmit the request or speak with our support team to find an immediate resolution/alternative.';
				let externalCopy = 'These requests are for products whereby the provider does not yet allow for KFIs to be requested by a third party. Click these requests to proceed to the providers website and fulfil the KFI request process, or remove the product from your KFI request list.';

				return [
					{
						id: "3b1f9f20-9717-4271-991f-ae0b448cab27",
						status: "KFI Request Submitted",
						product: {
							"id": "5a281493-2adc-4b18-9b4b-43a3ce7c5447",
							"name": "Processing automatically via API",
							"provider": {
							"id": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839",
							"name": "Provider Name",
							"nameUnique": "pure_retirement",
							"logo": [
								{
								"name": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839.png",
								"primary": true
								}
							]
							},
							"sqs": "kfi-submission"
						},
						kfiRequestFailed: "2024-11-19T12:47:13.058679+00:00",
						kfiRequestSubmitted: "2024-11-19T12:47:07.741295+00:00",
						overrideStatusText: `<p class="text-caption plain grey--text text--darken-3 mb-0">${apiCopy}</p>`
					},
					{
						id: "3b1f9f20-9717-4271-991f-ae0b448cab27",
						status: "KFI Request Submitted",
						product: {
							"id": "5a281493-2adc-4b18-9b4b-43a3ce7c5447",
							"name": "Requires manual processing",
							"provider": {
							"id": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839",
							"name": "Provider Name",
							"nameUnique": "pure_retirement",
							"logo": [
								{
								"name": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839.png",
								"primary": true
								}
							]
							},
							"sqs": null
						},
						kfiRequestFailed: "2024-11-19T12:47:13.058679+00:00",
						kfiRequestSubmitted: "2024-11-19T12:47:07.741295+00:00",
						overrideStatusText: `<p class="text-caption plain grey--text text--darken-3 mb-0">${manualCopy}</p>`
					},
					{
						id: "3b1f9f20-9717-4271-991f-ae0b448cab27",
						status: "KFI Received",
						product: {
							"id": "5a281493-2adc-4b18-9b4b-43a3ce7c5447",
							"name": "KFI received",
							"provider": {
							"id": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839",
							"name": "Provider Name",
							"nameUnique": "pure_retirement",
							"logo": [
								{
								"name": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839.png",
								"primary": true
								}
							]
							},
							"sqs": "kfi-submission"
						},
						kfiRequestFailed: "2024-11-19T12:47:13.058679+00:00",
						kfiRequestSubmitted: "2024-11-19T12:47:07.741295+00:00",
						overrideStatusText: `<p class="text-caption plain grey--text text--darken-3 mb-0">${receivedCopy}</p>`
					},
					{
						id: "3b1f9f20-9717-4271-991f-ae0b448cab27",
						status: "KFI Request Failed",
						product: {
							"id": "5a281493-2adc-4b18-9b4b-43a3ce7c5447",
							"name": "KFI request failed",
							"provider": {
							"id": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839",
							"name": "Provider Name",
							"nameUnique": "pure_retirement",
							"logo": [
								{
								"name": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839.png",
								"primary": true
								}
							]
							},
							"sqs": "kfi-submission"
						},
						kfiRequestFailed: "2024-11-19T12:47:13.058679+00:00",
						kfiRequestSubmitted: "2024-11-19T12:47:07.741295+00:00",
						overrideStatusText: `<p class="text-caption plain grey--text text--darken-3 mb-0">${failedCopy}</p>`
					},
					{
						id: "3b1f9f20-9717-4271-991f-ae0b448cab27",
						status: "KFI Request Submitted",
						product: {
							"id": "5a281493-2adc-4b18-9b4b-43a3ce7c5447",
							"name": `Request cannot be made via ${this.site?.softwareName || 'system'}`,
							"provider": {
							"id": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839",
							"name": "Provider Name",
							"nameUnique": "pure_retirement",
							"logo": [
								{
								"name": "1b1dbc8e-c9cd-4b14-a022-cfb0f6b91839.png",
								"primary": true
								}
							]
							},
							"keyFactsIllustration": {
								"online": true,
								"url": true
							},
							"sqs": null
						},
						kfiRequestFailed: "2024-11-19T12:47:13.058679+00:00",
						overrideStatusText: `<p class="text-caption plain grey--text text--darken-3 mb-0">${externalCopy}</p>`
					}
				];
			}
		},

		methods: {
			open() {
				this.$refs.dialog.open();
			}
		}
	};
</script>
