<!--
	@name common-dialog-confirm
	@description A common dialog box for confirmations
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" :persistent="options.persistent" :component="$options.name" @keydown.esc="cancel">
		<v-card>
			<v-app-bar dark :color="options.app_bar_color">
				<v-toolbar-title v-show="!!title">
					<v-icon v-if="options.titleIcon" class="mr-2">
						{{ options.titleIcon }}
					</v-icon>{{ title }}
				</v-toolbar-title>
				<v-spacer />
			</v-app-bar>

			<v-card-text v-if="options.html" v-show="!!message" class="pa-4" v-html="$sanitize(message)" />
			<v-card-text v-else v-show="!!message" class="pa-4">
				<p class="mb-0 text-subtitle-1">
					{{ message }}
				</p>
			</v-card-text>

			<v-card-text v-show="options.paragraphs.length" class="pa-6">
				<h2 v-if="options.headline" class="text-h6 mb-3">
					{{ options.headline }}
				</h2>

				<p v-for="(paragraph, index) in options.paragraphs" :key="index" class="mb-0 text-subtitle-1" :class="{ 'mt-3': index !== 0 }">
					{{ paragraph }}
				</p>
			</v-card-text>

			<v-card-actions class="pa-4" :class="options.hideText && 'blue-grey lighten-5'">
				<v-checkbox v-if="options.hideText" v-model="hidden" :label="options.hideText" hide-details color="primary" class="mx-0 my-0 px-0 py-0" @change="updateHide" />
				<v-spacer />
				<v-btn v-if="options.buttons.yes" color="primary mr-1" @click.native="agree">
					{{ options.buttons.yes }}
				</v-btn>
				<v-btn v-if="options.buttons.no" outlined color="blue-grey" @click.native="cancel">
					{{ options.buttons.no }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { $localStorage, $sessionStorage } from '@/utils/storage';

	export default {
		name: 'common-dialog-confirm',

		data() {
			return {
				dialog: false,
				hidden: false,
				resolve: null,
				reject: null,
				message: null,
				response: {},
				title: null,
				options: {
					buttons: { yes: 'OK', no: 'Cancel' },
					sessionHide: false,
					dialogName: null,
					system_bar_color: 'warning darken-2',
					app_bar_color: 'warning',
					headline: null,
					hideText: null,
					paragraphs: [],
					titleIcon: null,
					persistent: false,
					width: 500,
					zIndex: 200,
					html: false
				}
			};
		},

		computed: {
			// ...mapState('app', ['tips'])
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 * @param {String} title The title of the dialog
			 * @param {String} message The message for the dialog
			 * @param {Object} options Any options for the dialog
			 * @return {Promise} A Promise
			 */
			open(title, message, options) {
				this.reset();

				this.options = Object.assign(this.options, options);

				// If dialog is hidden by either local or session storage, do not open
				if ((this.options.dialogName && $localStorage.isDialogHidden(this.options.dialogName)) || $sessionStorage.isDialogHidden(this.options.dialogName)) return false;

				// Do we want this dialog to be shown once per session? If so, deal with it here
				if (this.options.sessionHide) $sessionStorage.hideDialog(this.options.dialogName);

				this.dialog = true;
				this.hidden = false;
				this.message = message;
				this.title = title;

				// NOTE: [Paul] This needs to be looked at
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},

			/**
			 * @name updateHide
			 * @description TODO
			 */
			updateHide() {
				if (this.hidden) $localStorage.hideDialog(this.options.dialogName);
				else $localStorage.resetDialog(this.options.dialogName);
			},

			/**
			 * @name agree
			 * @description TODO
			 */
			agree() {
				this.resolve(true);
				this.dialog = false;
			},

			/**
			 * @name cancel
			 * @description TODO
			 */
			cancel() {
				this.reject();
				this.dialog = false;
			},

			/**
			 * @name reset
			 * @description TODO
			 */
			reset() {
				this.dialog = false;
				this.hidden = false;
				this.resolve = null;
				this.reject = null;
				this.message = null;
				this.response = {};
				this.title = null;
				this.options = {
					buttons: { yes: 'OK', no: 'Cancel' },
					sessionHide: false,
					dialogName: null,
					system_bar_color: 'warning darken-2',
					app_bar_color: 'warning',
					headline: null,
					hideText: null,
					paragraphs: [],
					titleIcon: null,
					width: 500,
					zIndex: 200
				};
			}
		}
	};
</script>
