<!--
	@name app-client-dashboard-workflow-affordability-activity
	@description Client Affordability Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="loading" type="list-item-three-line" />

		<v-sheet v-else-if="!items?.length">
			<v-row>
				<v-col v-if="site && 'usesAirFlow' in site && site.usesAirFlow === false" cols="12">
					<p class="text-body-1 font-weight-bold mb-6">
						This integrated Affordability flow will save you time and ensure affordability remains at the forefront of delivering the best advice possible.
					</p>
					<ul class="text-body-1 pl-8 mb-8">
						<li class="mb-2">
							Directly access both LLL Navigator from within the “Affordability” tile.
						</li>
						<li class="mb-2">
							Easy access to the evidence of your affordability discussions via a PDF output once completed.
						</li>
						<li class="mb-0">
							Ability to make amendments to your affordability discussions where any alterations can be saved and uploaded once completed.
						</li>
					</ul>
					<v-btn color="primary" :loading="submitting" @click="createProcesses()">
						Get started
					</v-btn>
				</v-col>
				<v-col v-else cols="12">
					<video v-if="introResource" class="d-block ml-10 mb-6" :src="introResource.video" :poster="introResource.poster" controls width="50%" style="float: right" />
					<p class="text-body-1 font-weight-bold mb-6">
						This integrated Affordability flow will save you time and ensure affordability remains at the forefront of delivering the best advice possible.
					</p>
					<ul class="text-body-1 pl-8 mb-6">
						<li class="mb-2">
							Directly access both of Air's LLL Navigator tools from within AirFlow's “Affordability” tile.
						</li>
						<li class="mb-2">
							Easy access to the evidence of your affordability discussions via a PDF output once completed.
						</li>
						<li class="mb-0">
							Ability to make amendments to your affordability discussions where any alterations can be saved and uploaded once completed.
						</li>
					</ul>
					<p class="text-body-1 mb-8">
						Click on the intro video now to gain a deeper insight into how you can navigate AirFlow's Affordability tile and maximise its benefits and features.
					</p>
					<v-btn color="primary" :loading="submitting" @click="createProcesses()">
						Get started
					</v-btn>
					<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
				</v-col>
			</v-row>
		</v-sheet>

		<div v-else>
			<v-timeline align-top dense class="pa-0">
				<v-timeline-item v-for="(item, index) in items" :key="index" :color="item.completed ? 'grey' : 'grey'" small class="py-6">
					<v-row no-gutters>
						<v-col>
							<p class="text-h6 mt-n2 mb-1">
								<span class="font-weight-bold">
									{{ item.title }}
								</span>
								<v-chip label x-small dark :color="getStatusColor(item.completed ? 'Completed' : 'In Progress')" style="position: relative; left: 8px; top: -4px">
									{{ item.completed ? 'Completed' : 'In Progress' }}
								</v-chip>
							</p>
							<p class="text-body-2 mb-4">
								Use our quick calculator to determine which options are available for your customer(s)
							</p>

							<v-timeline align-top dense class="pt-1">
								<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon color="success">
											mdi-check-bold
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Created: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block">
												{{ item.created | moment('MMM Do YYYY, h:mma') }}
											</span>
										</v-col>
									</v-row>
								</v-timeline-item>
								<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon v-if="item.completed" color="success">
											mdi-check-bold
										</v-icon>
										<v-icon v-else color="error">
											mdi-close-thick
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block">
												{{ item.canBeSkipped && item.completed && !item.document ? 'Skipped' : 'Completed' }}
											</span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block">
												<template v-if="item.completed">
													{{ item.completed | moment('MMM Do YYYY, h:mma') }}
												</template>
												<template v-else> -- </template>
											</span>
										</v-col>
									</v-row>
								</v-timeline-item>
							</v-timeline>

							<div class="mt-4 mx-n1">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn color="primary" class="mx-1" @click="openAffordabilityTool(item)" v-on="on">
											{{ item.completed ? 'open' : 'get started' }}
										</v-btn>
									</template>
									<span>Open {{ item.title }} tool</span>
								</v-tooltip>

								<v-btn v-if="item.canBeSkipped && !item.completed" class="mx-1" color="warning" dark outlined :loading="submitting" @click="skip(item)">
									Skip
								</v-btn>

								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn color="info" class="mx-1" icon :disabled="!item.document" :loading="fileLoading[item.id]" @click="fetchDocument(item)" v-on="on">
											<v-icon> mdi-file-download </v-icon>
										</v-btn>
									</template>
									<span>Download PDF</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>
				</v-timeline-item>
			</v-timeline>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools, PopupWindow, EventBus } from '@/utils';

	export default {
		name: 'app-client-dashboard-workflow-affordability-activity',

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				items: null,
				loading: true,
				submitting: false,
				fileLoading: {}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('AppWorkflow', ['getStatusColor'])
		},

		created() {
			this.fetchItems();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('Affordability', ['fetchAffordabilityList', 'submitAffordability', 'markAsCompleted', 'fetchAffordabilityFile']),

			/**
			 * @name onMessage
			 * @description Handle websocket messages
			 * @param message
			 */
			onMessage(message) {
				if (message?.type !== 'affordability') return;
				const index = this.items.findIndex((i) => i.id === message.id);
				this.$set(this.items, index, { ...this.items[index], completed: message.completed, document: message.document });
				this.$emit('workflow-updated');
			},

			/**
			 * @name fetchItems
			 * @description Fetch affordability list
			 * @param item
			 */
			async fetchItems() {
				try {
					this.items = await this.fetchAffordabilityList({ entityIds: [this.asset.id] }).then((data) => data.data);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch affordability items');
				}

				if (!this.items?.length) this.$emit('toggle-intro-resource');

				this.loading = false;
			},

			/**
			 * @name createProcesses
			 * @description Create new matter with both Eligibility & Priorities and Product Comparison processes
			 */
			async createProcesses() {
				const client = [{ id: this.client.id }];
				if (this.association) client.push({ id: this.association.id });

				this.submitting = true;

				try {
					const data = await this.submitAffordability({ client, asset: { id: this.asset.id } }).then((data) => data.data);
					this.openAffordabilityTool(data);
					this.fetchItems(); // Fetch affordability processes again to update view
					this.$emit('workflow-updated'); // Update overall status
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to create affordability matter');
				}

				this.submitting = false;
			},

			/**
			 * @name skip
			 * @description Mark process as completed
			 */
			async skip(item) {
				this.submitting = true;

				try {
					await this.markAsCompleted({ id: item.id, payload: { completed: true } });
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to mark as completed');
				}

				this.submitting = false;
			},

			/**
			 * @name openAffordabilityTool
			 * @description Redirect user to affordability tool
			 */
			openAffordabilityTool(item) {
				let popupWindow = window.open(item.redirectUrl, '_blank');
				if (!popupWindow || popupWindow.closed || typeof popupWindow.closed == 'undefined') {
					EventBus.$emit('popup-blocked', { url: item.redirectUrl, target: '_blank' });
				}
			},

			/**
			 * @name fetchDocument
			 * @description Fetch affordability tool document
			 * @param item item that needs updating
			 * @param s3Key
			 */
			async fetchDocument(item) {
				const { id, document } = item;
				this.$set(this.fileLoading, item.id, true);

				this.fetchAffordabilityFile({ id: document.id })
					.then((response) => {
						if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, id, false));
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>

<style type="text/css" lang="scss" scoped>
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	.v-timeline-item:has(.v-timeline-item):nth-child(even) {
		background: rgba(30, 176, 225, 0.1);
		background: linear-gradient(90deg, rgba(27, 120, 216, 0.05) 0%, rgba(103, 0, 86, 0.05) 100%);
	}
</style>
