<!--
	@name common-form-property
	@description Property details section
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="d-flex">
		<div v-if="propertyForm && propertyForm.length" class="flex-grow-1">
			<common-structure-section class="flex-grow-1 mb-6 elevation-2">
				<template #header>
					Property Details
				</template>

				<template #body>
					<div v-if="propertyLoading" class="flex-grow-1 mx-n4">
						<v-skeleton-loader type="list-item-three-line" />
					</div>

					<div v-else-if="isEmpty(client)">
						<v-alert dense text border="left" type="info" class="mb-0 text-subtitle-2">
							Please select an applicant to proceed
						</v-alert>
					</div>

					<div v-else-if="isEmpty(property)">
						<v-btn-toggle v-model="propertyToggle" :mandatory="mandatory" dense class="d-flex" color="primary" @change="propertyToggleChanged()">
							<v-tooltip :disabled="!client.hasFactFind" bottom color="info">
								<template #activator="{ on }">
									<div class="v-btn-toggle v-btn-toggle--dense" v-on="on">
										<v-btn
											:disabled="client.hasFactFind || !noExistingProperty"
											elevation="0"
											style="border-top-right-radius: 0; border-bottom-right-radius: 0"
										>
											<div>Create a new property</div>
										</v-btn>
									</div>
								</template>
								<span>
									{{
										client.hasFactFind
											? 'Applicant 1 is associated with a Fact Find. You can only add new properties from WriteRoute.'
											: !noExistingProperty
												? 'Applicant 1 already has one or more Properties. Please select an existing Property to proceed.'
												: ''
									}}
								</span>
							</v-tooltip>

							<v-btn :disabled="!client || !client.id || noExistingProperty">
								Choose an existing property
							</v-btn>
						</v-btn-toggle>

						<v-alert v-if="showError" dense text border="left" type="error" class="mt-4 mb-0 text-subtitle-2">
							You need to select a property to proceed
						</v-alert>

						<v-autocomplete
							v-show="propertyToggle === 1"
							v-model="selectedProperty"
							class="mt-6"
							:items="propertyOptionList"
							:loading="isLoading"
							hide-no-data
							:hide-details="true"
							:hide-selected="false"
							item-text="text"
							item-value="value"
							label="Property List"
							placeholder="Start typing to Search"
							autocomplete="off"
							@click="fetchAssetList"
							@change="fetchAsset(client.id, selectedProperty)"
						/>
					</div>

					<div v-else>
						<p class="text-body-1 ma-0">
							<span class="detail font-weight-bold">{{ getFullAddress(property) }}</span>
							<span> - a </span>
							<span class="detail font-italic">{{ getDisplayValuebyId('tenureId') || property.tenureName }} </span>
							<span class="detail font-italic">{{ getDisplayValuebyId('assetDescriptionId') || property.assetDescriptionName }}</span>
							<span> valued at </span>
							<span class="detail font-italic">£{{ property.valuation | numFormat('0,0') }}</span>
						</p>

						<v-alert dense text border="left" :type="isValid ? 'success' : 'error'" class="mt-2 mb-0">
							<v-row align="center" class="px-3">
								<v-col v-if="isValid" class="grow pa-0 text-subtitle-2">
									You have entered the required details for this Property
								</v-col>
								<v-col v-else class="grow pa-0 text-subtitle-2">
									We require further information relating to this Property.
								</v-col>

								<v-col class="shrink pa-0">
									<v-btn
										small
										dark
										@click="openDynamicFormDialog(propertyForm, property, 'Update Property Details', 'Property Details', 'set-property', 'update')"
									>
										Update Details
									</v-btn>
								</v-col>

								<v-col v-if="!editOnly && !hasSingleProperty" class="shrink pa-0 ml-2">
									<v-btn small dark color="error" @click="requestRemoveProperty()">
										Remove
									</v-btn>
								</v-col>
							</v-row>
						</v-alert>

						<v-alert
							v-if="showAnotherProperty && anotherPropertyForm.length"
							dense
							text
							border="left"
							:type="isAnotherPropertyValid ? 'success' : 'error'"
							class="mt-2 mb-0"
						>
							<v-row align="center" class="px-3">
								<v-col class="grow pa-0 text-subtitle-2">
									<span v-if="!isAnotherPropertyValid">Please enter the details of the Property being Purchased</span>
									<span v-else>Details of the Property being Purchased are updated</span>
								</v-col>
								<v-col class="shrink pa-0">
									<v-btn
										small
										dark
										@click="
											openDynamicFormDialog(
												anotherPropertyForm,
												anotherProperty,
												'Submit Property Details',
												'Purchase of another Property',
												'set-another-property',
												'update'
											)
										"
									>
										Update Details
									</v-btn>
								</v-col>

								<v-col v-if="!editOnly && !hasSingleProperty" class="shrink pa-0 ml-2">
									<v-btn small dark color="error" @click="requestRemoveProperty()">
										Remove
									</v-btn>
								</v-col>
							</v-row>
						</v-alert>

						<v-sheet v-if="property.postcode && floodReport" class="mt-3">
							<common-base-flood-report :postcode="property.postcode" />
						</v-sheet>
					</div>
				</template>
			</common-structure-section>

			<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="setProperty" @closed="dialogClosed" />
			<common-dialog-confirm ref="confirm" />
		</div>

		<v-card v-else class="flex-grow-1 mb-6">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools, airbrake } from '@/utils';
	import { isEmpty } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonBaseFloodReport from '@/component/common/base/flood-report';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';

	export default {
		name: 'common-form-property',

		components: {
			CommonStructureSection,
			CommonBaseFloodReport,
			CommonDialogConfirm,
			DynamicFormDialog
		},

		props: {
			client: { type: Object, default: () => {} },
			floodReport: { type: Boolean, required: false, default: false },
			propertyForm: { type: Array, required: true, default: () => [] },
			property: { type: Object, required: true, default: () => ({}) },
			anotherPropertyForm: { type: Array, default: () => [] },
			anotherProperty: { type: Object, default: () => ({}) },
			showAnotherProperty: { type: Boolean, default: false },
			showError: { type: Boolean, default: false },
			editOnly: { type: Boolean, default: false },
			loading: { type: Boolean, default: false }
		},

		data() {
			return {
				isValid: false,
				isAnotherPropertyValid: false,
				propertyToggle: null,
				mandatory: false,
				selectedProperty: null,
				isLoading: false,
				propertyLoading: false,
				propertyList: []
			};
		},

		computed: {
			/**
			 * @name propertyOptionList
			 * @description property select option list
			 * @return {Array} property list
			 */
			propertyOptionList() {
				return this.propertyList.map((property) => ({
					value: property.id,
					text: this.getFullAddress(property.data.location)
				}));
			},

			/**
			 * @name hasSingleProperty
			 * @description Check if the client has only one property
			 * @return {Boolean} hasSingleProperty
			 */
			hasSingleProperty() {
				return this.propertyList?.length === 1;
			},

			/**
			 * @name noExistingProperty
			 * @description Check if the client has no existing property
			 * @return {Boolean} noExistingProperty
			 */
			noExistingProperty() {
				return this.propertyList?.length === 0;
			}
		},

		watch: {
			property(val) {
				if (!val || isEmpty(val)) {
					this.mandatory = false;
					this.propertyToggle = null;
					this.selectedProperty = null;
				}
			},

			client: {
				handler(client, oldClient) {
					console.log('client.id :>> ', client.id);
					console.log('oldClient.id :>> ', oldClient.id);
					if (client.id) this.fetchAssetList();
				},
				deep: true
			}
		},

		methods: {
			...mapActions('AppClientAsset', ['loadClientAssetList', 'loadClientAsset']),

			/**
			 * @name fetchAssetList
			 * @description Fetch client's properties from server
			 */
			async fetchAssetList() {
				if (this.propertyList.length > 0 || this.isLoading) return;
				this.propertyLoading = true;

				if (!this.client.id) {
					airbrake.notify({
						error: new Error('Client ID is not available / property selector'),
						params: { client: this.client, component: this.$options.name, parent: this.$parent?.$options?.name }
					});
				}

				this.isLoading = true;
				this.propertyList = await this.loadClientAssetList({ clientId: this.client.id, type: 'property' });

				if (this.hasSingleProperty) this.fetchAsset(this.client.id, this.propertyList[0].id);
				else this.propertyLoading = false;

				this.isLoading = false;
			},

			/**
			 * @name fetchAsset
			 * @description Fetch property details
			 */
			async fetchAsset(clientId, assetId) {
				this.propertyLoading = true;

				try {
					let { data: asset } = await this.loadClientAsset({ id: assetId, clientId: clientId });
					let additionalCriteria = [];
					if (asset.data.detail?.buyToLet) additionalCriteria.push('a_buy_to_let');
					if (asset.data.detail?.ageRestricted && asset.data.detail?.ageRestricted.value) additionalCriteria.push('age_restricted');
					if (asset.data.detail?.secondHome) additionalCriteria.push('second_home');
					if (asset.data.detail?.sheltered) additionalCriteria.push('sheltered');
					if (asset.data.detail?.ex && asset.data.detail?.ex.localAuthority) additionalCriteria.push('ex_local_authority');

					let property = {
						id: asset.id,
						type: asset.type,

						// construction
						constructionDetails: asset.data.construction?.details,
						numberOfFloors: asset.data.construction?.numberOfFloors,
						standard: asset.data.construction?.standard,

						// cost
						groundRent: asset.data.cost?.groundRent,
						serviceCharge: asset.data.cost?.serviceCharge,

						// detail
						additionalCriteria,
						ageRestrictedDetails: asset.data.detail?.ageRestricted.details,
						assetDescriptionId: asset.data.detail?.assetDescriptionId,
						assetDescriptionName: asset.data.detail?.assetDescriptionName,
						epcRating: asset.data.detail?.epcRating,
						listed: asset.data.detail?.listed,
						listedBuildingGradeId: asset.data.detail?.listedBuildingGradeId,
						servedByLift: asset.data.detail?.servedByLift,
						tenureId: asset.data.detail?.tenureId,
						tenureName: asset.data.detail?.tenureName,
						unexpiredLeaseTerm: asset.data.detail?.unexpiredLeaseTerm,
						whollyPrivatelyOwned: asset.data.detail?.whollyPrivatelyOwned,

						// location
						address1: asset.data.location?.address1,
						address2: asset.data.location?.address2,
						townCity: asset.data.location?.townCity,
						county: asset.data.location?.county,
						countryId: asset.data.location?.countryId,
						postcode: asset.data.location?.postcode,
						floor: asset.data.location?.floor,
						aboveRetailPremises: asset.data.location?.aboveRetailPremises,

						// mortgage
						mortgageTypeId: asset.data.mortgage?.mortgageTypeId,
						mortgageTypeName: asset.data.mortgage?.mortgageTypeName,
						outstandingMortgage: asset.data.mortgage?.outstanding,

						// valuation
						valuation: asset.data.valuation?.value
					};

					this.setProperty(property);

					// wait for validation to prevent flicker
					await new Promise((resolve) => setTimeout(resolve, 20));
					this.propertyLoading = false;
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to load property details');
					this.propertyLoading = false;
				}
			},

			/**
			 * @name setProperty
			 * @description Pass payload through parent
			 * @param {Object} payload
			 */
			setProperty(payload) {
				const action = payload.config ? payload.config.action : 'set-property';
				this.$emit(action, payload.data || payload);

				if (payload.config) {
					if (payload.config.type === 'add') ElementTools.fireNotification(this.$el, 'success', 'Property has been successfully added');
					else ElementTools.fireNotification(this.$el, 'success', 'Property has been successfully updated');
				}
			},

			propertyToggleChanged() {
				this.mandatory = true;
				this.selectedProperty = null;

				if (this.propertyToggle === 0) this.openDynamicFormDialog(this.propertyForm, {}, 'Add Property', 'Property Details', 'set-property', 'add');
				else if (this.propertyToggle === 1) this.fetchAssetList();
			},

			/**
			 * @name requestRemoveProperty
			 * @description Request the removal of a selected property.
			 */
			requestRemoveProperty() {
				this.$refs.confirm
					.open('Remove Property', 'Are you sure you wish to remove this Property?')
					.then(async() => {
						this.removeProperty();
					})
					.catch(function() {});
			},

			/**
			 * @name removeProperty
			 * @description Remove selected property.
			 */
			removeProperty() {
				this.setProperty({});
				this.selectedProperty = null;
			},

			/**
			 * @name dialogClosed
			 * @description Dynamic form dialog close without submitting event handler
			 */
			dialogClosed() {
				if (this.propertyToggle === 1) return;

				this.propertyToggle = null;
				this.mandatory = false;
				this.selectedProperty = null;
				this.isLoading = false;
				this.search = null;
			},

			/**
			 * @name isEmpty
			 * @description Wrapper method for lodash isEmpty for using the method inside template
			 * @param {*} val Value will be checked
			 */
			isEmpty: (val) => isEmpty(val),

			/**
			 * @name validateForm
			 * @description Validates item details form and sets result to store
			 */
			async validateForm() {
				this.isValid = await this.$refs.dynamicFormDialog.validateForm(this.propertyForm, this.property);

				if (this.showAnotherProperty && this.anotherPropertyForm.length) {
					this.isAnotherPropertyValid = await this.$refs.dynamicFormDialog.validateForm(this.anotherPropertyForm, this.anotherProperty);
				} else this.isAnotherPropertyValid = true;

				this.$emit('set-validation', { property: this.isValid && this.isAnotherPropertyValid });
			},

			/**
			 * @name getFullAddress
			 * @description Builds a full address text from address details
			 * @return {String} Full address
			 */
			getFullAddress(property) {
				let { address1, address2, townCity, postcode } = property;
				return [address1, address2, townCity, postcode].filter(Boolean).join(', ');
			},

			/**
			 * @name getDisplayValuebyId
			 * @description Get display value of the data to displaying meaningful data to user instead of id
			 * @param {String} fieldName Name of field to be displayed
			 */
			getDisplayValuebyId(fieldName) {
				let field = this.propertyForm.find((field) => field.name === fieldName);
				return field?.options?.find((option) => option.value == this.property[fieldName])?.text;
			},

			/**
			 * @name openDynamicFormDialog
			 * @description Event for open dynamic form dialog
			 * @param {Array} formSchema Schema for creating form layout
			 * @param {Object} formData Data for populating form
			 * @param {String} title Dialog title
			 * @param {String} action Action to be dispached after form submission
			 * @param {Number} dataIndex Index of the data to be updated if data type is an array (Ex: Applicants)
			 */
			openDynamicFormDialog(formSchema, formData, submitButtonText, title, action, type) {
				this.$refs.dynamicFormDialog.open({
					formSchema,
					formData,
					submitButtonText,
					title,
					action,
					type
				});
			},

			/**
			 * @name reset
			 * @description Reset the component
			 */
			reset() {
				this.isValid = false;
				this.isAnotherPropertyValid = false;
				this.propertyToggle = null;
				this.mandatory = false;
				this.selectedProperty = null;
				this.isLoading = false;
				this.propertyLoading = false;
				this.propertyList = [];
			}
		}
	};
</script>
