<!--
	@name app-kfi-request-product
	@description KFI Request Product
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :class="{ 'h-100':!demo }" :component="$options.name">
		<v-tooltip class="tooltip--override" top max-width="300" :disabled="!tooltipMessage">
			<template #activator="{ on }">
				<v-card
					:disabled="isLoading"
					:hover="clickable ? true : false"
					:ripple="clickable ? true : false"
					height="100%"
					class="d-flex flex-column mb-0"
					:class="statusClass"
					style="position: relative"
					@click="clickable ? clickHandler($event) : null"
					v-on="on"
				>
					<v-icon
						:color="isLoading ? 'black' : isComplete ? 'success' : isExternalComplete ? 'success' : isExternal ? 'warning' : isFailed ? 'error' : !isApi ? 'secondary' : 'info'"
						class="white"
						:class="{ 'icon--spin':isLoading || isProcessing }"
						size="30"
						style="position: absolute; right: -10px; top: -10px; border-radius: 100px;"
					>
						{{ isLoading ? 'mdi-refresh-circle' : isComplete ? 'mdi-check-circle' : isExternalComplete ? 'mdi-check-circle' : isExternal ? 'mdi-alert-circle' : isFailed ? 'mdi-alert-circle' : !isApi ? 'mdi-account-circle' : 'mdi-sync-circle' }}
					</v-icon>

					<v-card-subtitle class="text-caption plain pb-0">
						{{ product.product.provider.name }}
					</v-card-subtitle>
					<v-card-title class="text-body-1 font-weight-bold pt-0 pb-2">
						<span class="line-clamp" style="-webkit-line-clamp: 1">
							{{ product.product.name }}
						</span>
					</v-card-title>
					<v-card-text class="flex-grow-1" v-html="$sanitize(statusText)" />
				</v-card>
			</template>
			<span>
				<div class="text-center">
					{{ tooltipMessage }}
				</div>
			</span>
		</v-tooltip>

		<common-dialog ref="externalKfiRequestDialog">
			<template #header>
				Confirm Request KFI
			</template>
			<template #body>
				<p class="mb-5 text-subtitle-1">
					Please confirm that you wish to request a KFI from {{ product.product.provider.name }} for the {{ product.product.name }} product.
					<strong>PLEASE NOTE:</strong> This will divert you to provider website where you will be asked to enter all client and property information again to request your KFI.
					<a v-if="!isExternalComplete" class="error--text" @click="deleteProductHandler(product)">Or, click here to remove this product from your request</a>.
				</p>
				<v-card-actions class="pa-0">
					<v-spacer />
					<v-btn color="primary mr-1" :loading="externalKfiRequestLoading" @click="externalKfiRequestHandler(product)">
						Confirm
					</v-btn>
					<v-btn outlined color="blue-grey" @click="closeDialog('externalKfiRequestDialog')">
						Cancel
					</v-btn>
				</v-card-actions>
			</template>
		</common-dialog>
		<common-dialog ref="failedRequestDialog" @closed="failedRequestDialogClosed">
			<template #header>
				KFI Request Failed
			</template>
			<template #body>
				<p class="mb-5 text-body-2">
					KFI requests can fail for a variety of reasons, most of which are easily overcome by speaking with our support team.
					<span v-if="isApi">
						Occasionally, API enabled requests can fail because of a service timeout. In these instances, clicking to resubmit the request will usually result in a successful response.
						<strong>
							The most common cause of a failed KFI request is missing/invalid API credentials. We would advise you to <a target="_blank" href="/settings/credentials">check your {{ product.product.provider.name }} credentials are accurate</a> and, if appropriate, click to resubmit this request.
						</strong>
					</span>
				</p>

				<v-item-group>
					<v-row>
						<v-col cols="12" sm="6" class="py-0">
							<v-item>
								<v-card :disabled="resubmitDisabled" class="text-h6 font-weight-regular pa-6 text-center align-content-center grey lighten-2" height="100%" @click="resubmitKfiRequest">
									<template v-if="resubmitting">
										<strong class="d-block mb-3">Resubmitting your KFI request</strong>
										<v-progress-linear indeterminate />
									</template>
									<template v-else-if="isProcessing">
										<strong class="d-block mb-3">Processing KFI request</strong>
										<v-progress-linear indeterminate />
									</template>
									<template v-else-if="maxResubmitAttemptsReached">
										<span class="font-weight-medium error--text">
											We are unable to resubmit this request. Please contact our support team
										</span>
									</template>
									<template v-else>
										<span class="primary">Click here</span> to <strong>attempt to immediately resubmit</strong> your KFI request
									</template>
								</v-card>
							</v-item>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<v-item>
								<v-card class="text-h6 font-weight-regular pa-6 text-center grey lighten-2" height="100%" @click="openChat">
									<span class="accent">Click here</span> to <strong>open up a chat session</strong> with our support team
								</v-card>
							</v-item>
						</v-col>
					</v-row>
				</v-item-group>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { orderBy } from 'lodash';
	import { mapActions } from 'vuex';
	import { EventBus, ElementTools, PopupWindow } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'app-kfi-request-product',

		components: {
			CommonDialog,
			CommonDialogConfirm
		},

		props: {
			product: { type: Object, required: true },
			index: { type: Number, required: true },
			clientId: { type: String, required: true },
			socketsEnabled: { type: Boolean, required: false, default: true },
			demo: { type: Boolean, required: false, default: false }
		},

		data() {
			return {
				kfiStageId: '10cea1e8-8edb-4615-a3e3-8d66ad57f401',
				pulse: false,
				isLoading: false,
				externalKfiRequestLoading: false,
				failedRequestDialogOpen: false,
				resubmitting: false,
				maxResubmitAttempts: 3,
				times: {
					one: {
						start: '00:00 AM',
						end: '03:00 PM'
					},
					two: {
						start: '03:00 PM',
						end: '04:40 PM'
					},
					three: {
						start: '04:40 PM',
						end: '11:59 PM'
					}
				},
				time: null
			};
		},

		computed: {
			clickable() {
				if (this.demo) return false;
				if (this.isApi && this.isProcessing) return false;
				return true;
			},
			status() {
				if (this.product.status) return this.product.status;
				return this.getStatus(this.product.action);
			},

			isApi() {
				return this.product.product.sqs;
			},

			isExternal() {
				return this.product?.product?.keyFactsIllustration?.online && this.product?.product?.keyFactsIllustration?.url;
			},

			isExternalComplete() {
				return this.isExternal && this.product.kfiRequestSubmitted;
			},

			isFailed() {
				return ['Failed', 'KFI Request Failed'].includes(this.status);
			},

			isComplete() {
				return ['Completed', 'KFI Received'].includes(this.status);
			},

			isProcessing() {
				return this.isApi && !this.isFailed && !this.isComplete;
			},

			tooltipMessage() {
				if (this.demo) return false;
				if (this.isComplete) return 'Click here to access documents';
				else if (this.isExternalComplete) return 'KFI has been requested externally. Click here to start again';
				else if (this.isFailed) return 'Click here to find out more';
				else if (this.isExternal) return 'Click here to proceed, or remove this product from your request';
				else if (!this.isApi) return 'Click here to find out more';
				else if (this.isProcessing && this.socketsEnabled) return 'Our system is communicating with the lender partner system via API. This process usually takes no more than a couple of minutes';
				else if (this.isProcessing && !this.socketsEnabled) return 'Our system is communicating with the lender partner system via API. This process usually takes no more than a couple of minutes';
				return false;
			},

			statusText() {
				if (this.product.overrideStatusText) return this.product.overrideStatusText;
				else if (this.isComplete) return '<p class="text-caption plain grey--text text--darken-3 mb-0">Success! Your KFI request has completed. Click here to access documents.</p>';
				else if (this.isExternalComplete) return '<p class="text-caption plain grey--text text--darken-3 font-weight-medium mb-0">Success! You have initiated the process of requesting this KFI externally. Click here to start again.</p>';
				else if (this.isFailed) return '<p class="text-caption plain error--text font-weight-bold mb-0">KFI request failed. Click here to find out more.</p>';
				else if (this.isExternal) return '<p class="text-caption plain warning--text text--darken-1 font-weight-medium mb-0">KFI requests for this product must be completed on the providers own website. Click here to proceed, or remove this product from your request.</p>';
				else if (!this.isApi) return '<p class="text-caption plain grey--text text--darken-3 mb-0">This lender does not yet support API communication. Our team will manually process this KFI on your behalf.</p>';
				else if (this.isProcessing) return '<p class="text-caption plain mb-0">To be processed immediately through our integrated API. <span class="info--text font-weight-medium">If you do not recieve the KFI document within 10 minutes, please contact our support team</span></p>';
				return '<p class="text-caption plain grey--text text--darken-3 mb-0">...</p>';
				//return '<p class="text-caption plain mb-0 error--text font-weight-medium">Rate change today: As this KFI has been requested outside of SLA, we suggest that you go direct to the lender in order to secure the current rate</p>';
			},

			statusClass() {
				let _class = '';
				if (this.isComplete) _class = "kfi--complete";
				else if (this.isExternalComplete) _class = "kfi--external-complete";
				else if (this.isFailed) _class = "kfi--failed";
				else if (this.isExternal) _class = "kfi--external";
				else if (!this.isApi) _class = "kfi--manual";
				else if (this.isProcessing) _class = "kfi--processing";
				if (this.pulse) _class += " kfi--pulse";
				return _class;
			},

			maxResubmitAttemptsReached() {
				return this.product.retryCount >= this.maxResubmitAttempts;
			},

			resubmitDisabled() {
				return this.resubmitting || this.maxResubmitAttemptsReached || this.isProcessing;
			}
		},

		watch: {
			'product.status': {
				handler(e) {
					if (e) this.pulse = true;
					if (e.toLowerCase() === 'failed' && this.failedRequestDialogOpen) ElementTools.fireNotification(this.$el, 'error', 'The attempt to resubmit your KFI request has failed');
				},
				deep: true,
				immediate: true
			}
		},

		created() {
			this.setTime();
		},

		methods: {
			...mapActions('LifetimeMortgageKfi', ['submitKfi', 'deleteProduct', 'sendKfiBackToQueue']),
			...mapActions('AppClient', ['getUserConnect']),
			...mapActions('AppClientConnected', ['loadConnectedClientList']),

			setTime() {
				Object.keys(this.times).some((time) => {
					let obj = this.times[time];
					let start = this.$moment(obj.start, 'hh:mm A');
					let end = this.$moment(obj.end, 'hh:mm A');
					let cur = this.$moment();
					let isInBetween = cur.isBetween(start, end);
					if (isInBetween) this.time = time;
					return this.time;
				});
			},

			clickHandler(e) {
				if (this.demo) return null;
				if (this.isFailed) this.openFailedKfiRequest();
				else if (this.isExternal) this.openExternalKfiRequest();
				else if (e.target.id === 'delete-kfi') this.deleteProductHandler(this.product);
				else if (!this.isApi || !this.isProcessing) this.goToClient();
			},

			async goToClient() {
				this.isLoading = true;

				let path = `/client/${this.clientId}?workflowSection=${this.kfiStageId}`;

				const {
					data: { data: associationList }
				} = await this.loadConnectedClientList({ clientId: this.clientId, payload: { order: { property: 'updated', direction: 'desc' } } });

				if (associationList?.length) {
					let association = associationList.find((a) => a.relation === 'spouse');
					if (association) {
						this.association = association;
						this.associationId = association.id;
					}
				}

				if (this.association) {
					await this.getUserConnect({ clientIds: [this.clientId, this.associationId] }).then(({ data }) => {
						if (this.clientId !== data.mainClient) path = `/client/${data.mainClient}?workflowSection=${this.kfiStageId}`;
					});
				}

				//Check we haven't already changed pages before we implement nav change
				this.isLoading = false;
				if (this.$route.name === 'kfiRequest') this.$router.push(path);
			},

			getStatus(actions) {
				if (!actions || !actions.length) return null;
				let statuses = actions.filter((a) => a.type === 'status');
				if (!statuses) return null;
				return orderBy(statuses, 'created', ['desc'])?.[0]?.data?.status;
			},

			openFailedKfiRequest() {
				this.$refs.failedRequestDialog.open();
				this.failedRequestDialogOpen = true;
			},

			failedRequestDialogClosed() {
				this.failedRequestDialogOpen = false;
			},

			openExternalKfiRequest() {
				this.$refs.externalKfiRequestDialog.open();
			},

			externalKfiRequestHandler(product) {
				this.externalKfiRequestLoading = true;
				this.isLoading = true;

				const payload = {
					processId: product.processId,
					iterationId: product.id,
					data: {
						action: {
							key: 'SubmitKeyFactsIllustration'
						}
					}
				};

				this.submitKfi(payload)
					.then((response) => {
						const product = response.data;
						this.$emit('refresh-products');
						new PopupWindow().open(product.data.keyFactsIllustration.url, '_blank');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
					.finally(() => {
						this.closeDialog('externalKfiRequestDialog');
						this.externalKfiRequestLoading = false;
						this.isLoading = false;
					});
			},

						/**
			 * @name deleteProduct
			 * @description Remove product from KFI submissions after confirmation
			 * @param {Object} productObj The product object
			 */
			 deleteProductHandler(product) {
				let msg = 'Are you sure you wish to remove this product from your submission?';
				this.$refs.confirm.open('Remove Product', msg).then(async() => {
					this.isLoading = true;

					const payload = {
						processId: product.processId,
						iterationId: product.id,
						data: {
							action: {
								key: 'DeleteKeyFactsIllustrationProduct'
							}
						}
					};

					this.deleteProduct(payload)
						.then(() => {
							this.$emit('remove-product', product);
							ElementTools.fireNotification(
								this.$el,
								'success',
								`Product "${product.product.name} by ${product.product.provider.name}" has been successfully removed`
							);
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to remove product'))
						.finally(() => this.isLoading = false);
				});
			},

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			},

			closeDialog(ref) {
				this.$refs[ref].close();
			},

			/**
			 * @name resubmitKfiRequest
			*/
			resubmitKfiRequest() {
				this.resubmitting = true;
				this.sendKfiBackToQueue(this.product.id)
				.then((res) => {
					this.$set(this.product, 'status', 'KFI Request Submitted');
					this.$set(this.product, 'retryCount', res.data.retryCount);
				})
				.catch((err) => {
					ElementTools.fireNotification(this.$el, 'error', err.data?.message || 'Failed to send KFI back to queue');
				})
				.finally(() => {
					this.resubmitting = false;
				});
			},

			/**
			 * @name openChat
			 */
			openChat: function() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.kfi--pulse {
		animation: kfi--pulse 1.5s 1;
	}

	@keyframes kfi--pulse {
		0% {
			box-shadow: 0 0 0 0 rgba($success, 0.5);
			transform: scale(1);
		}
		10% {
			transform: scale(1.05);
		}
		20% {
			transform: scale(1);
		}
		70% {
			box-shadow: 0 0 25px 30px rgba($success, 0);
		}
		100% {
			box-shadow: 0 0 0 0 rgba($success, 0);
		}
	}

	.kfi--external {
		border: 1px solid $warning;
		background-color: tint($warning, 95%);
		animation: kfi--external 2s infinite;
	}

	.kfi--manual {
		border: 1px solid $col5;
		background-color: tint($col5, 95%);
	}

	.kfi--failed {
		border: 1px solid $error;
		background-color: tint($error, 95%);
	}

	.kfi--external-complete {
		border: 1px solid $success;
		background-color: tint($success, 95%);
	}

	.kfi--complete {
		border: 1px solid $success;
		background-color: tint($success, 95%);
	}

	.kfi--processing {
		border: 3px solid #000000;
		animation: kfi--processing 2s infinite;
	}

	@keyframes kfi--external {
		0%,
		100% {
			border-color: $warning;
			background-color: tint($warning, 70%);
		}
		50% {
			border-color: $error;
			background-color: tint($error, 70%);
		}
	}

	@keyframes kfi--processing {
		0%,
		100% {
			border-color: $col5;
			background-color: tint($col1, 70%);
		}
		50% {
			border-color: $col4;
			background-color: tint($col3, 70%);
		}
	}

	.v-tooltip__content {
		background: rgba(map-get($grey, 'darken-3'), 0.9) !important;
	}
</style>
