<!--
	@name app-client-dashboard-workflow-application-activity
	@description Client Application Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet>
			<v-skeleton-loader v-if="loading" type="list-item-three-line" />

			<v-sheet v-else-if="!kfis?.length">
				<v-row>
					<v-col v-if="site && 'usesAirFlow' in site && site.usesAirFlow === false" cols="12">
						<p class="text-body-1 font-weight-bold mb-6">
							Increase efficiency by visually managing and tracking the latest status of current and any previously requested or recommended KFI's.
						</p>
						<ul class="text-body-1 pl-8 mb-8">
							<li class="mb-2">
								Directly navigate to your sourcing results and request your Key Facts Illustration.
							</li>
							<li class="mb-2">
								Efficiently track the status of your requested KFI from “Submitted,” through to “Completed” or “Failed”.
							</li>
							<li class="mb-0">
								Download a copy of the research results or KFI, as well as the ability to navigate directly to the lender's portal for application.
							</li>
						</ul>
						<v-btn color="primary" @click="createSourcingSession()">
							Get started
						</v-btn>
					</v-col>
					<v-col v-else cols="12">
						<video
							v-if="introResource"
							class="d-block ml-10 mb-6"
							:src="introResource.video"
							:poster="introResource.poster"
							controls
							width="50%"
							style="float: right"
						/>
						<p class="text-body-1 font-weight-bold mb-6">
							Increase efficiency by visually managing and tracking the latest status of current and any previously requested or recommended KFI's.
						</p>
						<ul class="text-body-1 pl-8 mb-6">
							<li class="mb-2">
								Directly navigate to your sourcing results and request your Key Facts Illustration.
							</li>
							<li class="mb-2">
								Efficiently track the status of your requested KFI from “Submitted,” through to “Completed” or “Failed”.
							</li>
							<li class="mb-0">
								Download a copy of the research results or KFI, as well as the ability to navigate directly to the lender's portal for application.
							</li>
						</ul>
						<p class="text-body-1 mb-8">
							Click on the intro video for an explanation on how you can navigate AirFlow's Key Facts Illustration tile and maximise its benefits and features.
						</p>
						<v-btn color="primary" @click="createSourcingSession()">
							Get started
						</v-btn>
						<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
					</v-col>
				</v-row>
			</v-sheet>

			<div v-else class="pl-4">
				<div v-if="pendingKfis.length || failedKfis.length" class="ma-n1 pb-6">
					<p class="text-body-1 ma-1 mb-n1 font-weight-medium">
						Recommendations
					</p>
					<v-chip-group v-model="recommendation">
						<v-chip v-if="pendingKfis.length" filter color="warning darken-1" outlined label class="ma-1" value="pending">
							<v-icon small class="mr-2">
								mdi-alert
							</v-icon>{{ pendingKfis.length }} abandoned KFI requests
						</v-chip>
						<v-chip v-if="failedKfis.length" filter color="error" outlined label class="ma-1" value="failed">
							<v-icon small class="mr-2">
								mdi-alert
							</v-icon>{{ failedKfis.length }} KFI requests failed to process
						</v-chip>
					</v-chip-group>
				</div>
				<v-text-field v-if="items.length > 3" v-model="search" label="Search: e.g. Provider or Product name" outlined dense />
				<div class="overflow-y-auto overflow-x-hidden" :class="{ 'height--max': activeItems.length > 3 }">
					<v-timeline align-top dense class="pa-0">
						<v-timeline-item v-for="(item, index) in activeItems" :key="index" :color="item.completed ? 'grey' : 'grey'" small class="py-6">
							<v-row no-gutters>
								<v-col class="pr-8">
									<p class="text-h6 mt-n1 mb-1 line-clamp" style="-webkit-line-clamp: 1">
										<span class="font-weight-bold">
											{{ item.product.name | truncate(50) }}
										</span>
										<v-chip label x-small dark :color="getStatusColor(getStatus(item.status))" style="position: relative; left: 8px; top: -4px">
											{{ getStatus(item.status) }}
										</v-chip>
									</p>
									<p class="text-body-2 mb-3">
										{{ item.product.provider.name }}
									</p>

									<v-timeline align-top dense class="pt-1">
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon color="success">
													mdi-check-bold
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> Created: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														{{ item.created | moment('MMM Do YYYY, h:mma') }}
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon v-if="item.kfiRequestSubmitted" color="success">
													mdi-check-bold
												</v-icon>
												<v-icon v-else color="error">
													mdi-close-thick
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> KFI Requested: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														<template v-if="item.kfiRequestSubmitted">{{ item.kfiRequestSubmitted | moment('MMM Do YYYY, h:mma') }}</template>
														<template v-else>--</template>
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
										<v-timeline-item
											v-if="item.kfiRequestSubmitted && !item.kfiRecieved && item.kfiRequestFailed"
											color="white"
											small
											fill-dot
											class="pb-2 remove-dot"
										>
											<template #icon>
												<v-icon color="error">
													mdi-alert
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> KFI Request Failed: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														<template v-if="item.kfiRequestFailed">{{ item.kfiRequestFailed | moment('MMM Do YYYY, h:mma') }}</template>
														<template v-else>--</template>
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
										<v-timeline-item v-else color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon v-if="item.kfiRecieved" color="success">
													mdi-check-bold
												</v-icon>
												<v-icon v-else color="error">
													mdi-close-thick
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> KFI Received: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														<template v-if="item.kfiRecieved">{{ item.kfiRecieved | moment('MMM Do YYYY, h:mma') }}</template>
														<template v-else>--</template>
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
									</v-timeline>

									<div class="mt-4 mx-n1">
										<template v-for="(action, index) in getActions(item)">
											<v-tooltip :key="index" bottom content-class="text-center" max-width="300">
												<template #activator="{ on }">
													<div class="d-inline-block" v-on="on">
														<v-btn
															v-show="!action.hide"
															class="mx-1"
															:color="action.colour"
															:dark="'dark' in action ? action.dark : false"
															:disabled="action.disabled"
															:icon="'type' in action && action.type === 'icon'"
															:loading="fileLoading[item.id]"
															:small="'small' in action ? action.small : true"
															@click="handleAction(action, item)"
														>
															<v-icon v-if="action.icon" :color="'type' in action && action.type === 'icon' ? action.colour : 'white'">
																{{ action.icon }}
															</v-icon>
															<template v-else>
																{{ action.text }}
															</template>
														</v-btn>
													</div>
												</template>
												<span>{{ action.tooltip }}</span>
											</v-tooltip>
										</template>
									</div>
								</v-col>
								<v-col class="shrink pr-8 d-none">
									<common-base-image-tooltip
										:src="imageUrl(item) | urlize"
										:tooltip="item.product.provider.name"
										width="auto"
										height="auto"
										:max-width="150"
										:max-height="100"
										center
									/>
								</v-col>
							</v-row>
						</v-timeline-item>
					</v-timeline>
				</div>
			</div>
		</v-sheet>

		<app-client-property-dialog-apply ref="applicationDialog" @submit-application="submitApplication" />
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { EventBus, ElementTools, PopupWindow } from '@/utils';
	import documentMixin from '@/mixins/document';
	import CommonBaseImageTooltip from '@/component/common/base/image-tooltip';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppClientPropertyDialogApply from '../template/dialog/apply';

	export default {
		name: 'app-client-dashboard-workflow-key-facts-illustration-activity',

		components: {
			CommonBaseImageTooltip,
			CommonDialogConfirm,
			AppClientPropertyDialogApply
		},

		mixins: [documentMixin],

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				loading: true,
				total: 0,
				search: null,
				kfis: null,
				fileLoading: {},
				recommendation: []
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('AppWorkflow', ['getStatusColor']),

			items() {
				return this.kfis;
			},

			pendingKfis() {
				return this.items.filter((k) => k.status.toLowerCase().includes('pending'));
			},

			failedKfis() {
				return this.items.filter((k) => k.status.toLowerCase().includes('failed'));
			},

			activeItems() {
				let filtered = this.items;
				if (this.recommendation) {
					if (this.recommendation === 'pending') filtered = this.pendingKfis;
					if (this.recommendation === 'failed') filtered = this.failedKfis;
				}
				if (this.search) {
					filtered = filtered.filter((k) => {
						if (k.product.name.toLowerCase().includes(this.search.toLowerCase())) return true;
						if (k.product.provider.name.toLowerCase().includes(this.search.toLowerCase())) return true;
						return false;
					});
				}
				return filtered;
			}
		},

		created() {
			this.loadKfis();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('LifetimeMortgageKfi', ['fetchKfiAdvisorList', 'fetchFile', 'deleteProduct']),

			onMessage(message) {
				if (message?.type === 'factFind') {
					this.loadKfis();
				} else if (message?.type === 'kfi') {
					const index = this.kfis?.findIndex((i) => i.id === message.id);

					if (message.action?.data?.status) {
						const data = { status: message.action.data.status };
						if (message.action.data.status === 'KFI Request Failed') data.kfiRequestFailed = message.action.created;
						if (message.action.data.status === 'KFI Received') this.loadKfis();
						this.$set(this.kfis, index, { ...this.kfis[index], ...data });
					}
				}
			},

			submitApplication() {
				this.$emit('submit-application');
				this.$emit('workflow-updated');
				this.loadKfis();
			},

			loadKfis() {
				this.fetchKfiAdvisorList({
					entityIds: [this.asset.id],
					cumulative: true
				})
					.then(({ data }) => {
						this.total = data.total;
						this.kfis = data.data;
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
					.finally(() => {
						this.loading = false;
						if (!this.kfis?.length) this.$emit('toggle-intro-resource');
					});
			},

			getStatus(status) {
				if (status == 'KFI Request Submitted') return 'In Progress';
				if (status == 'KFI Request Failed') return 'Failed';
				if (status == 'KFI Received') return 'Completed';
				return 'Pending';
			},

			/**
			 * @name imageUrl
			 * @description Return image URL
			 * @return {String}
			 */
			imageUrl(item) {
				const primary = item?.product?.provider?.logo?.find((l) => l.primary);
				if (primary) return `logo/organisation/${primary.name}`;
				return null;
			},

			getActions(item) {
				let actions = [
					{
						title: 'Help',
						function: `openChat`,
						colour: 'blue-grey lighten-4',
						dark: false,
						small: false,
						text: 'contact support',
						tooltip: 'Help',
						hide: item.status != 'KFI Request Failed' && !item.productApplicationSubmitted
					},
					{
						title: 'Resume',
						path: `/request/${item?.matterId}?processTypeId=${item?.processTypeId}`,
						colour: 'primary',
						small: false,
						text: 'Resume',
						tooltip: 'This KFI request has not yet been submitted. Click here to continue.',
						disabled: !!item.kfiRequestSubmitted,
						hide: !!item.kfiRequestSubmitted
					},
					{
						title: 'Download KFI',
						function: `fetchDocument`,
						functionParams: item?.documents?.find((doc) => doc?.type === 'keyFactsIllustration'),
						small: false,
						text: 'Download KFI',
						colour: 'primary',
						tooltip: 'Download KFI',
						disabled: !item.kfiRecieved,
						hide: !item.kfiRequestSubmitted || item.status == 'KFI Request Failed'
					},
					{
						title: 'Apply',
						function: `proceedToApplication`,
						colour: 'secondary',
						small: false,
						text: 'Apply',
						tooltip: item.kfiRecieved
							? 'Apply for this product'
							: !item.kfiRequestSubmitted
							? 'Please submit the KFI request before attempting to apply for this product'
							: 'KFI not received',
						disabled: !item.kfiRecieved,
						hide: !item.kfiRequestSubmitted || item.productApplicationSubmitted || item.status == 'KFI Request Failed'
					},
					{
						title: 'Download Research PDF',
						function: 'fetchResearchPdf',
						functionParams: item?.documents?.find((doc) => doc?.type === 'researchPdf'),
						icon: 'mdi-file-table',
						colour: 'info',
						type: 'icon',
						tooltip: 'Download Research PDF'
					}
				];

				item?.documents
					?.filter((doc) => doc.type === 'supportingDocument')
					?.forEach((doc) => {
						actions.push({
							title: doc.title,
							function: 'fetchDocument',
							functionParams: doc,
							icon: 'mdi-file-document-plus',
							colour: 'info',
							type: 'icon',
							tooltip: doc.title || 'Supporting Document',
							disabled: !item.kfiRecieved,
							hide: !item.kfiRequestSubmitted
						});
					});

				actions.push({
					title: 'Delete',
					function: 'deleteProductSubmission',
					colour: 'error',
					icon: 'mdi-trash-can',
					type: 'icon',
					tooltip: 'Delete',
					hide: this.getStatus(item.status) !== 'Pending'
				});

				return actions;
			},

			/**
			 * @name handleAction
			 * @description execute correct action
			 * @param action item action
			 * @param item item that needs updating
			 */
			handleAction(action, item) {
				if (action.path) this.$router.push(action.path);
				if (action.function) this[action.function](item, action.functionParams);
			},

			/**
			 * @name proceedToApplication
			 * @description TODO
			 * @param action item action
			 */
			proceedToApplication(item) {
				this.$refs.applicationDialog.open(item);
			},

			apply(item) {
				this.$refs.applicationDialog.open(item);
			},

			/**
			 * @name fetchDocument
			 * @description TODO
			 * @param item
			 * @param data
			 */
			async fetchDocument(item, data) {
				this.$set(this.fileLoading, item.id, true);
				this.fetchFile({ id: data.id }).then(async(response) => {
					if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
					this.$set(this.fileLoading, item.id, false);
				});
			},

			/**
			 * @name fetchResearchPdf
			 * @description TODO
			 * @param item
			 * @param data
			 */
			async fetchResearchPdf(item, data) {
				this.$set(this.fileLoading, item.id, true);
				const doc = {
					processId: data?.type === 'researchPdf' ? item.sourcing?.processId : item.processId,
					iterationId: item.sourcing?.iteration?.id,
					s3Key: data?.s3Key,
					filename: data?.filename
				};
				this.downloadDocument(doc).then(async() => {
					if (!data?.s3Key) await this.loadKfis();
					this.$set(this.fileLoading, item.id, false);
				});
			},

			/**
			 * @name deleteProductSubmission
			 * @description Delete product submission after confirmation
			 * @param {Object} item The kfi submission object
			 */
			deleteProductSubmission(item) {
				let msg = 'Are you sure you wish to delete this product submission?';
				this.$refs.confirm.open('Remove Product Submission', msg).then(async() => {
					this.$set(this.fileLoading, item.id, true);

					const payload = {
						processId: item.processId,
						iterationId: item.id,
						data: {
							action: {
								key: 'DeleteKeyFactsIllustrationProduct'
							}
						}
					};

					this.deleteProduct(payload)
						.then(() => {
							this.loadKfis();
							ElementTools.fireNotification(this.$el, 'success', `Product "${item.product.name} by ${item.product.provider.name}" has been successfully removed`);
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to remove product submission'))
						.finally(() => this.$set(this.fileLoading, item.id, false));
				});
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			},

			/**
			 * @name createSourcingSession
			 * @description Create New Sourcing Session
			 */
			createSourcingSession() {
				this.$router.push(
					`/source?clientId=${this.client?.id}${this.asset?.id ? `&propertyId=${this.asset.id}` : ''}${
						this.association?.id ? `&associationId=${this.association.id}` : ''
					}`
				);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	table {
		th,
		td {
			&.shrunk {
				width: 1px;
				white-space: nowrap;
			}

			&.nowrap {
				white-space: nowrap;
			}
		}
	}

	.v-timeline-item:has(.v-timeline-item):nth-child(even) {
		background: rgba(30, 176, 225, 0.1);
		background: linear-gradient(90deg, rgba(27, 120, 216, 0.05) 0%, rgba(103, 0, 86, 0.05) 100%);
	}

	.height--max {
		max-height: 32rem;
	}
</style>
