import { $http } from '@/utils';

/**
 * @namespace Service
 * @class kfi
 * @exports Service/kfi
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class kfi {
	/**
	 * @static @name get
	 * @description Get a kfi resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`lifetime-mortgage/kfi/${id}`);
	}

	/**
	 * @static @name post
	 * @description Add a new kfi resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('lifetime-mortgage/kfi', payload);
	}

	/**
	 * @static @name list
	 * @description Get all kfis
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('lifetime-mortgage/kfi/list', payload);
	}

	/**
	 * @static @name submit
	 * @description Submit a kfi
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static submit(payload) {
		return $http.post('lifetime-mortgage/kfi/submit', payload);
	}

	/**
	 * @static @name delete
	 * @description delete a product from a kfi
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static delete(payload) {
		return $http.post('lifetime-mortgage/kfi/delete', payload);
	}

	/**
	 * @static @name advisorList
	 * @description Get all advisor kfi
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static advisorList(payload) {
		return $http.post('lifetime-mortgage/kfi/advisor/list', payload);
	}

	/**
	 * @name retry
	 * @description send a failed KFI back to the queue
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	static retry(id) {
		return $http.post(`lifetime-mortgage/kfi/${id}/retry`, {});
	} 

	/**
	 * @static @name advisorList
	 * @description Get all advisor kfi
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static getFile({ id, payload }) {
		return $http.get(`lifetime-mortgage/kfi/${id}/file`, payload);
	}
}
