import { Process } from '@/service';

/**
 * @class @name MatterHelper
 * @description Create a display ready array of activity items
 */

export class MatterHelper {
	/**
	 * @name groupKfiProducts
	 * @param {Object} matter
	 */
	async groupKfiProducts(kfiSubmissions) {
		let latestProcess = undefined;

		const kfiProcessIds = kfiSubmissions.filter((ks) => (ks.sourcing?.processId && !ks.kfiRequestSubmitted)).map((ks) => ks.sourcing?.processId);

		const sourcingProcesses = await Process.list({ where: { "process_type.name_unique": "sourcing", "process.id": { condition: 'IN', value: kfiProcessIds, type: 'uuid' } } });

		kfiSubmissions = kfiSubmissions.map((ks) => {
			let returnKs = ks;
			let valid = false;
			const sourcingProcess = (sourcingProcesses?.data?.data || []).find((sp) => sp.id === ks.sourcing?.processId);

			if( sourcingProcess ) {
				// Remove updated value from asset and clients to ensure diff is not affected by updated field
				const asset = sourcingProcess?.data?.asset;
				if(asset?.updated) delete asset.updated;
				const clients = sourcingProcess?.data?.clients;
				let i = 0;
				for(const client of clients || []) {
					if(client.updated) delete clients[i].updated;
					i++;
				}

				const currentProcess = { asset, clients };

				// Set the first sorted process as the latest process
				if(!latestProcess) latestProcess = currentProcess;

				// The current process must contain the same data as the latest process for an unsubmitted KFI to be eligible for submission
				if(JSON.stringify(currentProcess) === JSON.stringify(latestProcess)) valid = true;

				returnKs.valid = valid;
			}

			return returnKs;
		});

		return kfiSubmissions;
	}
}
