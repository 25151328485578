/**
 * @namespace Mixins
 * @exports Mixins/downloadDocument
 * @description Document common methods
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

import { mapActions } from 'vuex';
import { ElementTools, EventBus, PopupWindow } from '@/utils';

export default {
	methods: {
		...mapActions('LifetimeMortgageSourcing', ['saveToPdf', 'fetchSourcingFile']),

		/**
		 * @name createSaveToPdfAction
		 * @description TODO
		 */
		createSaveToPdfAction(processId, iterationId, data) {
			const payload = {
				processId: processId,
				iterationId: iterationId,
				data: {
					action: {
						key: 'SaveToPdf'
					},
					...data
				}
			};

			return this.saveToPdf(payload).then(({ data: action }) => action.data.s3Key);
		},

		/**
		 * @name downloadDocument
		 * @description Download document using id
		 * @param {Object} processId
		 * @param {Object} iteration
		 * @param {Object} s3Key
		 * @param {Object} filename
		 */
		async downloadDocument({ processId, iterationId, s3Key, data }) {
			ElementTools.fireNotification(this.$el, 'info', 'Your document is being prepared', { timeout: 0 });

			try {
				if (!s3Key) s3Key = await this.createSaveToPdfAction(processId, iterationId, data);
				const {
					data: { preSignedUrl }
				} = await this.fetchSourcingFile({ id: iterationId });
				if (preSignedUrl) new PopupWindow().open(preSignedUrl);
				ElementTools.fireNotification(this.$el, 'info', 'Your document has been downloaded');
			} catch (e) {
				if (e?.data?.message === 'Imported iteration') {
					ElementTools.hideNotification(this.$el);
					EventBus.$emit('imported-iteration', iterationId);
				} else {
					ElementTools.fireNotification(this.$el, 'error', e?.data?.message ?? 'Failed to download document');
				}
			}
		}
	}
};
