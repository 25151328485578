import { $http } from '@/utils';

/**
 * @namespace Service
 * @class StateBenefit
 * @exports Service/StateBenefit
 * @description API StateBenefit Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class StateBenefit {
	/**
	 * @static @name list
	 * @description Get a list of state benefits
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('state-benefit/list', payload);
	}

	/**
	 * @static @name post
	 * @description Add a new state benefit resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('state-benefit', payload);
	}

	/**
	 * @static @name get
	 * @description Add a new state benefit resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static getFile({ payload, id }) {
		return $http.get(`state-benefit/${id}/file`, payload);
	}
}
