<!--
	@name common-form-lender-credentials
	@description Lender credentials section
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="d-flex">
		<common-structure-section class="flex-grow-1 mb-6 elevation-2">
			<template #header>
				Lender Account Credentials
			</template>

			<template #body>
				<p class="text-body-2 mb-3">
					<span> Although not required to submit your KFI request, </span>
					<span class="font-weight-medium">we will be unable to act upon requests for any products requiring your lender credentials until we are able to obtain them</span>.
				</p>
				<v-alert dense text border="left" :type="isValid ? 'success' : 'warning'" class="ma-0">
					<v-row align="center" class="px-3">
						<v-col v-if="isValid" class="grow pa-0 text-subtitle-2">
							Our records indicate that your credentials are up to date.
						</v-col>
						<v-col v-else class="grow pa-0 text-subtitle-2">
							Please provide your credentials.
						</v-col>
						<v-col class="shrink pa-0">
							<v-btn small dark @click="openCredentialsDialog">
								Update Credentials
							</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</template>
		</common-structure-section>

		<common-dialog ref="credentialsDialog" :component="$options.name" eager>
			<template #body>
				<app-account-settings-credentials slim :lenders="lenders" @accounts-updated="credentialsUpdated" />
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import AppAccountSettingsCredentials from '@/component/app/account-settings/credentials';
	import CommonDialog from '@/component/common/dialog';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'common-form-lender-credentials',

		components: {
			CommonStructureSection,
			CommonDialog,
			AppAccountSettingsCredentials
		},

		props: {
			lenders: { type: Array, required: true, default: () => [] }
		},

		data() {
			return {
				isValid: false
			};
		},

		methods: {
			/**
			 * @name openCredentialsDialog
			 * @description Opens the credentials dialog
			 */
			openCredentialsDialog() {
				this.$refs.credentialsDialog.open();
			},

			/**
			 * @name credentialsUpdated
			 * @description Updates the validity of the credentials
			 * @param {Array} accounts
			 */
			credentialsUpdated(accounts) {
				this.isValid = accounts?.every((account) => !!account.credential);
			}
		}
	};
</script>
