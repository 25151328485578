<!--
	@name app-calculator-flood-risk-report
	@description Flood risk report
	@date 2020/05/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-dialog v-model="floodRiskReportActive" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="info" class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Flood Risk Report
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="close()">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 pt-10 ma-0 mx-auto" style="max-width: 1400px">
					<common-structure-section no-top-radius :body-class="'flex-grow-1'">
						<template #body>
							<p class="text-body-1 mb-0" style="max-width: 800px">
								View real-time flood warnings, locate geographical flood areas and generate flood reports. This tool uses Environment Agency flood and river level
								data from the real-time data API (Beta).
							</p>
							<validation-observer ref="observer" v-slot="{ invalid }">
								<form class="mt-6" @submit.prevent="submit">
									<v-row no-gutters>
										<v-col cols="12" md="6" class="pr-3">
											<validation-provider v-slot="{ errors }" ref="validation-provider-postcode" name="Postcode" rules="required|postcode">
												<v-text-field v-model="postcode" placeholder="Postcode" label="Postcode" :error-messages="errors" outlined @blur="onBlur" />
											</validation-provider>
										</v-col>
										<v-col cols="12" md="6" class="pl-3">
											<validation-provider v-slot="{ errors }" name="Radius" rules="required|numeric|min_value:1|max_value:300">
												<v-text-field v-model="radius" :error-messages="errors" label="Radius (km)" suffix="km" outlined />
											</validation-provider>
										</v-col>
									</v-row>

									<v-btn class="mr-4" type="submit" color="primary" :disabled="invalid">
										Search
									</v-btn>
								</form>
							</validation-observer>

							<v-sheet v-if="loading" class="mt-6 text-center">
								<p class="text-body-1 mb-3">
									Contacting Environment Agency...
								</p>
								<v-progress-linear indeterminate />
							</v-sheet>

							<v-alert v-else-if="fetchFloodReportError" type="error" border="left" colored-border elevation="4" class="mt-8 mb-0">
								An unexpected error occurred whilst attempting to contact the Environment Agency flood map service.
								<span class="font-weight-bold">Please check that the postcode is correct, as an invalid postcode will result in a failed response.</span> If this
								problem continues, please contact our support team.
							</v-alert>

							<v-sheet v-else-if="warnings" class="mt-8">
								<v-divider />
								<h2 class="text-h4 font-weight-medium mt-10 mb-3">
									<v-icon large color="grey darken-4" class="mr-2">
										mdi-weather-pouring
									</v-icon>Flood report
								</h2>

								<common-base-flood-report :postcode="postcode" :button-size="'default'" :new-chip="false" />

								<h2 class="text-h4 font-weight-medium mt-12 mb-2">
									<v-icon large color="grey darken-4" class="mr-2">
										mdi-alert-octagram-outline
									</v-icon>Flood warnings
								</h2>
								<p class="text-body-2 mb-0">
									The Environment Agency issue warnings of floods that cover specific warning or alert areas. Below is a listing of all current such warnings.
									This list is updated every 15 minutes. A warning may be at one of four possible severity levels:
								</p>
								<div class="ma-n1 pt-3">
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip label small dark class="red ma-1" v-on="on">
												1: Severe Flood Warning
											</v-chip>
										</template>
										<span>Severe Flooding, Danger to Life</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip label small dark class="warning ma-1" v-on="on">
												2: Flood Warning
											</v-chip>
										</template>
										<span> Flooding is Expected, Immediate Action Required</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip label small class="warning ma-1" v-on="on">
												3: Flood Alert
											</v-chip>
										</template>
										<span>Flooding is Possible, Be Prepared</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip label small dark class="green ma-1" v-on="on">
												4: Warning no Longer in Force
											</v-chip>
										</template>
										<span>Warning no Longer in Force</span>
									</v-tooltip>
								</div>

								<v-alert v-if="warnings.length === 0" border="left" colored-border color="info" elevation="4" class="mt-5 pl-6 font-weight-medium">
									There are currently 0 flood warnings within your search area
								</v-alert>
								<v-sheet v-else>
									<v-alert
										v-for="(warning, index) in warnings"
										:key="index"
										border="left"
										colored-border
										:color="severityLevel(warning.severityLevel)"
										elevation="4"
										class="mt-5 pa-0 pl-3"
									>
										<v-card flat>
											<v-row no-gutters>
												<v-col class="grow">
													<v-card-title class="pb-0 grey--text text--darken-3">
														{{ warning.eaAreaName }}
													</v-card-title>
													<v-card-title class="text-body-2 pt-0 pb-0 grey--text text--darken-1">
														{{ warning.description }}
													</v-card-title>
												</v-col>
												<v-col class="shrink">
													<div class="ma-n1 pa-4 nowrap">
														<v-chip label small :class="severityLevel(warning.severityLevel)" class="ma-1">
															Severity level: {{ warning.severityLevel }}
														</v-chip>
														<v-chip label small color="blue-grey lighten-4" class="ma-1">
															{{ warning.timeMessageChanged | moment('MMM Do YYYY, h:mma') }}
														</v-chip>
													</div>
												</v-col>
											</v-row>
											<v-card-text>
												<p class="text-body-2 mb-0 grey--text text--darken-2" @click="toggleExpandItem(warning.message, index)">
													{{ displayMessage(warning.message, index) }}
												</p>
											</v-card-text>
										</v-card>
									</v-alert>
								</v-sheet>

								<h2 class="text-h4 font-weight-medium mt-12 mb-2">
									<v-icon large color="grey darken-4" class="mr-2">
										mdi-map-search-outline
									</v-icon>Flood areas
								</h2>

								<p class="text-body-2 mb-5">
									A Flood Alert Area is a geographical area where it is possible for flooding to occur from rivers, sea and in some locations, groundwater. A
									single Flood Alert Area may cover a large portion of the floodplain, may contain multiple river catchments of similar characteristics and may
									contain a number of Flood Warning Areas. A Flood Warning Area is a geographical area where Environment Agency expect flooding to occur and which
									they provide a Flood Warning Service.
									<span class="font-weight-medium">N.B. distance is measured to the centre of the flood area</span>
								</p>

								<v-text-field
									v-model="search"
									label="Search: e.g. River Thames"
									hide-details=""
									clearable
									dense
									outlined
									prepend-inner-icon="mdi-magnify"
									class="mb-3"
									style="max-width: 500px"
								/>
								<v-data-table :headers="headers" :items="_areas" :items-per-page="5" class="elevation-3 table--styled">
									<template #body="{ items }">
										<tbody>
											<tr v-for="item in items" :key="item.name">
												<td style="max-width: 100px">
													<h3>
														{{ item.label }}
													</h3>
													<p class="mb-0">
														{{ item.description }}
													</p>
												</td>

												<td style="width: 1px; white-space: nowrap">
													{{ item.distance | numFormat('0,00.00') }} km
												</td>
												<td style="width: 1px; white-space: nowrap">
													<v-btn small color="primary" @click="openMapDialog(item)">
														View map
													</v-btn>
												</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</v-sheet>
						</template>
					</common-structure-section>

					<common-dialog ref="dialog" :max-width="1000" :max-height="800">
						<template #header>
							Map
						</template>
						<template #body>
							<div id="map" />
						</template>
					</common-dialog>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { orderBy, map } from 'lodash';
	import { googleMap } from '@/utils';
	import CommonBaseFloodReport from '@/component/common/base/flood-report';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-calculator-flood-risk-report',

		components: {
			CommonBaseFloodReport,
			CommonStructureSection,
			CommonDialog,
			ValidationProvider,
			ValidationObserver
		},

		data() {
			return {
				floodRiskReportActive: false,
				fetchFloodReportError: false,
				loading: false,
				postcode: '',
				radius: 10,
				longitude: null,
				latitude: null,
				areas: null,
				warnings: null,
				expandedItems: [],
				headers: [
					{ text: 'Name', value: 'label' },
					{ text: 'Distance', value: 'distance', sortable: false },
					{ text: '', value: '', sortable: false }
				],
				search: null
			};
		},

		computed: {
			...mapState('Account', ['user']),

			_areas() {
				if (this.areas === null) return null;
				map(this.areas, (obj) => {
					obj.distance = this.distance(this.latitude, this.longitude, obj.lat, obj.long, 'K');
					return obj;
				});

				let areas = this.areas;
				if (this.search) {
					areas = this.areas.filter((obj) => {
						return obj.label.toLowerCase().includes(this.search.toLowerCase()) || obj.description.toLowerCase().includes(this.search.toLowerCase());
					});
				}

				return orderBy(areas, ['distance'], ['asc']);
			}
		},

		methods: {
			...mapActions('AppCalculator', ['getFloodReport']),

			open() {
				document.documentElement.classList.add('overflow-y-hidden');
				this.floodRiskReportActive = true;
			},

			close() {
				this.floodRiskReportActive = false;
				this.$emit('closed');
			},

			openMapDialog(item) {
				this.$refs.dialog.open();

				let zoom = 12 - Math.ceil(item.distance / 5);
				if (zoom < 8) zoom = 8;

				this.drawMap(
					'map',
					{ lat: item.lat, lng: item.long },
					{
						zoom: zoom,
						polygon: item.polygon ? item.polygon.replace('http:', 'https:') : false,
						markers: [
							{
								position: { lat: this.latitude, lng: this.longitude }
							}
							/*,{
								position: { lat: item.lat, lng: item.long }
							}*/
						]
					}
				);
			},

			/**
			 * Draw Google map
			 * @param id
			 * @param latLng
			 * @param options
			 */
			drawMap(id, latLng, options) {
				const gm = new googleMap();

				gm.then(() => {
					let target = document.getElementById(id);
					if (target == null) throw new Error('Error');

					let map = new window.google.maps.Map(target, {
						center: latLng,
						zoom: options.zoom
					});

					if (Object.prototype.hasOwnProperty.call(options, 'markers')) {
						options.markers.forEach(function(marker) {
							if (Object.prototype.hasOwnProperty.call(marker, 'icon') && marker.icon == 'svg') {
								const svgMarker = Object.assign(marker.iconOptions, {
									anchor: new window.google.maps.Point(15, 30)
								});
								marker.icon = svgMarker;
							}
							new window.google.maps.Marker(
								Object.assign(marker, {
									map: map,
									animation: window.google.maps.Animation.DROP
								})
							);
						});
					}

					if (Object.prototype.hasOwnProperty.call(options, 'polygon') && options.polygon) {
						map.data.loadGeoJson(options.polygon);
						map.data.setStyle({
							fillColor: 'red',
							fillOpacity: 0.8,
							strokeWeight: 2
						});
					}
				});
			},

			severityLevel(level) {
				if (level == 1) return 'red white--text';
				if (level == 2) return 'warning white--text';
				if (level == 3) return 'orange';
				return 'green white--text';
			},

			displayMessage(message, index) {
				if (message.trim() == '') return 'No futher information.';
				if (this.expandedItems.includes(index)) return message;
				return message.substring(0, 250) + '...';
			},

			toggleExpandItem(message, index) {
				if (message.length < 250) return false;
				if (this.expandedItems.includes(index)) this.expandedItems = this.expandedItems.filter((item) => item !== index);
				else this.expandedItems.push(index);
			},

			submit() {
				this.$refs.observer.validate().then(async(valid) => {
					if (!valid) return false;

					this.fetchFloodReportError = false;
					this.loading = true;
					this.longitude = null;
					this.latitude = null;
					this.search = null;
					this.areas = null;
					this.warnings = null;
					this.expandedItems = [];

					let resp = await this.getFloodReport({ postcode: this.postcode, radius: this.radius });
					this.loading = false;
					if (resp.status != 0) this.fetchFloodReportError = true;
					if (resp.warnings?.items) this.warnings = resp.warnings.items;
					if (resp.areas?.items) this.areas = resp.areas.items;
					if (resp.longitude) this.longitude = resp.longitude;
					if (resp.latitude) this.latitude = resp.latitude;

					window.dataLayer.push({
						event: 'floodRiskReportSubmitted',
						floodRiskReportUserId: this.user.id
					});
				});
			},

			distance(lat1, lon1, lat2, lon2, unit) {
				if (lat1 == lat2 && lon1 == lon2) {
					return 0;
				} else {
					var radlat1 = (Math.PI * lat1) / 180;
					var radlat2 = (Math.PI * lat2) / 180;
					var theta = lon1 - lon2;
					var radtheta = (Math.PI * theta) / 180;
					var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
					if (dist > 1) {
						dist = 1;
					}
					dist = Math.acos(dist);
					dist = (dist * 180) / Math.PI;
					dist = dist * 60 * 1.1515;
					if (unit == 'K') {
						dist = dist * 1.609344;
					}
					if (unit == 'N') {
						dist = dist * 0.8684;
					}
					return dist;
				}
			},

			onBlur() {
				this.$refs[`validation-provider-postcode`].validate().then(({ valid }) => {
					if (valid) {
						const postcode = this.postcode.replace(/\s+/g, '').toUpperCase();
						const formattedValue = `${postcode.slice(0, -3)} ${postcode.slice(-3)}`;
						this.postcode = formattedValue;
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	#map {
		width: 100%;
		height: 500px;
	}
</style>
