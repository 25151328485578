<!--
	@name app-kfi-request
	@description Show KFI Request Page
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3 h-100">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						KFI Request
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip v-if="displaySummary && clientId" bottom>
						<template #activator="{ on }">
							<v-btn text large color="white" @click="openOnboarding" v-on="on">
								<v-icon large>
									mdi-frequently-asked-questions
								</v-icon>
							</v-btn>
						</template>
						<span>What's all this?</span>
					</v-tooltip>
					<v-tooltip v-if="resultsUrl" bottom>
						<template #activator="{ on }">
							<v-btn text large color="white" :to="resultsUrl" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Product Results</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row v-if="displaySummary && clientId">
			<kfi-request-summary
				ref="requestSummary"
				:client-id="clientId"
				:products="products"
				@refresh-products="setProducts"
				@remove-product="removeProduct"
			/>
		</v-row>
		<div v-else-if="products">
			<kfi-request-submission
				v-if="products"
				ref="requestSubmission"
				:products="products"
				:submitted-products="submittedProducts"
				:display-summary="displaySummary"
				@refresh-products="setProducts"
				@remove-product="removeProduct"
			/>
		</div>
		<v-skeleton-loader v-else type="list-item-three-line" />
		<common-dialog ref="invalidProductsDialog" :show-close="false" :color="'error'">
			<template #header>
				Data mismatch
			</template>

			<template #body>
				<v-card elevation="0">
					<p class="text-body-2 mb-0">
						The following outstanding KFI requests cannot proceed as the information used to source the products is out of date.
						<strong>These products will be removed from the request when you proceed to the form.</strong>
					</p>

					<v-list class="pt-4 pb-0">
						<v-list-item v-for="product in invalidProducts" :key="product.id" class="amber lighten-5 my-1">
							<v-list-item-content>
								<v-list-item-title class="mb-0 font-weight-medium text-body-2">
									{{ product.product.provider.name }} - <span class="caption">{{ product.product.name }}</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-card-actions class="pa-0 pt-5">
						<v-spacer />
						<v-btn color="primary" style="margin:0 auto" @click="removeInvalidProducts">
							Agree and proceed
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import { orderBy } from 'lodash';
	import KfiRequestSummary from './kfi-request-summary.vue';
	import KfiRequestSubmission from './kfi-request-submission.vue';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { MatterHelper } from '@/store/helpers/MatterHelper';

	export default {
		name: 'app-kfi-request',

		components: {
			KfiRequestSummary,
			KfiRequestSubmission,
			CommonDialog,
			CommonDialogConfirm
		},

		beforeRouteLeave(to, from, next) {
			if (this.outstandingExternalKfiRequests) {
				let msg = `<p class="mb-0 text-subtitle-1"><span class="font-weight-bold error--text">${this.outstandingExternalKfiRequests} item`;
				if (this.outstandingExternalKfiRequests !== 1) msg += 's';
				msg += ` require`;
				if (this.outstandingExternalKfiRequests === 1 ) msg += 's';
				msg += ` your attention.</span> ${this.outstandingExternalKfiRequests === 1 ? 'This' : 'These'} KFI request`;
				if (this.outstandingExternalKfiRequests !== 1) msg += 's';
				msg += ` <strong>have not yet been submitted.</strong> Are you sure you wish to leave the page and abandon`;
				msg += ` ${this.outstandingExternalKfiRequests === 1 ? 'this' : 'these'} KFI request`;
				if (this.outstandingExternalKfiRequests !== 1) msg += 's';
				msg += `?</p>`;

				this.$refs.confirm.open('Before you go...', msg, { width:750, html: true }).then(async() => {
					next();
				});
			} else next();
		},

		data() {
			return {
				setAdviceFeeAsDefault: false,
				selectPanel: [],
				iterationId: null,
				processId: null,
				products: null,
				company: {},
				property: {},
				anotherProperty: {},
				borrowingRequested: {},
				adviceFee: {},
				notes: {},
				firstTimeBuyer: null,
				clientId: null,
				sourcingIterationId: null,
				sourcingProcessId: null,
				invalidProducts: []
			};
		},

		computed: {
			...mapState('Account', ['organisation', 'department']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsForm', ['formFromGroup', 'form']),

			/**
			 * @name matterId
			 */
			matterId() {
				return this.$route.params.matterId;
			},

			/**
			 * @name processTypeId
			 */
			processTypeId() {
				return this.$route.query.processTypeId;
			},

			/**
			 * @name outstandingProducts
			 * @description used to get kfi submissions to be submitted
			 */
			outstandingProducts() {
				return this.products?.filter((product) => !product.kfiRequestSubmitted && product.valid) || [];
			},

			/**
			 * @name automatedProducts
			 * @description used to get kfi submissions to be submitted automatically
			 */
			outstandingAutomatedProducts() {
				return this.outstandingProducts?.filter((product) => !product.product?.keyFactsIllustration?.online || !product.product?.keyFactsIllustration?.url) || [];
			},

			/**
			 * @name outstandingExternalKfiRequests
			 * @description used to get kfi submissions to be submitted manually
			 */
			 outstandingExternalKfiRequests() {
				let arr = this.products?.filter((product) => !product.kfiRequestSubmitted && product.product?.keyFactsIllustration?.online && product.product?.keyFactsIllustration?.url) || [];
				return arr.length;
			},

			/**
			 * @name submittedProducts
			 * @description submitted products
			 */
			submittedProducts() {
				let products = this.products?.filter((product) => product.kfiRequestSubmitted) || [];
				return orderBy(products, 'created', ['desc']);
			},

			/**
			 * @name displaySummary
			 * @description display summary if no outstanding automated products
			 * @return {Boolean} Value for display condition
			 */
			displaySummary() {
				return this.products && !this.outstandingAutomatedProducts.length;
			},

			/**
			 * @name showCompany
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showCompany() {
				return this.department.name && this.department.name.includes('owner') && !this.organisation.data?.membership?.fca;
			},

			/**
			 * @name resultsUrl
			 * @description construct results url
			 * @return {String} results url
			 */
			resultsUrl() {
				return `/results/${this.sourcingProcessId}?iteration=${this.sourcingIterationId}`;
			}
		},

		created() {
			this.init();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('CmsForm', ['loadForm', 'loadForm']),
			...mapActions('CmsSite', ['getMembershipSite']),
			...mapActions('AppClientAsset', ['loadClientAsset']),
			...mapActions('AppClient', ['loadClient', 'getUserConnect']),
			...mapActions('LifetimeMortgageKfi', ['fetchKfiAdvisorList', 'deleteProduct']),


			/**
			 * @name onMessage
			 * @description Handle websocket messages
			 * @param {Object} message
			 */
			onMessage(message) {
				if (message?.type !== 'kfi') return;
				const index = this.products.findIndex((i) => i.id === message.id);
				this.$set(this.products, index, { ...this.products[index], status: message.workflowItemStatus.status });
			},

			init() {
				Promise.all([this.fetchKfiAdvisorList({ where: { 'matter.id': this.matterId }, order: { property: 'created', direction: 'desc' }}), this.loadForm('kfi')]).then(async(response) => {
					const matterHelper = new MatterHelper();
					this.products = await matterHelper.groupKfiProducts(response[0].data.data);
					this.checkInvalidProducts();

					this.processId = this.products[0].processId;
					this.iterationId = this.products[0].id;
					this.sourcingProcessId = this.products[0].sourcing.processId;
					this.sourcingIterationId = this.products[0].sourcing.iteration.id;

					// Get our master client ID
					this.clientId = this.products[0].clients[0]?.id;
					// 2 clients, find the master
					if (this.products[0].client?.length > 1) this.getUserConnect({ clientIds: this.products[0].clients }).then(({ data }) => (this.clientId = data.mainClient));

					// wait for the next tick to ensure the products are set
					await this.$nextTick();

					if (this.outstandingAutomatedProducts.length) {
						this.setMatterEntities(this.products[0].clients, this.products[0].asset);
						if (this.submittedProducts[0]) this.$refs.requestSubmission.setProcessData(this.submittedProducts[0].processData);
					}

					if (this.showCompany)
						this.setCompany({
							organisationName: this.organisation?.name,
							organisationFcaNumber: this.organisation?.data?.membership?.fca
						});
				});

				this.loadForm('interface');
				this.setSelectPanel();
			},

			setProducts() {
				return this.fetchKfiAdvisorList({ where: { 'matter.id': this.matterId }, order: { property: 'created', direction: 'desc' } })
					.then(async(response) => {
						const matterHelper = new MatterHelper();
						this.products = await matterHelper.groupKfiProducts(response.data.data);
						this.checkInvalidProducts();
					});
			},

			checkInvalidProducts() {
				this.invalidProducts = (this.products || []).filter((product) => !product.kfiRequestSubmitted && !product.valid);
				console.log(this.invalidProducts);
				if (this.invalidProducts.length) {
					this.$refs.invalidProductsDialog.open();
				}
			},

			removeInvalidProducts() {
				let deletions = [];
				for(const invalidProduct of this.invalidProducts) {
					const payload = {
						processId: invalidProduct.processId,
						iterationId: invalidProduct.id,
						data: {
							action: {
								key: 'DeleteKeyFactsIllustrationProduct'
							}
						}
					};

					deletions.push(this.deleteProduct(payload));
				}

				Promise.all(deletions)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', `Products have been successfully removed from outstanding list`);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to remove products'));
				this.$refs.invalidProductsDialog.close();
			},

			setSelectPanel() {
				this.getMembershipSite('select').then(({ data }) => {
					let panel = data?.matter?.lifetimeMortgage?.panel?.sourcing;
					if (panel) this.selectPanel = data?.matter?.lifetimeMortgage?.panel?.sourcing;
				});
			},

			setMatterEntities(clients, asset) {
				clients.map((item, index) => this.$refs.requestSubmission.fetchClient(index, item.id, false));
				this.$refs.requestSubmission.fetchAsset(clients[0].id, asset.id);
			},

			removeProduct(product) {
				this.products = this.products.filter((item) => item.id !== product.id);
			},

			openOnboarding() {
				this.$refs.requestSummary.openOnBoarding();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-alert {
		font-size: 14px;
		line-height: 18px;
	}

	::v-deep .v-card.v-sheet {
		margin-bottom: 32px;
	}
</style>
