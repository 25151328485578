// import AppHome from '@/component/app/home';
import AppProductUpdates from '@/component/app/product-updates';
import AppRegister from '@/component/app/register';
import AppVerifyEmailAddress from '@/component/app/register/verify-email-address';
import AppInvalidDetails from '@/component/app/register/invalid-details';
import AppLogin from '@/component/app/login';
import AppLoginOld from '@/component/app/login-old';
import AppPasswordChange from '@/component/app/password-change';
import AppPasswordRecovery from '@/component/app/password-recovery';
import AppCookiePolicy from '@/component/app/cookie-policy';
import AppMaintenance from '@/component/app/maintenance';
import AppEvents from '@/component/app/events';
import AppEvent from '@/component/app/events/event';

import AppHome from '@/component/app/air-later-life';
import AppNews from '@/component/app/air-later-life/news/list';
import AppNewsDetail from '@/component/app/air-later-life/news/detail';
import AppInsightsHub from '@/component/app/air-later-life/insights/list';
import AppInsightsHubDetail from '@/component/app/air-later-life/insights/detail';
import AppContact from '@/component/app/air-later-life/contact';
import AppAwards from '@/component/app/air-later-life/awards/index';
import AppAwards2023 from '@/component/app/air-later-life/awards/awards-2023';
import AppAwards2022 from '@/component/app/air-later-life/awards/awards-2022';
import AppConference from '@/component/app/air-later-life/conference';
import AppAmbassadors from '@/component/app/air-later-life/ambassadors';
import AppMarketing from '@/component/app/air-later-life/marketing';
import AppSourcing from '@/component/app/air-later-life/sourcing';
import AppAcademy from '@/component/app/air-later-life/academy';
import AppClub from '@/component/app/air-later-life/club';
import AppComprehensiveConversations from '@/component/app/air-later-life/comprehensive-conversations';
import AppComprehensiveConversationsManifesto from '@/component/app/air-later-life/comprehensive-conversations/manifesto';
import AppComprehensiveConversationsReport from '@/component/app/air-later-life/comprehensive-conversations/report';
import AppAirMail from '@/component/app/air-later-life/air-mail';
import AppAirMailEdition from '@/component/app/air-later-life/air-mail/edition';
import AppCampaign from '@/component/app/air-later-life/summer';
import AppNavigator from '@/component/app/air-later-life/navigator';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21air-selec
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{ path: '/', name: 'home', meta: { public: true, layout: 'air-later-life', requiresSiteCheck: true }, component: AppHome },
	{ path: '/product-updates', name: 'productUpdates', meta: { public: true, layout: 'air-later-life', requiresSiteCheck: true }, component: AppProductUpdates },
	{ path: '/register', name: 'register', meta: { public: true, layout: 'default-minimal', requiresSiteCheck: true }, component: AppRegister },
	{ path: '/complete-registration/:token', name: 'completeRegistration', meta: { public: true, layout: 'default-minimal' }, component: AppRegister, props: true },
	{ path: '/club-register', name: 'clubRegister', meta: { public: true, layout: 'default-minimal' }, component: AppRegister },
	{ path: '/academy-register', name: 'academyRegister', meta: { public: true, layout: 'default-minimal' }, component: AppRegister },
	{ path: '/advisers', name: 'adviserLogIn', meta: { public: true, layout: 'default-minimal', requiresSiteCheck: true }, component: AppLogin },
	{ path: '/old-login', name: 'oldLogin', meta: { public: true, layout: 'default-minimal', requiresSiteCheck: true }, component: AppLoginOld },
	{ path: '/password-recovery', name: 'passwordRecovery', meta: { public: true, layout: 'default-minimal' }, component: AppPasswordRecovery },
	{ path: '/password-change/:token', name: 'passwordChange', meta: { public: true, layout: 'default-minimal' }, component: AppPasswordChange },
	{ path: '/verify-email-address/:token', name: 'verifyEmailAddress', meta: { public: true, layout: 'default-minimal' }, component: AppVerifyEmailAddress },
	{ path: '/pending-verification', name: 'pendingVerification', meta: { public: true, layout: 'default-minimal' }, component: AppVerifyEmailAddress },
	{ path: '/invalid-details', name: 'invalidDetails', meta: { public: true, layout: 'default-minimal' }, component: AppInvalidDetails },
	{ path: '/cookie-policy', name: 'cookiePolicy', meta: { public: true, layout: 'blank' }, component: AppCookiePolicy },
	{ path: '/maintenance', name: 'maintenance', meta: { public: true, layout: 'blank' }, component: AppMaintenance },
	{ path: '/events', name: 'events', meta: { public: true, layout: 'air-later-life' }, component: AppEvents },
	{ path: '/event/:slug/:date/:id', name: 'event', meta: { public: true, layout: 'air-later-life' }, component: AppEvent },

	{ path: '/news', name: 'news', meta: { public: true, layout: 'air-later-life' }, component: AppNews },
	{ path: '/news/:id', name: 'news', meta: { public: true, layout: 'air-later-life' }, component: AppNewsDetail },
	{ path: '/insights-hub', name: 'insights-hub', meta: { public: true, layout: 'air-later-life' }, component: AppInsightsHub },
	{ path: '/insights-hub/:id', name: 'insights-hub', meta: { public: true, layout: 'air-later-life' }, component: AppInsightsHubDetail },
	{ path: '/contact', name: 'contact', meta: { public: true, layout: 'air-later-life' }, component: AppContact },
	{ path: '/awards', name: 'awards', meta: { public: true, layout: 'air-later-life' }, component: AppAwards },
	{ path: '/awards-2023', name: 'awards2023', meta: { public: true, layout: 'air-later-life' }, component: AppAwards2023 },
	{ path: '/awards-2022', name: 'awards2022', meta: { public: true, layout: 'air-later-life' }, component: AppAwards2022 },
	{ path: '/conference', name: 'conference', meta: { public: true, layout: 'air-later-life' }, component: AppConference },
	{ path: '/air-club', name: 'air-club', meta: { public: true, layout: 'air-later-life' }, component: AppClub },
	{ path: '/air-academy', name: 'air-academy', meta: { public: true, layout: 'air-later-life' }, component: AppAcademy },
	{ path: '/sourcing', name: 'sourcing', meta: { public: true, layout: 'air-later-life' }, component: AppSourcing },
	{ path: '/marketing', name: 'marketing', meta: { public: true, layout: 'air-later-life' }, component: AppMarketing },
	{ path: '/ambassadors', name: 'ambassadors', meta: { public: true, layout: 'air-later-life' }, component: AppAmbassadors },
	{ path: '/comprehensive-conversations', name: 'comprehensiveConversations', meta: { public: true, layout: 'air-later-life' }, component: AppComprehensiveConversations },
	{
		path: '/comprehensive-conversations/manifesto',
		name: 'comprehensiveConversations.manifesto',
		meta: { public: true, layout: 'air-later-life' },
		component: AppComprehensiveConversationsManifesto
	},
	{
		path: '/comprehensive-conversations/report',
		name: 'comprehensiveConversations.report',
		meta: { public: true, layout: 'air-later-life' },
		component: AppComprehensiveConversationsReport
	},
	{ path: '/air-mail', name: 'airMail', meta: { public: true, layout: 'air-later-life' }, component: AppAirMail },
	{ path: '/air-mail/:hash', name: 'airMailEdition', meta: { public: true, layout: 'air-later-life' }, component: AppAirMailEdition, props: true },
	{ path: '/air-mail/:hash/:month', name: 'airMailEdition', meta: { public: true, layout: 'air-later-life' }, component: AppAirMailEdition, props: true },
	{ path: '/campaign', name: 'campaign', meta: { public: true, layout: 'air-later-life' }, component: AppCampaign },
	{ path: '/navigator', name: 'navigator', meta: { public: true, layout: 'air-later-life' }, component: AppNavigator }
];
