<!--
	@name app-client-dashboard
	@description Clients dashboard page
	@date 2020/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						<v-progress-circular v-if="loading" :size="25" :width="3" color="blue-grey" indeterminate />
						<template v-else-if="!client || (associationId && !association)">
							Client not found
						</template>
						<template v-else>
							{{ getClientName() }}
						</template>
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" @click="openClientInformation" v-on="on">
								<v-icon large>
									mdi-information-box
								</v-icon>
							</v-btn>
						</template>
						<span>Client Information</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/clients" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Clients</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<div v-if="loading" />
		<v-alert v-else-if="!client" type="error" transition="fade">
			Either the URL is incorrect, or the client has been deleted. If the client was deleted in error, please contact our support team.
		</v-alert>
		<v-fade-transition v-else>
			<v-row>
				<v-col class="py-0" cols="12" md="8">
					<v-row>
						<v-col class="py-0" cols="12">
							<div class="border-effect border-effect--lg ml-6 mb-8">
								<v-progress-linear v-if="!client || (associationId && !association) || !assetList" class="mx-3 mt-4" indeterminate />
								<div v-else class="d-flex">
									<div class="flex-grow-0" style="position: relative">
										<v-avatar size="60px" color="rgb(23, 184, 144)" :style="association ? 'position: relative; top:-4px; left:0;' : ''">
											<span class="white--text headline">{{ getClientInitials(client) }}</span>
										</v-avatar>
										<v-avatar v-if="association" size="60px" color="rgb(27, 120, 216)" style="position: absolute; top: 10px; left: 18px">
											<span class="white--text headline">{{ getClientInitials(association) }}</span>
										</v-avatar>
									</div>
									<div class="flex-grow-1 align-self-center pr-10" :class="association ? 'pl-8' : 'pl-6'">
										<p class="mb-0" :class="assetList?.length ? 'text-body-1 font-weight-medium' : 'text-h6'">
											{{ introduction }}{{ !assetList?.length ? ': ' : '' }}
											<a v-if="!assetList?.length" class="" color="primary" @click="openAddProperty"> add a property to get started. </a>
										</p>
									</div>
								</div>
							</div>
						</v-col>
					</v-row>

					<v-alert v-if="client && assetList && !assetList?.length" outlined text prominent colored-border border="left" color="accent" type="info">
						<v-row>
							<v-col class="grow align-content-center py-0 pl-4">
								<strong>Short on time?</strong> Click the "start a sourcing session" button to go straight to product sourcing. Don't worry, AirFlow will be applied
								to the client record automatically.
							</v-col>
							<v-col class="shrink py-0">
								<v-btn small dark @click="createSourcingSession">
									start a sourcing session
								</v-btn>
							</v-col>
						</v-row>
					</v-alert>

					<common-structure-section class="mt-6" :body-class="'pa-6 rounded'">
						<template #body>
							<app-client-dashboard-workflow-overview
								ref="workflow"
								:title="workflow.name"
								:asset="workflowAsset"
								:association="association"
								:client="client"
								@add-property="openAddProperty"
								@add-note="openAddNote('Please enter a reason for declined.')"
							/>
						</template>
					</common-structure-section>

					<common-structure-section v-if="assetList?.length" class="mt-6">
						<template #header>
							Other Products / Services
						</template>
						<template #body>
							<app-client-dashboard-summary
								:client="client"
								:association="association"
								:asset-list="assetList"
								:workflow-asset="workflowAsset"
								:state-benefit-list="stateBenefitList"
							/>
						</template>
					</common-structure-section>
				</v-col>

				<v-col class="pb-0" cols="12" md="4">
					<app-client-dashboard-property
						v-if="assetList?.length"
						:client-id="id"
						:asset-id="workflowAsset?.id || null"
						:items="assetList"
						@load-property="loadProperty"
						@add-property="openAddProperty"
					/>
					<app-client-dashboard-enquiry v-if="hasEnquiriesAccess" class="mt-6" :client-id="id" :asset-id="workflowAsset?.id || null" :association-id="association?.id" />
					<app-client-dashboard-note
						class="mt-6"
						:client="client"
						:items="clientNoteList?.slice(0, 1)"
						@add-note="openAddNote"
						@view-all-client-notes="viewAllClientNotes"
					/>
				</v-col>
			</v-row>
		</v-fade-transition>
		<app-client-dialog-information ref="clientInformation" :client="client" :association="association" />
		<app-client-dialog-add-property ref="addPropertyDialog" :form="form('add_property').value" @load-client-asset="loadClientAssets" />
		<app-client-dialog-add-note ref="addNoteDialog" :client="client" :association="association" @note-added="loadClientNoteList" />
		<common-dialog ref="noteListDialog">
			<template #header>
				Notes
			</template>
			<template #body>
				<common-base-activity
					:id="id"
					avatar
					:hide-header="true"
					:activity-list="clientNoteList"
					:note-display="showActiveNotes ? 'note' : 'default'"
					@note-deleted="loadClientNoteList"
				/>
			</template>
		</common-dialog>
		<v-dialog v-model="loadingDialog" persistent width="300">
			<v-card color="white">
				<v-card-text>
					{{ loadingDialogText }}
					<v-progress-linear indeterminate color="black" class="mt-2 mb-0" />
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import numeral from 'numeral';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialog from '@/component/common/dialog';
	import CommonBaseActivity from '@/component/common/base/activity';
	import AppClientDashboardWorkflowOverview from '@/component/app/client/dashboard/workflow/overview';
	import AppClientDashboardNote from '@/component/app/client/dashboard/note';
	import AppClientDashboardEnquiry from '@/component/app/client/dashboard/enquiry';
	// import AppClientService from '@/component/app/client/service';
	import AppClientDashboardProperty from '@/component/app/client/dashboard/property';
	import AppClientDialogAddNote from '@/component/app/client/dialog/add-note';
	import AppClientDialogAddProperty from '@/component/app/client/dialog/add-property';
	import AppClientDashboardSummary from '@/component/app/client/dashboard/summary';
	import AppClientDialogInformation from '@/component/app/client/dialog/information';

	export default {
		name: 'app-client-dashboard',

		components: {
			CommonStructureSection,
			CommonDialog,
			CommonBaseActivity,
			AppClientDashboardWorkflowOverview,
			AppClientDashboardNote,
			AppClientDashboardEnquiry,
			// AppClientService,
			AppClientDashboardProperty,
			AppClientDialogAddNote,
			AppClientDialogAddProperty,
			AppClientDashboardSummary,
			AppClientDialogInformation
		},

		provide() {
			return {
				$loadClientAssets: this.loadClientAssets
			};
		},

		props: {
			id: { type: String, required: true }
		},

		data() {
			return {
				loading: true,
				loadingDialogText: null,
				tabConfig: [
					{ title: 'Summary', alerts: 0, name: 'summary' },
					{ title: 'Activity', alerts: 0, name: 'activity' },
					{ title: 'Fact Find', alerts: 0, name: 'fact-find' }
				],
				showActiveNotes: true,
				matterList: null,
				client: null,
				association: null,
				assetList: null,
				clientNoteList: null,
				clientActivityList: null,
				stateBenefitList: null,
				matterTypeListLoaded: false,
				associationList: null,
				associationId: null,
				assetId: null,
				workflowAsset: null
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsSite', ['restrictedRoutes']),
			...mapGetters('CmsForm', ['form']),
			...mapGetters('AppMatterType', ['clientMatterTypes']),

			workflow() {
				let name = 'WorkFlow';
				if (this.site && (!('usesAirFlow' in this.site) || this.site.usesAirFlow)) name = 'AirFlow';
				return { name };
			},

			clientId() {
				return this.$route.params.id;
			},

			mainProperty() {
				return this.assetList?.[0];
			},

			mainPropertyId() {
				return this.mainProperty?.id;
			},

			mainPropertyAddress() {
				if (!this.assetList || !this.assetList?.length) return '';
				const { address1, address2, townCity, postcode } = this.mainProperty?.data?.location;
				return [address1, address2, townCity, postcode].filter(Boolean).join(', ');
			},

			loadingDialog() {
				return this.loadingDialogText !== null;
			},

			introduction() {
				if (!this.client || !this.assetList) return '';
				let intro = `${this.getClientFullName(this.client)} ${this.client.dateOfBirth ? `born ${this.getClientDob(this.client)}` : ''} ${this.getClientAge(this.client)}`;
				if (this.association) {
					intro += ` and ${this.getClientFullName(this.association)}`;
					if (this.association.dateOfBirth) intro += ` born ${this.getClientDob(this.association)} ${this.getClientAge(this.association)}`;
				}
				if (this.assetList?.length)
					intro += ` living at ${this.mainPropertyAddress}${
						this.mainProperty?.data.detail?.tenureName ? ` in a ${this.mainProperty?.data.detail.tenureName} ${this.mainProperty?.data.detail?.assetDescriptionName}` : ''
					}${this.mainProperty?.data.valuation?.value ? ` valued at £${numeral(this.mainProperty?.data.valuation.value).format('0,0')}` : ''}.`;
				return intro.trim();
			},

			hasEnquiriesAccess() {
				return !this.restrictedRoutes.includes('enquiries');
			}
		},

		async created() {
			this.fetchClient().then(() => this.loadClientAssets());
			this.fetchAssociation();
			this.loadClientNoteList();
			this.loadMatterTypeList({ with: 'organisation', order: { property: 'position' } }).then(() => {
				this.matterTypeListLoaded = true;
			});
		},

		methods: {
			...mapActions('AppClient', ['loadClient', 'getUserConnect']),
			...mapActions('AppClientConnected', ['loadConnectedClientList']),
			...mapActions('AppClientAsset', ['loadClientAssetList', 'addClientAsset']),
			...mapActions('LogActivityUser', ['loadClientActivityList', 'addActivityUser', 'deleteActivityUser']),
			...mapActions('AppMatterType', ['loadMatterTypeList']),

			getClientName() {
				let clientName = `${this.getClientFullName(this.client)}`;
				if (this.association) clientName += ` and ${this.getClientFullName(this.association)}`;
				return clientName;
			},

			getClientInitials(client) {
				return client.nameGiven.charAt(0).toUpperCase() + client.nameFamily.charAt(0).toUpperCase();
			},

			getClientFullName(client) {
				return `${client.nameTitleName} ${client.nameGiven} ${client.nameMiddle || ''} ${client.nameFamily}`;
			},

			getClientAge(client) {
				if (!client.dateOfBirth) return '';
				return `(${this.$moment().diff(client.dateOfBirth, 'years')}yrs)`;
			},

			getClientDob(client) {
				if (!client.dateOfBirth) return '';
				return this.$moment(client.dateOfBirth).format('DD MMM YYYY');
			},

			fetchClient() {
				this.loading = true;
				return this.loadClient(this.clientId)
					.then(({ data }) => (this.client = data))
					.finally(() => (this.loading = false));
				// if (this.associationId) this.loadClient(this.associationId).then(({ data }) => (this.association = data));
			},

			async fetchAssociation() {
				const {
					data: { data: associationList }
				} = await this.loadConnectedClientList({ clientId: this.clientId, payload: { order: { property: 'updated', direction: 'desc' } } });

				if (associationList?.length) {
					let association = associationList.find((a) => a.relation === 'spouse');
					if (association) {
						this.association = association;
						this.associationId = association.id;
					}
				}

				if (this.association) {
					this.getUserConnect({ clientIds: [this.clientId, this.associationId] }).then(({ data }) => {
						// Temporary solution to reinit component with main client. $router.push wouldn't work as the component is already loaded.
						if (this.clientId !== data.mainClient && this.$route.name === 'clientDashboard') {
							const queryParams = this.$route.query;
							let queryString = '';
							if (Object.keys(queryParams).length > 0) {
								queryString = new URLSearchParams(queryParams).toString();
							}
							window.location.href = `/client/${data.mainClient}${queryString ? '?' + queryString : ''}`;
						}
					});
				}
			},

			loadClientAssets() {
				if (!this.clientId) return;

				this.assetList = null;

				return this.loadClientAssetList({
					clientId: this.clientId,
					type: 'property',
					payload: { order: { property: 'updated', direction: 'desc' } }
				}).then(async(data) => {
					this.assetList = Array.isArray(data) ? data : [data];
					this.workflowAsset = this.assetList?.[0];
					this.assetId = this.assetList?.[0]?.id;
					this.$nextTick(() => this.$refs.workflow.init());
				});
			},

			loadClientNoteList() {
				const where = [[{ type: 'client_note', "data->>'status'": 'added' }]];
				const order = { property: 'created', direction: 'desc' };
				this.loadClientActivityList({
					clientId: this.clientId,
					mappingOptions: { canDeleteNotes: true, showAccessClient: false },
					payload: { where, order }
				}).then((activityList) => (this.clientNoteList = activityList));
			},

			openAddNote(message) {
				this.$refs.addNoteDialog.open(message);
			},

			loadProperty(property) {
				this.workflowAsset = property;
				this.$nextTick(() => this.$refs.workflow.init());
			},

			openAddProperty() {
				this.$refs.addPropertyDialog.open({ association: this.association });
			},

			/**
			 * @name viewAllClientNotes
			 * @description Sets the activity feed to only display notes
			 */
			viewAllClientNotes() {
				this.$refs.noteListDialog.open();
			},

			/**
			 * @name createSourcingSession
			 * @description Create New Sourcing Session
			 */
			createSourcingSession() {
				this.$router.push(
					`/source?clientId=${this.client?.id}${this.workflowAsset?.id ? `&propertyId=${this.workflowAsset.id}` : ''}${
						this.association?.id ? `&associationId=${this.association.id}` : ''
					}`
				);
			},

			openClientInformation() {
				this.$refs.clientInformation.open();
			}
		}
	};
</script>

<style lang="scss" scoped></style>
