import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Enquiry
 * @exports Service/Enquiry
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Enquiry {
	/**
	 * @static @name get
	 * @description Get a enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`enquiry/${id}`);
	}

	/**
	 * @static @name list
	 * @description Get a list of Enquiry resources
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('enquiry/list', payload);
	}

	/**
	 * @static @name post
	 * @description Add a new Enquiry resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('enquiry', payload);
	}

	/**
	 * @static @name response
	 * @description send a response to an enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static response(payload) {
		return $http.post('enquiry/response', payload);
	}

	/**
	 * @static @name update
	 * @description update a Enquiry resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static update(payload) {
		return $http.post('enquiry/update', payload);
	}

	/**
	 * @static @name createResponse
	 * @description create a response
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static createResponse(payload) {
		return $http.post('enquiry/create-response', payload);
	}

	/**
	 * @static @name withdraw
	 * @description withdraw enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static withdraw(payload) {
		return $http.post('enquiry/withdraw', payload);
	}

	/**
	 * @static @name saveToPdf
	 * @description saveToPdf source
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static saveToPdf(payload) {
		return $http.post('enquiry/save-to-pdf', payload);
	}

	/**
	 * @static @name getFile
	 * @description Get file
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static getFile({ id, payload, s3key }) {
		return $http.get(`enquiry/${id}/file?s3Key=${encodeURIComponent(s3key)}`, payload);
	}
}
