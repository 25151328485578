<!--
	@name app-navigator-banner
	@description
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div class="d-none d-md-block" :component="$options.name">
		<div class="banner--animation py-6">
			<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2; max-width: 1000px;">
				<v-row>
					<v-col class="shrink align-content-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" style="width: 400px; height: 350px;">
							<g id="bottom_cube" data-name="bottom cube">
								<g>
									<polygon class="cls-2" points="160 226.8 160 306.6 225 279.91 225 200.2 160 226.8" />
									<polygon class="cls-1" points="95 279.91 160 306.6 160 226.8 95 200.2 95 279.91" />
									<polygon class="cls-3" points="160 171.4 95 200.2 160 226.8 225 200.2 160 171.4" />
									<polygon class="cls-2" points="126.21 200.2 160 213.5 193.79 200.2 160 184.7 126.21 200.2" />
									<polygon class="cls-1" points="160 184.7 160 213.5 193.79 200.2 160 184.7" />
								</g>
							</g>
							<g id="middle_cube" data-name="middle cube">
								<g>
									<polygon class="cls-2" points="160 165.18 160 203.24 191 190.51 191 152.49 160 165.18" />
									<polygon class="cls-1" points="129 190.51 160 203.24 160 165.18 129 152.49 129 190.51" />
									<polygon class="cls-3" points="160 138.76 129 152.49 160 165.18 191 152.49 160 138.76" />
									<polygon class="cls-2" points="143.89 152.49 160 158.84 176.11 152.49 160 145.1 143.89 152.49" />
									<polygon class="cls-1" points="160 145.1 160 158.84 176.11 152.49 160 145.1" />
								</g>
							</g>
							<g id="top_cube" data-name="top cube">
								<g>
									<polygon class="cls-2" points="160 68.8 160 148.6 225 121.91 225 42.2 160 68.8" />
									<polygon class="cls-1" points="95 121.91 160 148.6 160 68.8 95 42.2 95 121.91" />
									<polygon class="cls-3" points="160 13.4 95 42.2 160 68.8 225 42.2 160 13.4" />
									<polygon class="cls-2" points="126.21 42.2 160 55.5 193.79 42.2 160 26.7 126.21 42.2" />
									<polygon class="cls-1" points="160 26.7 160 55.5 193.79 42.2 160 26.7" />
								</g>
							</g>
						</svg>
					</v-col>
					<v-col class="grow py-10">
						<p class="text-h2 font-weight-black white--text mb-3">
							<span class="primary--text">Unlock</span><br />your <span class="primary--text">potential</span>
						</p>
						<p class="text-h4 font-weight-light white--text mb-6">
							<span class="font-weight-bold">with air</span>navigator
						</p>
						<p class="text-h6 font-weight-bold white--text mb-0">
							Enhance your service offering, expand your reach and identify new opportunities — all while ensuring you're exploring all product solutions for your clients
						</p>
                        <v-btn large target="_blank" color="white" outlined class="mt-8" @click="handleCtaClick({ function: 'arrangeDemo'})">
							request a callback now
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-navigator-banner',

		components: {},

		props: {
			copy: { type: String, required: false, default: null }
		},

		data() {
			return {
                VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL
			};
		},

		computed: {},

		created() {},

        methods: {
            handleCtaClick(cta) {
                this.$emit('handle-cta-click', cta);
            }
        }
	};
</script>

<style lang="scss" scoped>
    .banner--animation {
        border-bottom: 20px solid rgb(30, 176, 225);
        background-position: 50% 50%;
        background-size: cover;
        background-attachment: fixed;
        background-image: url($VUE_APP_S3_AIR_ASSETS_URL+'/manager_documents/d0bb7b46-b858-45ac-8e11-15f9c7883eb7.png');
    }

    .cls-1 {
    fill: #54addd;
    }

    .cls-2 {
    fill: #60d4f4;
    }

    .cls-3 {
    fill: #c0eefb;
    }

    .cls-4 {
    fill: #5354c0;
    }
</style>
