import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Vulnerability
 * @exports Service/Vulnerability
 * @description API Vulnerability Service
 * @date 2024/02/16
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Vulnerability {
	/**
	 * @static @name post
	 * @description Add a new vulnerability resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('vulnerability', payload);
	}

	/**
	 * @static @name list
	 * @description Get all vulnerability assessments
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('vulnerability/list', payload);
	}

	/**
	 * @static @name list
	 * @description Get all vulnerability assessments
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static getFile({ payload, id }) {
		return $http.get(`vulnerability/${id}/file`, payload);
	}
}
