import { Matter } from '@/service';

/**
 * @class @name MatterType
 * @description Exposes menu store module
 */
export default class MatterType {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name modifyMatter
			 * @param {function} commit
			 * @param {Object} data
			 */
			modifyMatter(context, data) {
				let { id, payload } = data;
				return Matter.modify(id, payload);
			},

			/**
			 * @async @name deleteMatter
			 * @param {function} commit
			 * @param {Object} data
			 */
			deleteMatter(context, id) {
				return Matter.delete(id);
			}
		};
	}
}
