<!--
	@name app-dashboard-countdown
	@description Countdown
	@date 2020/05/13
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div v-if="display" :component="$options.name">
		<common-structure-section v-show="active" class="mb-6">
			<template #header>
				{{ title }}
			</template>
			<template #body>
				<v-sheet class="text-center pa-1">
					<div class="wrap">
						<div class="countdown countdown-static">
							<div class="bloc-time days" :data-init-value="days">
								<span class="count-title">Days</span>

								<div class="figure days days-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure days days-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>

							<div class="bloc-time hours" :data-init-value="hours">
								<span class="count-title">Hours</span>

								<div class="figure hours hours-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure hours hours-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>

							<div class="bloc-time min" :data-init-value="minutes">
								<span class="count-title">Minutes</span>

								<div class="figure min min-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure min min-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>

							<div class="bloc-time sec" :data-init-value="seconds">
								<span class="count-title">Seconds</span>

								<div class="figure sec sec-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure sec sec-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="text-left px-1">
						<v-btn v-if="fullDetails" color="primary" block class="mt-5" @click="openDialog">
							click for full details
						</v-btn>
					</div>
				</v-sheet>
			</template>
		</common-structure-section>

		<v-dialog v-model="dialogActive" persistent width="1000">
			<v-card>
				<v-app-bar dark class="generic-gradient">
					<v-toolbar-title>
						{{ title }}
					</v-toolbar-title>
					<v-spacer />
					<v-btn icon @click="closeDialog">
						<v-icon>mdi-close-box</v-icon>
					</v-btn>
				</v-app-bar>
				<v-card-text class="pa-8 px-15">
					<div class="wrap">
						<div class="countdown countdown-dialog">
							<div class="bloc-time days" :data-init-value="days">
								<span class="count-title">Days</span>

								<div class="figure days days-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure days days-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>

							<div class="bloc-time hours" :data-init-value="hours">
								<span class="count-title">Hours</span>

								<div class="figure hours hours-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure hours hours-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>

							<div class="bloc-time min" :data-init-value="minutes">
								<span class="count-title">Minutes</span>

								<div class="figure min min-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure min min-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>

							<div class="bloc-time sec" :data-init-value="seconds">
								<span class="count-title">Seconds</span>

								<div class="figure sec sec-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>

								<div class="figure sec sec-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<v-divider class="mt-10 mb-8" />
					<h2 v-if="subtitle" class="text-h5 font-weight-bold mb-6 text-center">
						{{ subtitle }}
					</h2>

					<div v-if="countdownImageStyle" class="image mb-6" :style="countdownImageStyle" />
					<a v-else-if="fixedImage && 'link' in fixedImage" target="_blank" :href="fixedImage.link">
						<img class="mb-6" :src="fixedImage.src" :style="`width: ${fixedImage.width}`" />
					</a>
					<img v-else-if="fixedImage" class="mb-6" :src="fixedImage.src" :style="`width: ${fixedImage.width}`" />

					<div class="pa-0 mb-6 rounded hero-background">
						<v-row no-gutters>
							<v-col cols="12" md="6" class="pa-6 align-content-center">
								<p class="white--text text-h3 font-weight-bold mb-2">
									Fit for the Future
								</p>
								<p class="white--text text-h5 font-weight-light mb-0">
									PREPARE TO PERFORM
								</p>
							</v-col>
							<v-col cols="12" md="6" class="text-right align-content-center col-md-6 col-12 pt-0 pb-3 px-6">
								<img class="d-inline-block mb-1" :src="'manager_documents/60c11503-8fec-44ad-9ffb-b898c4767365.png' | urlize" style="max-width: 150px;" />
							</v-col>
						</v-row>
					</div>

					<p class="text-body-1 px-0">
						Join us at our <strong>Fit for the Future</strong> event on January 16th 2025, a premier gathering designed with our members in mind, supporting you to seize new opportunities. This event will equip you with the insights, tools, and strategies needed to thrive in an evolving financial landscape - featuring expert speakers, practical Masterclasses and an exciting exhibition space.
					</p>

					<p class="text-body-1 px-0">
						<strong>Advance your business confidently in 2025 and reserve your place at this exclusive event and join us in shaping the future of financial advice.</strong>
					</p>

					<p class="text-body-1 px-0">
						<strong>Haven't registered yet?</strong>
					</p>
					<v-sheet v-if="link" class="text-center mt-6">
						<v-btn :href="link" target="_blank" large color="primary" class="mx-auto">
							{{ buttontext }}
						</v-btn>
					</v-sheet>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import { TweenMax, Quart, Back } from 'gsap';

	export default {
		name: 'app-dashboard-countdown',

		components: {
			CommonStructureSection
		},

		data() {
			return {
				display: true,
				active: false,
				dialogActive: false,
				obj: {},
				days: null,
				hours: null,
				minutes: null,
				seconds: null,
				total_seconds: null,
				staticInit: false,
				dialogInit: false,
				countdown_interval: null,
				date: '2025-01-15 23:59:59',
				title: '‘Fit for the Future’ event',
				subtitle: false,
				link: 'https://www.eventbrite.co.uk/e/fit-for-the-future-tickets-1091013283099?aff=Sourcing',
				buttontext: 'REGISTER',
				fullDetails: true
			};
		},

		computed: {
			countdownImageStyle() {
				return false;
				//return { 'background-image': `url('${this.$options.filters.urlize('generic/countdown-conference2024.gif')}')` };
			},

			fixedImage() {
				return false;
				// return {
				// 	src: `${this.$options.filters.urlize('banner/3b156272-8822-40a6-a5b6-37b5c04ac6c9.png')}`,
				// 	link: 'https://www.eventbrite.co.uk/e/fit-for-the-future-tickets-1091013283099?aff=Sourcing',
				// 	width: '100%'
				//  };
			}
		},

		methods: {
			open(type = 'static') {
				if (type !== 'dialog') this.active = true;
				if (!this[`${type}Init`]) {
					//this[`${type}Init`] = true;
					this.$nextTick(() => {
						this.init(type);
					});
				}
			},

			openDialog() {
				this.active = false;
				this.open('dialog');
			},

			closeDialog(openStatic = true) {
				this.dialogActive = false;
				if (openStatic) this.open('static');
			},

			getParentAttr(element, attr) {
				let node = document.querySelector(element);
				return node.parentElement.getAttribute(attr);
			},

			// Initialize the countdown
			init(type) {
				var t1 = new Date(this.date);
				var t2 = new Date();
				var dif = t1.getTime() - t2.getTime();
				if(dif < 0) {
					this.display = false;
					this.closeDialog(false);
					return;
				}

				if (type == 'dialog') this.dialogActive = true;

				this.$nextTick(() => {
					this.total_seconds = Math.abs(dif / 1000);
					var delta = this.total_seconds;

					this.days = Math.floor(delta / 86400);
					delta -= this.days * 86400;
					// calculate (and subtract) whole hours
					this.hours = Math.floor(delta / 3600);
					delta -= this.hours * 3600;

					// calculate (and subtract) whole minutes
					this.minutes = Math.floor(delta / 60);
					delta -= this.minutes * 60;

					// what's left is seconds
					this.seconds = Math.floor(delta % 60); // in theory the modulus is not required

					// Init countdown values
					this.values = {
						days: this.days,
						hours: this.hours,
						minutes: this.minutes,
						seconds: this.seconds
					};

					let selector = '.countdown-static';
					if (type == 'dialog') selector = '.countdown-dialog';
					this.objel = document.querySelector(selector);

					// DOM
					this.obj = {
						days: this.objel.querySelectorAll('.bloc-time.days .figure'),
						hours: this.objel.querySelectorAll('.bloc-time.hours .figure'),
						minutes: this.objel.querySelectorAll('.bloc-time.min .figure'),
						seconds: this.objel.querySelectorAll('.bloc-time.sec .figure')
					};

					// Animate countdown to the end
					if(this.days < 100) this.count(this.obj);
					else this.display = false;
				});
			},

			count(obj) {
				var that = this,
					$day_1 = obj.days[0],
					$day_2 = obj.days[1],
					$hour_1 = obj.hours[0],
					$hour_2 = obj.hours[1],
					$min_1 = obj.minutes[0],
					$min_2 = obj.minutes[1],
					$sec_1 = obj.seconds[0],
					$sec_2 = obj.seconds[1];

				if (this.countdown_interval) clearInterval(this.countdown_interval);

				this.countdown_interval = setInterval(() => {
					if (that.total_seconds > 0) {
						--that.values.seconds;

						if (that.values.minutes >= 0 && that.values.seconds < 0) {
							that.values.seconds = 59;
							--that.values.minutes;
						}

						if (that.values.hours >= 0 && that.values.minutes < 0) {
							that.values.minutes = 59;
							--that.values.hours;
						}

						if (that.values.days >= 0 && that.values.hours < 0) {
							that.values.hours = 23;
							--that.values.days;
						}

						// Days
						that.checkHour(that.values.days, $day_1, $day_2);
						// Hours
						that.checkHour(that.values.hours, $hour_1, $hour_2);
						// Minutes
						that.checkHour(that.values.minutes, $min_1, $min_2);
						// Seconds
						that.checkHour(that.values.seconds, $sec_1, $sec_2);

						--that.total_seconds;
					} else {
						this.display = false;
						clearInterval(that.countdown_interval);
					}
				}, 1000);
			},

			animateFigure($el, value) {
				var $top = $el.querySelectorAll('.top'),
					$bottom = $el.querySelectorAll('.bottom'),
					$back_top = $el.querySelectorAll('.top-back'),
					$back_bottom = $el.querySelectorAll('.bottom-back');

				// Before we begin, change the back value
				$back_top[0].querySelectorAll('span')[0].innerHTML = value;

				// Also change the back bottom value
				$back_bottom[0].querySelectorAll('span')[0].innerHTML = value;

				// Then animate
				TweenMax.to($top[0], 0.8, {
					rotationX: '-180deg',
					transformPerspective: 300,
					ease: Quart.easeOut,
					onComplete() {
						$top[0].innerHTML = value;
						$bottom[0].innerHTML = value;
						TweenMax.set($top[0], { rotationX: 0 });
					}
				});

				TweenMax.to($back_top[0], 0.8, {
					rotationX: 0,
					transformPerspective: 300,
					ease: Back.easeOut,
					scaleX: 0.98,
					clearProps: 'all'
				});
			},

			checkHour(value, $el_1, $el_2) {
				var val_1 = value.toString().charAt(0),
					val_2 = value.toString().charAt(1),
					fig_1_value = $el_1.querySelectorAll('.top'),
					fig_2_value = $el_2.querySelectorAll('.top');

				if (value >= 10) {
					// Animate only if the figure has changed
					if (fig_1_value[0].innerHTML !== val_1) this.animateFigure($el_1, val_1);
					if (fig_2_value[0].innerHTML !== val_2) this.animateFigure($el_2, val_2);
				} else {
					// If we are under 10, replace first figure with 0
					if (fig_1_value[0].innerHTML !== '0') this.animateFigure($el_1, 0);
					if (fig_2_value[0].innerHTML !== val_1) this.animateFigure($el_2, val_1);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	.hero-background {
		background-image: url($VUE_APP_S3_AIR_ASSETS_URL + '/manager_documents/b1bf62f0-aca8-4a4e-83bc-80dca7a455d2.png');
		background-size: cover;
	}
	// Variables
	$lato: 'Roboto';
	$cardCol1: #fff;
	$cardCol2: #f7f7f7;

	// Countdown
	.countdown {
		width: 100%;
		margin: 0 auto;
		display: flex;

		.bloc-time {
			text-align: center;
			flex-grow: 1;
			flex-shrink: 1;
			padding: 4px;
			flex-basis: 20%;

			&:last-child {
				margin-right: 0;
			}
		}

		.count-title {
			display: block;
			margin-bottom: 15px;
			font: normal 0.94em $lato;
			//color: #55acb8;
			//color: #f0acb8;
			text-transform: uppercase;
			font-weight: 500;
		}

		.figure {
			position: relative;
			float: left;
			height: 3rem;
			width: 46%;
			margin: 0 2%;
			background-color: $cardCol1;
			border-radius: 8px;
			box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);

			&:last-child {
				margin-right: 0;
			}

			> span {
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				font: normal 2.5rem $lato;
				line-height: 3rem;
				font-weight: 700;
				color: rgba(27, 120, 216, 1);
			}

			&.days {
				> span {
					color: rgba(103, 0, 86, 1);
				}
			}

			.top,
			.bottom-back {
				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
			}

			.top {
				z-index: 3;
				background-color: $cardCol2;
				transform-origin: 50% 100%;
				-webkit-transform-origin: 50% 100%;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				transform: perspective(200px);
			}

			.bottom {
				z-index: 1;

				&:before {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					height: 50%;
					background-color: rgba(0, 0, 0, 0.02);
				}
			}

			.bottom-back {
				z-index: 2;
				top: 0;
				height: 50%;
				overflow: hidden;
				background-color: $cardCol2;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				span {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			.top,
			.top-back {
				height: 50%;
				overflow: hidden;
				backface-visibility: hidden;
			}

			.top-back {
				z-index: 4;
				bottom: 0;
				background-color: #fff;
				background-color: $cardCol1;
				-webkit-transform-origin: 50% 0;
				transform-origin: 50% 0;
				transform: perspective(200px) rotateX(180deg);
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;

				span {
					position: absolute;
					top: -100%;
					left: 0;
					right: 0;
					margin: auto;
				}
			}
		}
	}

	.countdown-dialog {
		.count-title {
			font-size: 1.25em;
		}

		.figure {
			height: 4.2rem;

			> span {
				font-size: 3.5rem;
				line-height: 4.2rem;
			}
		}
	}

	.image {
		width: 100%;
		height: 150px;
		background-position: 50% 50%;
		background-size: cover;
	}

	.wrap {
		background-position: 50% 50%;
		background-size: cover;
	}
</style>
