<template>
	<v-row>
		<!-- Submission -->
		<v-col class="py-0" cols="12" md="8">
			<!-- <common-structure-section v-if="onlyManualProducts" class="mb-6">
				<template #body>
					<div>
						<v-alert prominent text outlined border="left" type="error" class="text-body-1 mb-6 font-weight-bold">
							Unfortunately, your chosen Products are not available for 'Quick KFI Requests'. However, select any of the Products listed below and you will be
							diverted to the relevant Provider website to begin your KFI request.
						</v-alert>
						<product-list :products="manualProducts" :link-button="true" @refresh-products="$emit('refresh-products')" @remove-product="removeProduct" />
					</div>
				</template>
			</common-structure-section> -->

			<div v-if="site" class="border-effect border-effect--lg ml-6 mb-10">
				<p class="text-h5 mb-2 font-weight-regular">
					Just one quick step remaining in order to <span class="font-weight-bold">submit your KFI request{{ automatedProducts.length > 1 ? 's' : '' }}</span>
				</p>
				<p class="text-body-1 mb-0">
					Simply enter any missing information below and press "Proceed". You'll be notified by email as each KFI document is received, at which point you can download
					and review directly from your {{ site.softwareName }} account.
				</p>
			</div>

			<v-alert
				v-if="showPanelChange"
				class="pl-4 mb-6 white"
				:class="panelChangeMessage.disclosed ? 'grey lighten-1' : 'warning'"
				:color="panelChangeMessage.disclosed ? 'success' : 'error'"
				dark
				dense
				prominent
				colored-border
				border="left"
				:type="panelChangeMessage.disclosed ? 'success' : 'warning'"
				@click="panelChangeMessage.disclosed = !panelChangeMessage.disclosed"
			>
				<v-row>
					<v-col class="grow text-body-1 py-1">
						{{ panelChangeMessage.text }}
					</v-col>
					<v-col class="shrink py-1">
						<v-checkbox
							:value="panelChangeMessage.disclosed"
							inset
							class="ma-0"
							hide-details
							@click.self="panelChangeMessage.disclosed = !panelChangeMessage.disclosed"
						/>
					</v-col>
				</v-row>
			</v-alert>

			<common-structure-section class="mb-6">
				<template #body>
					<div v-if="!products || !form('kfi').value">
						<v-row class="no-gutters">
							<v-col v-for="index in 6" :key="index" cols="12" sm="12" class="pa-0 ma-0">
								<v-card class="flex-grow-1" :class="[index !== 6 ? 'mb-5' : 'mb-0']">
									<v-skeleton-loader type="list-item-three-line" />
								</v-card>
							</v-col>
						</v-row>
					</div>
					<div v-else-if="outstandingProducts.length">
						<!-- Company Details -->
						<common-form-company
							v-if="showCompany"
							ref="companyDetails"
							:form="formFromGroup('kfi', 'company')"
							:item="company"
							@set-validation="setValidation"
							@dynamic-form-submit="setCompany"
						/>

						<!-- Applicant Details-->
						<common-form-client
							ref="applicantDetails"
							:form="applicantForm"
							:clients="clientList"
							edit-only
							read-only
							@set-client="setApplicant"
							@set-validation="setValidation"
						/>

						<!-- Property Details -->
						<common-form-property
							ref="propertyDetails"
							:property-form="propertyForm"
							:another-property-form="formFromGroup('kfi', 'another_property')"
							:property="property"
							:client="clientList[0]"
							:another-property="anotherProperty"
							:show-another-property="showAnotherProperty"
							edit-only
							@set-validation="setValidation"
							@set-property="setProperty"
							@set-another-property="setAnotherProperty"
						/>

						<!-- Lender Account Credentials -->
						<common-form-lender-credentials v-if="showLenderCredentials" ref="lenderCredentials" :lenders="lenders" />

						<!-- Borrowing Requested -->
						<common-form-section
							v-if="borrowingRequestedForm.length"
							ref="borrowingRequested"
							title="Borrowing Requested"
							:form="borrowingRequestedForm"
							:item="borrowingRequested"
							@set-validation="setValidation"
						/>

						<!-- Advice Fee -->
						<common-form-section ref="adviceFee" title="Advice Fee" :form="adviceFeeForm" :item="adviceFee" @set-validation="setValidation">
							<template #header>
								<div class="d-flex flex-grow-1 justify-space-between align-center">
									<span>Advice Fee</span>
									<v-switch v-model="setAdviceFeeAsDefault" :disabled="!adviceFeeForm.length" label="Set as default" color="primary" hide-details />
								</div>
							</template>
						</common-form-section>

						<!-- Notes -->
						<common-form-section ref="notes" title="Notes" :form="formFromGroup('kfi', 'notes')" :item="notes" />

						<!-- Form Footer -->
						<div class="text-center">
							<v-alert v-show="!isValid" transition="slide-y-transition" dense prominent outlined text border="left" type="error" class="mb-6 text-body-1 text-left">
								You cannot proceed until ALL sections are validated. Please go back and address any sections displaying this error message.
							</v-alert>
							<v-btn large color="primary" :loading="submitting" @click="submitKfiRequest">
								Proceed to Request KFI{{ automatedProducts.length > 1 ? 's' : '' }}
							</v-btn>
						</div>
					</div>
				</template>
			</common-structure-section>
		</v-col>

		<!-- Products -->
		<v-col class="py-0" cols="6" md="4">
			<div v-if="!products">
				<common-structure-section class="mb-0">
					<template #header>
						Outstanding
					</template>
					<template #body>
						<v-row class="no-gutters">
							<v-col v-for="index in 2" :key="index" cols="12" sm="12" class="pa-0 ma-0">
								<v-card class="flex-grow-1" :class="[index !== 2 ? 'mb-5' : 'mb-0']">
									<v-skeleton-loader type="list-item-avatar" />
								</v-card>
							</v-col>
						</v-row>
					</template>
				</common-structure-section>
			</div>
			<div v-else-if="outstandingProducts.length">
				<common-structure-section v-if="automatedProducts.length" class="mb-6">
					<template #header>
						Outstanding // {{ automatedProducts.length }}
					</template>
					<template #body>
						<product-list
							:products="automatedProducts"
							@refresh-products="$emit('refresh-products')"
							@remove-product="$emit('remove-product')"
						/>
					</template>
				</common-structure-section>

				<common-structure-section v-if="displayManualOnlyProductsSidebar" class="mb-6">
					<template #header>
						Manual Only // {{ manualProducts.length }}
					</template>
					<template #body>
						<v-alert type="warning" icon="mdi-exclamation" outlined text border="left" class="text-body-2">
							KFI requests for Products listed below <span class="font-weight-bold">cannot be made via Air Sourcing</span>. Please click the Product to request a KFI
							from the Providers website
						</v-alert>
						<product-list
							:products="manualProducts"
							:link-button="true"
							@refresh-products="$emit('refresh-products')"
							@remove-product="$emit('remove-product')"
						/>
					</template>
				</common-structure-section>

				<common-structure-section v-if="!displaySummary && submittedProducts.length" class="mb-6">
					<template #header>
						Submitted // {{ submittedProducts.length }}
					</template>
					<template #body>
						<product-list
							:products="submittedProducts"
							:delete-button="false"
							@refresh-products="$emit('refresh-products')"
							@remove-product="$emit('remove-product')"
						/>
					</template>
				</common-structure-section>

				<div v-if="hasBanner && !manualProducts.length">
					<common-promotion-advisor-advert />
				</div>
			</div>
			<v-sheet v-else-if="hasBanner" class="pa-5">
				<common-promotion-advisor-advert />
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { isEmpty, isNil } from 'lodash';
	import { $localStorage } from '@/utils/storage';
	import { ElementTools } from '@/utils';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonFormCompany from '@/component/common/form/company';
	import CommonFormClient from '@/component/common/form/client';
	import CommonFormProperty from '@/component/common/form/property';
	import CommonFormSection from '@/component/common/form/section';
	import CommonFormLenderCredentials from '@/component/common/form/lender-credentials';
	import ProductList from './product-list.vue';

	export default {
		name: 'kfi-request-submission',

		components: {
			CommonPromotionAdvisorAdvert,
			ProductList,
			CommonStructureSection,
			CommonFormCompany,
			CommonFormClient,
			CommonFormProperty,
			CommonFormSection,
			CommonFormLenderCredentials
		},

		props: {
			products: {
				required: true,
				type: Array
			},
			submittedProducts: {
				type: Array,
				required: true
			},
			displaySummary: {
				required: true,
				type: Boolean
			}
		},

		data() {
			return {
				selectPanel: [],
				panelChangeMessage: {
					text: 'You are requesting KFIs for Products that sit outside of the Air Select panel. Please tick to confirm your understanding and proceed to request these KFIs',
					disclosed: false
				},
				company: {},
				validations: {},
				clientList: [{}],
				property: {},
				anotherProperty: {},
				borrowingRequested: {},
				setAdviceFeeAsDefault: false,
				adviceFee: {},
				notes: {},
				submitting: false
			};
		},

		computed: {
			...mapState('Account', ['organisation', 'department']),
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsForm', ['formFromGroup', 'form']),

			/**
			 * @name outstandingProducts
			 * @description used to get kfi submissions to be submitted
			 */
			outstandingProducts() {
				return this.products?.filter((product) => !product.kfiRequestSubmitted && product.valid) || [];
			},

			/**
			 * @name manualProducts
			 * @description used to get kfi submissions to be submitted manually
			 */
			manualProducts() {
				return this.outstandingProducts?.filter((item) => item.product?.keyFactsIllustration?.online && item.product?.keyFactsIllustration?.url) || [];
			},

			/**
			 * @name automatedProducts
			 * @description used to get kfi submissions to be submitted automatically
			 */
			automatedProducts() {
				return this.outstandingProducts?.filter((product) => !product.product?.keyFactsIllustration?.online || !product.product?.keyFactsIllustration?.url) || [];
			},

			/**
			 * @name onlyManualProducts
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			onlyManualProducts() {
				return this.outstandingProducts.length === this.manualProducts.length;
			},

			/**
			 * @name showPanelChange
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showPanelChange() {
				if (!this.products) return false;
				if (!this.products.length) return false;
				if (!this.membership('select').id) return false;
				if (!this.selectPanel.length) return false;
				const hasNonAirSelectProviders = this.outstandingProducts.some((product) => !this.selectPanel.includes(product.product.provider.id));
				return hasNonAirSelectProviders;
			},
			/**
			 * @name showCompany
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showCompany() {
				return this.department.name && this.department.name.includes('owner') && !this.organisation.data?.membership?.fca;
			},
			/**
			 * @name showAnotherProperty
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showAnotherProperty() {
				return this.property.mortgageTypeName === 'Purchase';
			},
			/**
			 * @name showLenderCredentials
			 * @description Lender Credentials section display conditionshowPanelChangeisvalid
			 * @return {Boolean} Value for display condition
			 */
			showLenderCredentials() {
				return this.lenders?.length;
			},
			/**
			 * @name displayManualOnlyProductsSidebar
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			displayManualOnlyProductsSidebar() {
				return this.products && this.products.length && this.manualProducts.length && !this.onlyManualProducts;
			},

			/**
			 * @name applicantForm
			 * @description TODO
			 * @return {Array} Applicant form
			 */
			applicantForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'applicant');
				return form.length ? this.showFormFields(form) : [];
			},
			/**
			 * @name propertyForm
			 * @description TODO
			 * @return {Array} Borrowing requested form
			 */
			propertyForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'property');
				return form.length ? this.showFormFields(form) : [];
			},
			/**
			 * @name borrowingRequestedForm
			 * @description TODO
			 * @return {Array} Borrowing requested form
			 */
			borrowingRequestedForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'borrowing_requested');
				return form.length ? this.showFormFields(form) : [];
			},
			/**
			 * @name adviceFeeForm
			 * @description TODO
			 * @return {Array} Advice fee form
			 */
			adviceFeeForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'advice_fee');
				return form.length ? this.showFormFields(form) : [];
			},

			/**
			 * @name lenders
			 * @description lender list constructed from outstanding products
			 * @return {Array} lenders
			 */
			lenders() {
				// If interest reward, switch ID 50ce465a-863c-4779-9def-87d6ea114eca for 66f485cf-bc37-45a1-aacc-4f9e3d2ff1e3
				let providers = this.automatedProducts.map((product) => {
					if (product.product.provider.nameUnique === 'standard_life_home_finance' && product.product?.name?.toLowerCase().includes('interest reward'))
						return 'standard_life_home_finance___interest_reward';
					if (product.product.provider.nameUnique === 'more2life' && product.product?.name?.toLowerCase().includes('payment term')) return 'more2life__payment_term';
					return product.product.provider.nameUnique;
				});
				return [...new Set(providers)];
			},

			/**
			 * @name isValid
			 * @description all forms are valid
			 * @return {Boolean} is valid
			 */
			isValid() {
				if (this.showPanelChange && !this.panelChangeMessage.disclosed) return false;
				return !Object.keys(this.validations).filter((item) => this.validations[item] === false)[0];
			},

			hasBanner() {
				return this.site.adverts?.advisor;
			}
		},

		methods: {
			...mapActions('CmsSite', ['getMembershipSite']),
			...mapActions('LifetimeMortgageKfi', ['submitKfi']),

			init() {
				this.setSelectPanel();
				if (this.showCompany)
					this.setCompany({
						organisationName: this.organisation?.name,
						organisationFcaNumber: this.organisation?.data?.membership?.fca
					});
			},

			setSelectPanel() {
				this.getMembershipSite('select').then(({ data }) => {
					let panel = data?.matter?.lifetimeMortgage?.panel?.sourcing;
					if (panel) this.selectPanel = data?.matter?.lifetimeMortgage?.panel?.sourcing;
				});
			},

			setCompany(company) {
				this.company = company;
				this.$nextTick(() => this.$refs.companyDetails.validateForm());
			},

			setApplicant({ dataIndex, data }) {
				this.$set(this.clientList, dataIndex, data);
				this.$nextTick(() => this.$refs.applicantDetails.validateForm());
			},

			setProperty(property) {
				let firstTimeBuyer = !isNil(property.firstTimeBuyer)
					? property.firstTimeBuyer
					: !isNil(this.property?.firstTimeBuyer)
					? this.property.firstTimeBuyer
					: this.firstTimeBuyer;
				this.property = { ...property, firstTimeBuyer };
				this.$nextTick(() => this.$refs.propertyDetails.validateForm());
			},

			setAnotherProperty(property) {
				this.anotherProperty = property;
				this.$nextTick(() => this.$refs.propertyDetails.validateForm());
			},

			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			setProcessData(data) {
				this.setAnotherProperty(data.alternativeProperty);
				if (this.$refs.borrowingRequested) {
					this.borrowingRequested = data.borrowingRequested || {};
					this.$nextTick(() => this.$refs.borrowingRequested.validateForm());
				}

				this.notes = data.notes || {};

				this.adviceFee = data.adviceFee || {};

				this.property = { ...this.property, firstTimeBuyer: data.firstTimeBuyer };
				this.$nextTick(() => this.$refs.propertyDetails.validateForm());

				if ($localStorage.get('adviceFeeDefaults') && isEmpty(this.adviceFee)) {
					this.adviceFee = $localStorage.get('adviceFeeDefaults');
					this.setAdviceFeeAsDefault = true;
				}

				this.$nextTick(() => this.$refs.adviceFee.validateForm());
			},

			showFormFields(form) {
				return form
					.map((field) => {
						if (field && typeof field === 'object' && field.conditions && field.conditions.include) {
							const result = field.conditions.include.every((condition) => this.getConditionResult(condition));
							return result ? field : null;
						} else return field;
					})
					.filter((f) => f);
			},
			getConditionResult(condition) {
				let result = false;
				let dataSet = [];
				if (condition.entity === 'asset') dataSet = [this.property];
				if (condition.entity === 'product') dataSet = this.outstandingProducts.map((product) => product.product);

				dataSet.map((item) => {
					let data = null;
					if (condition.name.includes('.')) data = condition.name.split('.').reduce((o, i) => o[i] || o.value[i], item);
					else data = item[condition.name];

					if (
						(condition.type === 'equals' && data == condition.value) ||
						(condition.type === 'not_equals' && data != condition.value) ||
						(condition.type === 'includes' && data && data.includes(condition.value)) ||
						(condition.type === 'in_array' && condition.value.includes(data)) ||
						(condition.type === 'not_in_array' && !condition.value.includes(data)) ||
						(condition.type === 'is_empty' && isEmpty(data)) ||
						(condition.type === 'not_empty' && !isEmpty(data))
					) {
						result = true;
						return;
					}
				});

				return result;
			},

			/**
			 * @name submitKfiRequest
			 * @description Submit KFI requsts
			 */
			async submitKfiRequest() {
				if (!this.validateForms() || !this.validateClients()) return;

				this.submitting = true;
				const payload = this.createPayload();

				this.submitKfi(payload)
					.then(() => {
						if (this.setAdviceFeeAsDefault) $localStorage.set('adviceFeeDefaults', this.adviceFee);
						this.$emit('refresh-products');
						ElementTools.fireNotification(this.$el, 'success', 'KFI request has been submitted successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst submitting the KFI request'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name validateForms
			 * @description Validate all forms in the page
			 * @return {Boolean} Validation result
			 */
			validateForms() {
				this.$refs.adviceFee.validateForm();
				if (this.$refs.companyDetails) this.$refs.companyDetails.validateForm();
				if (this.$refs.borrowingRequested) this.$refs.borrowingRequested.validateForm();

				return this.isValid;
			},

			validateForm(form, args) {
				this.$refs[form].validateForm(args);
			},

			validateClients() {
				if (this.clientList.length !== this.products[0].clients.length) {
					ElementTools.fireNotification(this.$el, 'error', 'Client data is missing or invalid.');
					return false;
				}

				return true;
			},

			fetchClient(index, clientId, edit) {
				this.$refs.applicantDetails.fetchClient(index, clientId, edit);
			},

			fetchAsset(clientId, assetId) {
				this.$refs.propertyDetails.fetchAsset(clientId, assetId);
			},

			createPayload() {
				const property = this.property;

				const mappedProperty = {
					id: property.id,
					type: property.type,
					data: {
						construction: {
							details: property.constructionDetails,
							numberOfFloors: property.numberOfFloors,
							standard: property.standard
						},
						cost: {
							groundRent: property.groundRent,
							serviceCharge: property.serviceCharge
						},
						detail: {
							listed: property.listed,
							listedBuildingGradeId: property.listedBuildingGradeId,
							servedByLift: property.servedByLift,
							ageRestricted: {
								details: property.ageRestrictedDetails
							},
							unexpiredLeaseTerm: Number(property.unexpiredLeaseTerm),
							whollyPrivatelyOwned: property.whollyPrivatelyOwned,
							managementCompany: this.borrowingRequested?.managementCompany,
							blockInsurance: this.borrowingRequested?.blockInsurance
						},
						location: {
							floor: property.floor,
							aboveRetailPremises: property.aboveRetailPremises
						}
					}
				};

				// map client
				const mappedClientList = this.clientList.map((item) => {
					const { dateOfBirth, estimatedRetirementAge, ...client } = item;
					const [day, month, year] = dateOfBirth.split('/');
					const dob = this.$moment.utc(`${month}/${day}/${year}`).toISOString();
					if (estimatedRetirementAge) client.data = { estimatedRetirementAge };
					return { ...client, dateOfBirth: dob };
				});

				const mappedOrganisation = this.showCompany
					? {
							name: this.company?.organisationName,
							data: {
								membership: {
									fca: this.company?.organisationFcaNumber
								}
							}
					  }
					: undefined;

				return {
					processId: this.outstandingProducts[this.outstandingProducts.length - 1].processId,
					client: mappedClientList,
					asset: mappedProperty,
					organisation: mappedOrganisation,
					data: {
						process: {
							borrowingRequested: { ...this.borrowingRequested, managementCompany: undefined, blockInsurance: undefined },
							adviceFee: this.adviceFee,
							notes: this.notes,
							firstTimeBuyer: property.firstTimeBuyer,
							alternativeProperty: this.anotherProperty
						},
						action: {
							key: 'SubmitKeyFactsIllustration'
						}
					}
				};
			}
		}
	};
</script>
