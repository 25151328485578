import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Sourcing
 * @exports Service/Sourcing
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Sourcing {
	/**
	 * @static @name list
	 * @description Get all Sourcings
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('lifetime-mortgage/sourcing/list', payload);
	}

	/**
	 * @static @name results
	 * @description Get all results for a Sourcing
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static results(payload) {
		return $http.post('lifetime-mortgage/sourcing/results', payload);
	}

	/**
	 * @static @name post
	 * @description Add a new Sourcing resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('lifetime-mortgage/sourcing', payload);
	}

	/**
	 * @static @name quick
	 * @description quick source
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static quick(payload) {
		return $http.post('lifetime-mortgage/sourcing/quick', payload);
	}

	/**
	 * @static @name iteration
	 * @description iteration source
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static iteration(payload) {
		return $http.post('lifetime-mortgage/sourcing/iteration', payload);
	}

	/**
	 * @static @name fetchInsights
	 * @description iteration source
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static fetchInsights(payload) {
		return $http.post('lifetime-mortgage/sourcing/near-misses', payload);
	}

	/**
	 * @static @name saveToPdf
	 * @description saveToPdf source
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static saveToPdf(payload) {
		return $http.post('lifetime-mortgage/sourcing/save-to-pdf', payload);
	}

	/**
	 * @static @name saveToPdf
	 * @description saveToPdf source
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static getFile({ id, payload }) {
		return $http.get(`lifetime-mortgage/sourcing/${id}/file`, payload);
	}
}
