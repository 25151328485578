<template>
	<div>
		<!-- Summary -->
		<v-col class="py-0" cols="12">
			<div class="border-effect border-effect--lg ml-6 mb-10">
				<div class="d-flex">
					<div class="flex-grow-1 align-self-center">
						<p v-if="!outstandingExternalKfiRequests" class="text-h5 mb-2 font-weight-regular">
							All KFI requests <strong>for all Products</strong> have been requested.
						</p>
						<p v-else class="text-h5 mb-2 font-weight-regular">
							KFI requests have been requested.
							<strong class="error--text">{{ outstandingExternalKfiRequests }} item{{ outstandingExternalKfiRequests !== 1 ? 's': null }} require{{ outstandingExternalKfiRequests === 1 ? 's': null }} attention</strong>
						</p>
						<p class="text-body-1 mb-0">
							Check below to track your KFI requests through to completion and retrieve related documents.
							<span v-if="displayVideos" class="d-none d-md-inline text-body-1 mb-0">
								<strong>Why not take a look at one of our informative videos, powered by Air Academy and packed full of great tips to help maximise your Air Sourcing experience.</strong>
							</span>
						</p>
					</div>
				</div>
			</div>

			<v-slide-y-transition origin="center center">
				<div v-if="displayVideos && !videosLoading" class="d-none d-md-flex align-center justify-center my-n2 mx-n3" style="overflow-x: hidden;">
					<div v-for="(video, index) in videos" :key="index" class="my-2 mx-3">
						<video
							class="d-block rounded elevation-3"
							:src="video.video | urlize"
							:poster="video.image || 'generic/gradient.png' | urlize"
							controls
							:width="video.featured ? '250px' : '215px'"
						/>
					</div>
				</div>
				<v-progress-linear v-else-if="displayVideos" indeterminate color="primary" class="d-none d-md-flex mx-auto" style="max-width: 800px;" />
			</v-slide-y-transition>

			<v-sheet class="pa-6 rounded mt-6">
				<v-row no-gutters class="mb-5">
					<v-col class="grow">
						<p class="text-body-2 mb-0">
							<span v-if="socketsEnabled">
								API enabled requests <v-icon color="info" size="20" class="icon--spin" style="top: -1px;">
									mdi-sync-circle
								</v-icon> will process immediately and update automatically in your browser.
							</span>
							<span v-else>
								API enabled requests <v-icon color="info" size="20" class="icon--spin" style="top: -1px;">
									mdi-sync-circle
								</v-icon> will process immediately.
							</span>
							Requests that require manual processing <v-icon color="secondary" size="20" style="top: -1px;">
								mdi-account-circle
							</v-icon> received before 3pm will be actioned the same day.
							With best endeavours, we will look to process any requests received after 3pm, however this cannot be guaranteed.
							<strong>
								Please note: any requests received after 5pm will be processed the next working day.
								To prevent any delays please ensure your credentials are updated in <a class="text-decoration-underline" @click="$router.push('/settings/credentials')">your account</a> page.
							</strong>
						</p>
						<p v-if="outstandingExternalKfiRequests" class="text-body-2 mt-3 mb-0 error--text">
							<strong>
								KFI requests for products displaying the <v-icon color="warning" size="20" style="top: -1px;">
									mdi-alert-circle
								</v-icon> icon cannot be made via {{ site.softwareName }}. Click the relevant product to request a KFI from the providers website, or remove the product from your KFI request list.
							</strong>
						</p>
					</v-col>
				</v-row>

				<v-row v-if="displayViewOptions" class="d-none d-md-flex mt-3 mb-1">
					<v-col class="text-left align-content-center">
						<v-btn-toggle v-model="viewOption" dense color="primary" mandatory>
							<v-tooltip top>
								<template #activator="{ on }">
									<v-btn v-on="on">
										All
									</v-btn>
								</template>
								<span>Display all KFI requests</span>
							</v-tooltip>
							<v-tooltip top>
								<template #activator="{ on }">
									<v-btn v-on="on">
										Pending
									</v-btn>
								</template>
								<span>Display only pending KFI requests</span>
							</v-tooltip>
							<v-tooltip top>
								<template #activator="{ on }">
									<v-btn v-on="on">
										Complete
									</v-btn>
								</template>
								<span>Display only completed KFI requests</span>
							</v-tooltip>
							<v-tooltip top>
								<template #activator="{ on }">
									<v-btn v-on="on">
										Failed
									</v-btn>
								</template>
								<span>Display only failed KFI requests</span>
							</v-tooltip>
						</v-btn-toggle>
					</v-col>
					<v-col class="shrink align-self-center">
						<v-text-field
							v-model="search"
							placeholder="Search: e.g. Provider or Product name"
							append-icon="mdi-magnify"
							dense
							solo
							hide-details=""
							style="width: 22rem; max-width: 22rem;"
						/>
					</v-col>
				</v-row>

				<v-row no-gutters class="mx-n3 mb-n3 pr-1">
					<v-col v-for="(product, index) in filteredProducts" :key="product.id" cols="12" sm="6" md="6" lg="4" class="pa-3">
						<product
							:product="product"
							:index="index"
							:client-id="clientId"
							:sockets:enabled="socketsEnabled"
							@refresh-products="$emit('refresh-products')"
							@remove-product="$emit('remove-product', product)"
						/>
					</v-col>
				</v-row>
			</v-sheet>
		</v-col>

		<kfi-request-onboarding ref="onBoarding" :sockets-enabled="socketsEnabled" :client-id="clientId" />
	</div>
</template>

<script>
	const NAME = 'kfiSummaryOnBoarding211124';

	import { mapActions, mapState } from 'vuex';
	import Product from './product';
	import { orderBy, some } from 'lodash';
	import KfiRequestOnboarding from './onboarding.vue';
	import { $localStorage } from '@/utils/storage';

	export default {
		name: 'kfi-request-summary',

		components: {
			Product,
			KfiRequestOnboarding
		},

		props: {
			clientId: {
				type: String,
				required: true
			},
			products: {
				type: Array,
				required: true
			}
		},

		data() {
			return {
				socketStatus: null,
				search: null,
				viewOption: undefined,
				kfiStageId: '10cea1e8-8edb-4615-a3e3-8d66ad57f401',
				resources: [],
				videosLoading: true
			};
		},

		computed: {
			...mapState('Account', ['changelogItems']),
			...mapState('CmsSite', ['site']),

			socketsEnabled() {
				return this.socketStatus === 1;
			},

			displayViewOptions() {
				return this.products.length > 4;
			},

			filteredProducts() {
				let products = this.products;
				if (this.viewOption == 1)
					products = products.filter((x) => {
						let status = x.status;
						if (!status) {
							let statuses = x.action.filter((a) => a.type === 'status');
							status = orderBy(statuses, 'created', ['desc'])?.[0]?.data?.status;
						}
						//Handle external KFIs here
						if (x.kfiRequestSubmitted && x.product?.keyFactsIllustration?.online && x.product?.keyFactsIllustration?.url) return false;
						//Handle everything else
						return !['Failed', 'Completed', 'KFI Request Failed', 'KFI Received'].includes(status);
					});
				if (this.viewOption == 2)
					products = products.filter((x) => {
						let status = x.status;
						if (!status) {
							let statuses = x.action.filter((a) => a.type === 'status');
							status = orderBy(statuses, 'created', ['desc'])?.[0]?.data?.status;
						}
						//Handle external KFIs here
						if (x.kfiRequestSubmitted && x.product?.keyFactsIllustration?.online && x.product?.keyFactsIllustration?.url) return true;
						//Handle everything else
						return ['Completed', 'KFI Received'].includes(status);
					});
				if (this.viewOption == 3)
					products = products.filter((x) => {
						let status = x.status;
						if (!status) {
							let statuses = x.action.filter((a) => a.type === 'status');
							status = orderBy(statuses, 'created', ['desc'])?.[0]?.data?.status;
						}
						//Handle external KFIs here
						if (x.kfiRequestSubmitted && x.product?.keyFactsIllustration?.online && x.product?.keyFactsIllustration?.url) return false;
						//Handle everything else
						return ['Failed', 'KFI Request Failed'].includes(status);
					});
				if (this.search) {
					products = products.filter((k) => {
						if (k.product.name.toLowerCase().includes(this.search.toLowerCase())) return true;
						if (k.product.provider.name.toLowerCase().includes(this.search.toLowerCase())) return true;
						return false;
					});
				}
				return orderBy(
					products,
					[
						(item) => {
							if (!item.kfiRequestSubmitted && item.product?.keyFactsIllustration?.online && item.product?.keyFactsIllustration?.url) return 1;
							return 0;
						},
						'created'
					],
					[
						'desc',
						'desc'
					]
				);
			},

			/**
			 * @name outstandingExternalKfiRequests
			 * @description used to get kfi submissions to be submitted manually
			 */
			outstandingExternalKfiRequests() {
				let arr = this.products?.filter((item) => !item.kfiRequestSubmitted && item.product?.keyFactsIllustration?.online && item.product?.keyFactsIllustration?.url) || [];
				return arr.length;
			},

			displayVideos() {
				if (!this.site || (('usesAirFlow' in this.site) && !this.site.usesAirFlow)) return false;
				return true;
			},

			/**
			 * @name kfiVideo
			 * @description TODO
			 */
			kfiVideo() {
				if (!this.resources.length) return null;
				return this.resources.find((x) => some(x.workflowTemplateItem, {'id': this.kfiStageId }));
			},

			/**
			 * @name onAirVideos
			 * @description TODO
			 */
			onAirVideos() {
				if (!this.changelogItems || !this.changelogItems.length) return null;
				return this.changelogItems.filter((item) => ['Introducing AirFlow', 'Introducing Affordability', 'Audit Functionality'].includes(item.title)) || null;
			},

			videos() {
				let videos = [];
				if (!this.site || (('usesAirFlow' in this.site) && !this.site.usesAirFlow)) return videos;

				//if (this.kfiVideo) videos.push(this.kfiVideo);
				if (this.onAirVideos) {
					this.onAirVideos.forEach(v => {
						if (v.title === 'Introducing AirFlow') videos.push({ ...v, featured: true });
						else videos.push(v);
					});

					//ensure featured video is in middle of array
					let i = videos.findIndex((v) => v.title === 'Introducing AirFlow');
					let e = videos[i];
					videos.splice(i, 1);
					videos.splice(1, 0, e);
				}
				return videos;
			}
		},

		created() {
			this.socketStatus = this.$websocket.$status();
			this.$websocket.$on('open', this.onWebSocketConnected);
			this.loadResources();
			setTimeout(() => {
				if (!this.site || (('usesAirFlow' in this.site) && !this.site.usesAirFlow)) return;
				this.videosLoading = false;
			}, 2000);
		},

		mounted() {
			if (!$localStorage.isDialogHidden(NAME)) {
				this.openOnBoarding();
				$localStorage.hideDialog(NAME);
			}
		},

		methods: {
			...mapActions('CmsCourse', ['loadCourseList']),

			onWebSocketConnected() {
				this.socketStatus = this.$websocket.$status();
			},

			loadResources() {
				this.loadCourseList().then((items) => this.resources = items.data.data.filter((i) => i.useAsIntro));
			},

			openOnBoarding() {
				this.$refs.onBoarding.open();
			}
		}
	};
</script>
