<!--
	@name app-enquiry-details
	@description Responses timeline for enquiry
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						<v-progress-circular v-if="loading" :size="25" :width="3" color="white" indeterminate />
						<template v-else-if="!matter">
							Enquiry not found
						</template>
						<template v-else>
							Enquiry #{{ matter.matterReference }}
						</template>
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" v-on="on" @click="printEnquiry">
								<v-icon large>
									mdi-printer
								</v-icon>
							</v-btn>
						</template>
						<span>Print enquiry to PDF</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" :loading="processing[0]" v-on="on" @click="openResponseDialog(0)">
								<v-icon large>
									mdi-comment-text-multiple
								</v-icon>
							</v-btn>
						</template>
						<span>Send update to all Providers</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" v-on="on" @click="openDocumentsDialog">
								<v-icon large>
									mdi-file-multiple
								</v-icon>
							</v-btn>
						</template>
						<span>Documents</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/enquiries" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Enquiries</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<div v-if="loading" />
		<v-alert v-else-if="!matter" type="error" transition="fade">
			Either the URL is incorrect, or the enquiry has been deleted. If the enquiry was deleted in error, please contact our support team.
		</v-alert>
		<v-fade-transition v-else>
			<v-row>
				<v-col class="py-0" cols="12" md="8">
					<div class="border-effect border-effect--lg ml-6 mb-10">
						<v-progress-linear v-if="!matter || !clients || !property" class="mx-3 mt-4" indeterminate />
						<div v-else class="d-flex">
							<div class="flex-grow-0" style="position: relative">
								<v-avatar size="60px" :color="matter.data?.closed ? 'rgba(23, 184, 144)' : 'rgba(247, 203, 115)'">
									<v-icon size="28" color="white" v-html="matter.data?.closed ? 'mdi-folder' : 'mdi-folder-open'" />
								</v-avatar>
							</div>
							<div class="flex-grow-1 align-self-center pl-5">
								<p class="text-body-1 mb-0 font-weight-medium">
									{{ summary }}
								</p>
							</div>
						</div>
					</div>

					<v-alert
						v-if="isEnquiryClosed"
						icon="mdi-check-circle"
						border="left"
						colored-border
						color="rgb(23, 184, 144)"
						dark
						class="mt-6 pl-6"
					>
						This enquiry was closed on {{ matter.data.closed | moment('MMM Do YYYY, h:mma') }}
					</v-alert>

					<common-structure-section class="mt-6">
						<template #header>
							Activity
							<v-spacer />
							<v-tooltip v-if="matter" bottom>
								<template #activator="{ on }">
									<v-btn color="primary" small class="mb-0" :loading="processing[0]" :disabled="isEnquiryClosed" v-on="on" @click="openResponseDialog(0)">
										<v-icon left>
											mdi-comment-text-multiple
										</v-icon>
										Send update to all Providers
									</v-btn>
								</template>
								<span>Send update to all Providers</span>
							</v-tooltip>
						</template>

						<template #body>
							<div v-if="tags && tags.length" class="mb-6">
								<common-base-tag :tags="tags" @update-tags="updateTags" />
							</div>

							<div v-if="matter && matter.process.length">
								<v-timeline>
									<app-enquiry-enquiry-details-response
										v-for="(response, index) in visibleResponses"
										:key="index"
										:response="response"
										:loading="processing"
										:enquiry-closed="isEnquiryClosed"
										:organisations="organisations"
										@open-response-dialog="openResponseDialog"
									/>
								</v-timeline>
							</div>

							<v-card v-else class="flex-grow-1 mb-0 pa-6">
								<v-skeleton-loader type="list-item-avatar" class="mb-6" />
								<v-skeleton-loader type="divider, card" />
							</v-card>
						</template>
					</common-structure-section>
				</v-col>

				<v-col class="py-0" cols="12" md="4">
					<app-enquiry-enquiry-details-documents :responses="responses" @show-all-documents="openDocumentsDialog" />

					<common-promotion-advisor-advert class="mt-6" />
				</v-col>
			</v-row>
		</v-fade-transition>

		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="submitResponse" />
		<app-enquiry-enquiry-details-documents-dialog ref="documentsDialog" :responses="responses" :organisations="organisations" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { orderBy, findIndex } from 'lodash';
	import pdfMake from 'pdfmake/build/pdfmake';
	import pdfFonts from 'pdfmake/build/vfs_fonts';
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	import { ElementTools } from '@/utils';
	import CommonBaseTag from '@/component/common/base/tag';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';
	import AppEnquiryEnquiryDetailsResponse from './response';
	import AppEnquiryEnquiryDetailsDocuments from './documents';
	import AppEnquiryEnquiryDetailsDocumentsDialog from './documents-dialog';

	export default {
		name: 'app-enquiry-details',

		components: {
			CommonBaseTag,
			CommonPromotionAdvisorAdvert,
			CommonStructureSection,
			DynamicFormDialog,
			AppEnquiryEnquiryDetailsResponse,
			AppEnquiryEnquiryDetailsDocuments,
			AppEnquiryEnquiryDetailsDocumentsDialog
		},

		data() {
			return {
				thread: null,
				formSchema: null,
				formData: {},
				loading: true,
				processing: {},
				activeTags: [],
				matter: null,
				clients: null,
				property: null,
				organisations: null,
				processList: null
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),
			...mapState('Account', ['user']),

			enquiryId() {
				return this.$route.params.id;
			},

			visibleResponses() {
				return this.responses.filter((response) => {
					let responder = [];
					if ((response.processTypeNameUnique === 'withdraw' || response.processTypeNameUnique === 'reconnect') && this.organisations)
						responder.push(this.organisations.find((o) => o.id === response.data.id)?.name);
					else if (response.data.responder?.type === 'organisation' && this.organisations)
						responder.push(this.organisations.find((o) => o.id === response.data.responder.id)?.name);
					else if (response.data.responder?.type === 'system') responder.push('Air Sourcing Support Team');
					else {
						response.iteration.map((iteration) => {
							if (iteration.data?.recipient?.type === 'organisation' && this.organisations) {
								const organisation = this.organisations.find((o) => o.id === iteration.data?.recipient.id);
								if (organisation) responder.push(organisation.name);
							}
						});
					}

					return !responder.length || this.activeTags.some((tag) => responder.includes(tag));
				});
			},

			isEnquiryClosed() {
				return !!this.matter?.data?.closed;
			},

			responses() {
				return orderBy(this.processList, 'created', ['desc']);
			},

			tags() {
				if (!this.matter || !this.organisations) return;
				let tags = [{ name: 'Air Sourcing Support Team' }];
				this.organisations.map((o) => tags.push({ name: o.name }));
				return tags;
			},

			summary() {
				let clientSummary = '';
				this.clients.map((client, index) => (clientSummary += `${index > 0 ? ' and ' : ''}${client.nameGiven} ${client.nameFamily}`));

				let { data: property } = this.property;
				let propertySummary = `${property.location.address1},${property.location.address2 ? ` ${property.location.address2},` : ''} ${property.location.townCity}, ${
					property.location.postcode
				}`;

				return `Enquiry #${this.matter?.matterReference}, created ${this.$moment(this.matter.created).format(
					'DD MMM YYYY HH:mm'
				)} on behalf of ${clientSummary} at ${propertySummary}`;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AppMatter', ['modifyMatter']),
			...mapActions('AppClient', ['loadClientList']),
			...mapActions('AppClientAsset', ['loadClientAsset']),
			...mapActions('AccountOrganisation', ['loadProviderList']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Enquiry', ['fetchEnquiry', 'createResponse', 'sendUpdate']),

			init() {
				this.loadMatter();
				this.loadForm('enquiryResponse');
				this.updateLastSeen();
			},

			/**
			 * @name loadMatter
			 * @description fetch matter
			 */
			async loadMatter() {
				this.loading = true;
				let { data: matter } = await this.fetchEnquiry(this.enquiryId).finally(() => (this.loading = false));

				this.matter = matter;
				let orderedProcessList = orderBy(matter.process, 'created', ['asc']);

				this.processList = orderedProcessList.map((process, index) => {
					let parentProcess = orderedProcessList.find((p) => p.id === process.action?.iteration?.processId);
					if (parentProcess) parentProcess.initialMessage = findIndex(orderedProcessList, parentProcess) === 0;
					return {
						...process,
						initialMessage: index === 0,
						parentProcess
					};
				});

				const clientIds = (matter?.matterEntity || []).filter((me) => me.entity === 'client').map((me) => me.entityId);
				const assetId = (matter?.matterEntity || []).find((me) => me.entity === 'asset')?.entityId;
				const organisationIds = (matter?.matterEntity || []).filter((me) => me.entity === 'organisation').map((me) => me.entityId);

				const requests = [this.loadClientList({ where: { id: { value: clientIds, type: 'uuid' } } }), this.loadClientAsset({ clientId: clientIds[0], id: assetId })];
				if (organisationIds.length)
					requests.push(await this.loadProviderList({ where: { id: { value: organisationIds, type: 'uuid' } }, order: { property: 'organisation.name' } }));

				Promise.all(requests).then((responses) => {
					this.clients = responses[0];
					this.property = responses[1].data;
					this.organisations = (responses[2]?.data || []).map((o) => ({
						...o,
						withdrawn: this.isOrganisationWithdrawn(o.id)
					}));
				});
			},

			isOrganisationWithdrawn(id) {
				const withdrawReconnectProcesses = this.processList?.filter(
					(p) => (p.processTypeNameUnique === 'withdraw' || p.processTypeNameUnique === 'reconnect') && p.data.id === id
				);

				if (withdrawReconnectProcesses?.length > 0) {
					const latestWithdrawReconnectProcess = withdrawReconnectProcesses.reduce((prev, current) =>
						this.$moment(prev.created).isAfter(this.$moment(current.created)) ? prev : current
					);

					return latestWithdrawReconnectProcess.processTypeNameUnique === 'withdraw';
				} else return false;
			},

			/**
			 * @name updateLastSeen
			 * @description update last seen
			 */
			async updateLastSeen() {
				this.modifyMatter({
					id: this.enquiryId,
					payload: { data: { lastSeenByAdvisor: new Date() } }
				});
			},

			/**
			 * @name submitResponse
			 * @description submit response
			 */
			async submitResponse({ config, data }) {
				const payload = config.responseId
					? {
							processId: config.responseId,
							iterationId: config.iterationId,
							data: {
								action: { key: 'CreateResponse' },
								process: { ...data }
							}
					  }
					: {
							matterId: this.matter.id,
							processType: 'update',
							process: { ...data }
					  };

				this.$set(this.processing, config.responseId.toString(), true);

				let request = config.responseId ? this.createResponse : this.sendUpdate;

				request(payload)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Response has been sent successfully');
						this.matter = null;
						this.loadMatter();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
					.finally(() => this.$set(this.processing, config.responseId.toString(), false));
			},

			/**
			 * @name openResponseDialog
			 * @description Event for open dynamic form dialog
			 * @param {Number} responseId
			 */
			openResponseDialog(responseId, iterationId) {
				this.$refs.dynamicFormDialog.open({
					responseId,
					iterationId,
					formSchema: this.form('enquiry_response').value,
					formData: this.formData,
					submitButtonText: 'Submit Response',
					title: 'Send a Response'
				});
			},

			/**
			 * @name openDocumentsDialog
			 * @description Event for open documents dialog
			 */
			openDocumentsDialog() {
				this.$refs.documentsDialog.open();
			},

			/**
			 * @name updateTags
			 * @description Tags updated event handler
			 * @param {Array} activeTags The active tags
			 */
			updateTags(activeTags) {
				this.activeTags = activeTags;
			},

			/**
			 * @name getAgeFromDateOfBirth
			 * @description Get age using date of birth
			 * @param {Date} dateOfBirth Date of birth
			 */
			getAgeFromDateOfBirth(dateOfBirth) {
				if (!dateOfBirth) return '';
				return `${this.$moment().diff(dateOfBirth, 'years')}`;
			},

			/**
			 * @name print
			 * @description Print as PDF
			 */
			printEnquiry() {
				if (!this.matter) return;

				const docDefinition = {
					info: {
						title: `Enquiry #${this.matter.id}`,
						author: 'Air Sourcing'
					},
					content: [],
					pageBreakBefore: (currentNode, followingNodesOnPage) => {
						if (currentNode.headlineLevel === 1) {
							let headlines = followingNodesOnPage.filter((i) => i?.headlineLevel === 1);
							return headlines.length === 0;
						}
					},
					styles: {
						summary: {
							fontSize: 16,
							lineHeight: 1.2,
							bold: true
						},
						providers: {
							fontSize: 14,
							italics: true,
							bold: true,
							background: '#fffde7'
						},
						responseHeaderSystem: {
							fontSize: 10,
							bold: true,
							color: '#388E3C'
						},
						responseHeaderAdvisor: {
							fontSize: 10,
							bold: true,
							color: '#6a1b9a'
						},
						responseHeaderProvider: {
							fontSize: 10,
							bold: true,
							color: '#0D47A1'
						},
						responseMessage: {
							fontSize: 12,
							lineHeight: 1.2
						},
						responseListHeader: {
							fontSize: 10,
							bold: true,
							color: '#888888'
						},
						responseList: {
							fontSize: 10,
							color: '#888888'
						}
					}
				};

				docDefinition.content.push({
					text: this.summary,
					style: 'summary',
					margin: [0, 0, 0, 0]
				});

				docDefinition.content.push({
					text: `Providers: ${this.organisations.map((o) => o.name).join(', ')}`,
					style: 'providers',
					margin: [0, 15, 0, 10]
				});

				if (this.visibleResponses && Array.isArray(this.visibleResponses) && this.visibleResponses.length) {
					this.visibleResponses.forEach((response) => {
						const columns = [
							{ width: '15%', text: '' },
							{ width: '15%', text: '' }
						];
						const isAdvisor = !response?.data?.responder;
						const isSystem = response?.data?.responder?.type === 'system';

						const responseAuthor = isAdvisor
							? this.user.name
							: isSystem
							? 'Air Sourcing Support Team'
							: this.organisations.find((o) => o.id === response.data.responder.id)?.name;

						let columnIndex = 1;
						if (isAdvisor) columnIndex = 0;
						columns[columnIndex] = [];

						let responseHeader = 'responseHeaderProvider';
						if (isAdvisor) responseHeader = 'responseHeaderAdvisor';
						if (isSystem) responseHeader = 'responseHeaderSystem';

						columns[columnIndex].push({
							headlineLevel: 1,
							text: `${responseAuthor}, ${this.$moment(response.created).format('Do MMM YYYY, HH:mm')}`,
							style: responseHeader,
							margin: [0, 20, 0, 0]
						});

						columns[columnIndex].push({
							text: response.data.message,
							style: 'responseMessage',
							margin: [0, 5, 0, 0]
						});

						const anyRecipients = response.processTypeNameUnique === 'update' && isAdvisor;
						const recipientList = response?.iteration?.filter((iteration) => iteration?.data?.recipient?.type !== 'system');

						if (anyRecipients && recipientList.length) {
							columns[columnIndex].push({
								text: `Recipients:`,
								style: 'responseListHeader',
								margin: [0, 5, 0, 0]
							});
							columns[columnIndex].push({
								text: `${recipientList.map((r) => this.organisations.find((o) => o.id === r.id)?.name).join(', ')}`,
								style: 'responseList',
								margin: [0, 2, 0, 0]
							});
						}

						if (response.data.files && Array.isArray(response.data.files) && response.data.files.length) {
							columns[columnIndex].push({
								text: `Attachments:`,
								style: 'responseListHeader',
								margin: [0, 5, 0, 0]
							});
							columns[columnIndex].push({
								text: `${response.data.files.map((file) => file.filename).join(', ')}`,
								style: 'responseList',
								margin: [0, 2, 0, 0]
							});
						}

						docDefinition.content.push({ columns: columns });
					});
				}

				pdfMake.createPdf(docDefinition).open();
			}
		}
	};
</script>
