import { $http } from '@/utils';

const basePath = 'matter';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/App/Source/Matter
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Matter {
	/**
	 * @static @name enquiryList
	 * @description Get all enquiry matters
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static enquiryList(payload) {
		return $http.post(`${basePath}/enquiry/list`, payload);
	}
	/**
	 * @static @name caseList
	 * @description Get all enquiry matters
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static caseList(payload) {
		return $http.post(`${basePath}/cases/list`, payload);
	}

	/**
	 * @static @name update
	 * @description Update all source details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static update(id, payload) {
		return $http.put(`${basePath}/${id}`, payload);
	}

	/**
	 * @static @name modify
	 * @description Update some source details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	static modify(id, payload) {
		return $http.patch(`${basePath}/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete source details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`${basePath}/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new source resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static add(payload) {
		return $http.post(basePath, payload);
	}

	/**
	 * @static @name sourcing
	 * @description new sourcing session
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static source(payload) {
		return $http.post(`${basePath}/sourcing`, payload);
	}
}
